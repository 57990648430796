/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
// import { } from "react-router-dom"

import AddNewBlog from "./AddNewFAQ";
import { isArray } from "lodash";
import swal from "sweetalert";
import EditRole from "./EditFAQ";
import { actions as FAQActions } from "../../../store/reducers/faqReducer";
import { useDispatch, useSelector } from "react-redux";
import TableLoader from "../common/TableLoader";
import NotFoundLable from "../common/NotFoundLable";
import { deleteFaq } from "../../../services/FAQs";
import { verifyObject } from "../../../utilities/utils";
import Pagination from "../../components/common/Pagination";

const FAQList = () => {
  const [editedData, setEditFormData] = useState("Newest");
  const [userModal, setUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const { push } = useHistory();
  const { permissions } = useSelector((state) => state?.localStore);
  const createPermissions = permissions?.createPermissions;
  const editPermissions = permissions?.editPermissions;
  const deletePermissions = permissions?.deletePermissions;

  // const [data, setData] = useState(
  //   document.querySelectorAll("#example2_wrapper tbody tr")
  // );

  const dispatch = useDispatch();
  const { data, isLoading, pagination } = useSelector((state) => state.faqs);
  const handleEditClick = (event, data) => {
    event.preventDefault();
    const formValues = {
      id: data._id,
      faq_title: data.title,
      faq_question: data.question,
      faq_answer: data.answer,
      faq_type: data.faqType._id,
    };
    setEditFormData(formValues);
    handleEditModal();
  };

  const handleEditModal = () => {
    setEditUserModal(!editUserModal);
  };

  const _getFAQList = () => {
    dispatch(FAQActions.onRequest({}));
  };

  const onPageChange = (data) => {
    const { page } = data;
    dispatch(FAQActions.onRequest({ page }));
  };

  const deleteRecord = async (_id) => {
    try {
      // await setLoading(true);
      let response = await deleteFaq(_id);
      console.log("Response roles", response);
      swal("Poof! Your record has been deleted!", {
        icon: "success",
      });
      _getFAQList();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      // await setLoading(false);
    }
  };

  useEffect(() => {
    _getFAQList();
  }, []);

  const DropdownBlog = (user) => {
    return (
      <>
        {(editPermissions.FAQs || deletePermissions.FAQs) && (
          <Dropdown className="dropdown">
            <Dropdown.Toggle
              as="div"
              className="btn-link i-false"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              {editPermissions.FAQs && (
                <Dropdown.Item
                  onClick={(e) => handleEditClick(e, user)}
                  className="dropdown-item"
                >
                  Edit
                </Dropdown.Item>
              )}
              {deletePermissions.FAQs && (
                <Dropdown.Item
                  onClick={() => {
                    return swal({
                      title: "Are you sure?",
                      text: "Once deleted, you will not be able to recover this record!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        deleteRecord(user._id);
                      } else {
                        swal("Your record is safe!");
                      }
                    });
                  }}
                  className="dropdown-item"
                >
                  Delete
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={(e) => push(`/faqs/${user._id}`)}
                className="dropdown-item"
              >
                Show Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </>
    );
  };

  // Active data

  // use effect
  useEffect(() => {
    // setData(document.querySelectorAll("#example2_wrapper tbody tr"));
    //chackboxFun();
  }, []);

  // console.log(document.querySelectorAll(".sorting_1 input")[0].checked);

  const handleModal = (value) => {
    setUserModal(!userModal);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2 flex-wrap">
          <div className="newest ms-3">
            {createPermissions.FAQs && (
              <Link
                to={"#"}
                className="btn btn-primary font-w600 mb-2 me-auto"
                onClick={() => {
                  setEditFormData(null);
                  handleModal();
                }}
              >
                + New FAQ
              </Link>
            )}
            {userModal && (
              <AddNewBlog
                editedData={editedData}
                userModal={userModal}
                setAddUserModal={handleModal}
              />
            )}
            {editUserModal && (
              <EditRole
                editedData={editedData}
                userModal={editUserModal}
                setAddUserModal={handleEditModal}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <div
                  id="example2_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example2"
                    className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting"> ID</th>
                        <th className="sorting_asc">Title</th>
                        <th className="sorting_asc">Question</th>
                        <th className="sorting_asc">Answer</th>
                        <th className="sorting bg-none"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="8">
                            <TableLoader />
                          </td>
                        </tr>
                      ) : null}
                      {!isLoading && data.length === 0 && (
                        <tr>
                          <td colSpan="8">
                            <NotFoundLable message="No FAQs Found" />
                          </td>
                        </tr>
                      )}
                      {data &&
                        isArray(data) &&
                        data.map((user, index) => {
                          return (
                            <tr key={user._id} role="row" className="odd">
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-nowrap">
                                      <Link to={"#"} className="text-black">
                                        {index + 1}
                                      </Link>
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-nowrap">
                                      {user.title}
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <span className="text-nowrap">
                                    {user.question}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  {/* <span className="text-wrap"> */}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: `${user.answer.slice(0, 100)} ${
                                        user.answer.length > 100 ? "..." : ""
                                      }`,
                                    }}
                                  />
                                </div>
                              </td>
                              <td>{DropdownBlog(user)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info"></div>
                    <div
                      className="dataTables_paginate paging_simple_numbers mb-0"
                      id="example2_paginate"
                    >
                      <Pagination
                        pagination={pagination}
                        data={data}
                        onPageChange={onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FAQList;
