/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { saveAs } from "file-saver";
// import { toPng } from "html-to-image";

const EvisaDocument = ({ documentItem, title, type }) => {
  console.log("documentItem", documentItem);
  // const downloadImage = (url) => {
  //   fetch(url, {
  //     mode: "no-cors",
  //   })
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       let blobUrl = window.URL.createObjectURL(blob);
  //       let a = document.createElement("a");
  //       a.download = url.replace(/^.*[\\\/]/, "");
  //       a.href = blobUrl;
  //       document.body.appendChild(a);
  //       a.click();
  //       a.remove();
  //     });
  // };
  return (
    <>
      <h4 className="text-capitalize">{title}</h4>
      <div style={{ display: "flex"}}>
        <div className="new-arrival-product-custom">
          <div className="new-arrivals-img-contnent">
            <img
              className="img-fluid"
              src={
                type ? documentItem.other_passport : documentItem.us_ca_passport
              }
              alt=""
            />
          </div>
          <div className="new-arrival-content text-center mt-3">
            <h4>
              <a
                href={
                  type
                    ? documentItem.other_passport
                    : documentItem.us_ca_passport
                }
                download
              >
                <i className="fa fa-download" />
              </a>
            </h4>
            <span className="font-w600">{"Passport"}</span>
          </div>
        </div>
        <div style={{ marginLeft: 30 }} className="new-arrival-product-custom">
          <div className="new-arrivals-img-contnent">
            <img
              className="img-fluid"
              src={type ? documentItem.other_photo : documentItem.us_ca_photo}
              alt=""
            />
          </div>
          <div className="new-arrival-content text-center mt-3">
            <h4>
              <a
                href={
                  type ? documentItem.other_photo : documentItem.us_ca_photo
                }
                download
              >
                <i className="fa fa-download" />
              </a>
            </h4>
            <span className="font-w600">{"Photo"}</span>
          </div>
        </div>

        {type === "other" && (
          <div
            style={{ marginLeft: 30 }}
            className="new-arrival-product-custom"
          >
            <div className="new-arrivals-img-contnent">
              <img
                className="img-fluid"
                src={documentItem.other_green_card}
                alt=""
              />
            </div>
            <div className="new-arrival-content text-center mt-3">
              <h4>
                <a href={documentItem.other_green_card} download>
                  <i className="fa fa-download" />
                </a>
              </h4>

              <span className="font-w600">{"Green Card"}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EvisaDocument;
