/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ValidateInput } from "./ValidateImages";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import swal from "sweetalert";
import { actions as imageActions } from "../../../store/reducers/imageGallaryReducer";
import { useDispatch } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import {
  getAllImageGalleryCategories,
  updateImage,
} from "../../../services/ImageGallary";
import { Image } from "antd";
import { TINY_MCE_API_KEY, TINY_MCE_EDITOR } from "../../../constants/common";

export default function EditImage({ userModal, setAddUserModal, editedData }) {
  const dispatch = useDispatch();
  const [displayedImage, setDisplayImage] = useState([]);
  // const [oldImages, setOldImage] = useState(null);

  const [faqTypes, setFaqTypes] = useState([]);

  useEffect(() => {
    _getFaqTypeList();
  }, []);

  useEffect(() => {
    setDisplayImage(editedData?.image_file);
  }, [editedData]);

  // const toDataURL = url => fetch(url)
  //     .then(response => response.blob())
  //     .then(blob => new Promise((resolve, reject) => {
  //     const reader = new FileReader()
  //     reader.onloadend = () => resolve(reader.result)
  //     reader.onerror = reject
  //     reader.readAsDataURL(blob)
  //    }))

  // function dataURLtoFile(dataurl, filename) {
  //    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
  //    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  //    while(n--){
  //    u8arr[n] = bstr.charCodeAt(n);
  //    }
  //  return new File([u8arr], filename, {type:mime});
  // }

  const _getFaqTypeList = async () => {
    try {
      let response = await getAllImageGalleryCategories({});
      console.log("Response roles", response);
      let faqTypes = response.data.data.docs;
      let options = [];
      faqTypes.map((d) => {
        options.push({
          value: d._id,
          label: d.name,
        });
        return null;
      });
      await setFaqTypes(faqTypes);

      // this.setState()
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
    }
  };

  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);
    try {
      let formData = new FormData();
      // if (values.image_updated) {
      //   formData.set("image", values.image_updated);
      // } else {
      //   formData.set("image", values.image_file);
      // }
      for (const singleFile of values.image_file) {
        formData.append("images", singleFile);
      }
      editedData.image_file.map((d) => {
        formData.append("images", d);
      });
      formData.set("description", values.image_description);
      formData.set("type", values.image_type);
      formData.set("title", values.title);
      let response = await updateImage(formData, editedData.id);

      console.log("Response roles", response);
      // setUserArray(response.data.data.docs);
      // this.setState()
      setAddUserModal();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await dispatch(imageActions.onRequest({}));
      await setSubmitting(false);
    } catch (e) {
      console.log("eeee", e);
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };

  return (
    <div>
      {" "}
      <Modal
        className="fade bd-example-modal-lg"
        size="lg"
        show={userModal}
        onHide={setAddUserModal}
      >
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                ...editedData,
                image_updated: null,
              }}
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                // setSubmitting(false);
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Update Image</h4>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Title
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="title"
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter title"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.title}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Image
                            </label>
                            <div className="contact-name">
                              <input
                                className="form-control"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                multiple
                                onChange={(event) => {
                                  setFieldValue(
                                    "image_file",
                                    event.target.files
                                  );
                                  let imagefiles = [];
                                  let sendFiles = [];

                                  for (const singleFile of event.target.files) {
                                    // formData.append("images", image);
                                    imagefiles = [
                                      ...imagefiles,
                                      URL.createObjectURL(singleFile),
                                    ];
                                    // sendFiles = [...imagefiles, singleFile];
                                  }

                                  setDisplayImage([
                                    ...displayedImage,
                                    ...imagefiles,
                                  ]);
                                  // setDisplayImage([
                                  //   ...displayedImage,
                                  //   ...imagefiles,
                                  // ]);
                                }}
                                onBlur={handleBlur}
                                placeholder="Choose image"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.image_file}
                                </span>
                              )}
                              {/* {values.image_file } */}
                              <div className="displayed-img-cont">
                                {displayedImage?.map((im) => {
                                  return (
                                    <Image
                                      className="displayed-image"
                                      width={100}
                                      src={im}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Image Type
                            </label>
                            <div className="contact-name">
                              <select
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="image_type"
                                value={values.image_type}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter blog title"
                              >
                                <option selected disabled value={""}>
                                  Select Image type
                                </option>
                                {faqTypes.map((ft) => {
                                  return (
                                    <option key={ft._id} value={ft._id}>
                                      {ft.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.image_type}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Description
                            </label>
                            <div className="contact-name">
                              <Editor
                                apiKey={TINY_MCE_API_KEY}
                                value={values.image_description}
                                init={TINY_MCE_EDITOR}
                                // init={{
                                //   height: 300,
                                //   menubar: false,
                                //   plugins: [
                                //     "advlist autolink lists link image code charmap print preview anchor",
                                //     "searchreplace visualblocks code fullscreen",
                                //     "insertdatetime media table paste code help wordcount",
                                //   ],
                                //   toolbar:
                                //     "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
                                //     "bullist numlist outdent indent | removeformat | help ",
                                //   content_style: "body { color: #828282 }",
                                // }}
                                onEditorChange={(e) => {
                                  setFieldValue("image_description", e);
                                }}
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.image_description}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Updating..." : "Update"}
                      </button>
                      <button
                        type="button"
                        onClick={setAddUserModal}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
