import { get, post, patch, deletee } from "./index";

export const getUsers = (params) => {
  const request = {
    subUrl: `/admin/getAllUser`,
    params,
  };
  return get(request);
};

export const addUser = (data) => {
  const request = {
    subUrl: `/admin/user`,
    data,
  };
  return post(request);
};

export const updateUser = (data) => {
  const request = {
    subUrl: `/admin/user/${data._id}`,
    data,
  };
  return patch(request);
};
export const resetPassword = (data) => {
  const request = {
    subUrl: `/admin/resetPassword`,
    data,
  };
  return post(request);
};
export const deleteUser = (data) => {
  const request = {
    subUrl: `/admin/user/${data._id}`,
  };
  return deletee(request);
};
