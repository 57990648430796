import React, { useEffect, useRef, useState } from "react";
import { ValidateInputPackagesDetails } from "./ValidatePackages";
import { Formik } from "formik";

import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { verifyObject } from "../../../../utilities/utils";
import "react-datepicker/dist/react-datepicker.css";
import { Image } from "antd";
import {
  addPackageDetails,
  updatePackageDetail,
} from "../../../../services/packages";
import { Select, Space } from "antd";
import { actions as iconsActions } from "../../../../store/reducers/iconLibReducer";
import { map } from "lodash";

export default function AddNewPackage({ packegeDetail, next }) {
  const dispatch = useDispatch();
  const [displayedImage, setDisplayImage] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const formikRef = useRef(null);
  const {
    data: iconListData,
    isLoading,
    pagination,
  } = useSelector((state) => state.iconLib);

  const [displayedImage1, setDisplayImage1] = useState(null);

  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);
    try {
      let formData = new FormData();
      formData.set("title", values.title);
      formData.set("forType", values.forType);
      formData.set("slug", values.slug);
      formData.set("packageIcon", JSON.stringify(values.packageIcon));

      // formData.set("url", values.package_url);
      if (values.isActive) {
        formData.set("isActive", values.isActive);
      } else {
        formData.set("isActive", false);
      }

      // formData.set("subTitle", values.subTitle);
      formData.set("shortDescription", values.shortDescription);
      formData.set("englishDate", values.englishDate);
      formData.set("noOfDays", values.noOfDays);
      formData.set("price", values.price);
      formData.set("priceCaption", values.priceCaption);
      // formData.set("packageType", values.packageType);
      formData.set("packageListingImage", values.packageListingImage);
      formData.set("packageHeaderImage", values.packageHeaderImage);
      formData.set("meta_title", values.meta_title);
      formData.set("meta_desc", values.meta_desc);
      formData.set("meta_keywords", values.meta_keywords);
      if (values.umrahSubType) {
        formData.set("umrahSubType", values.umrahSubType);
      }
      formData.set("position", values.position);
      let response = await addPackageDetails(formData);
      console.log("Response roles", response);
      console.log("console error", response);
      dispatch({
        type: "SET_PACKAGE_ID",
        payload: response.data.data._id,
      });
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      next();

      await setSubmitting(false);
    } catch (e) {
      await setSubmitting(false);
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };

  const handleUpdate = async (values, setSubmitting) => {
    // console.log("Values", values);
    try {
      let formData = new FormData();
      formData.set("title", values.title);
      formData.set("forType", values.forType);
      formData.set("slug", values.slug);

      formData.set("packageIcon", JSON.stringify(values.packageIcon));

      formData.set("url", values.package_url);
      if (values.isActive) {
        formData.set("isActive", values.isActive);
      } else {
        formData.set("isActive", false);
      }
      formData.set("subTitle", values.subTitle);
      formData.set("shortDescription", values.shortDescription);
      formData.set("englishDate", values.englishDate);
      formData.set("noOfDays", values.noOfDays);
      formData.set("price", values.price);
      formData.set("priceCaption", values.priceCaption);
      formData.set("packageType", values.packageType);
      formData.set("packageListingImage", values.packageListingImage);
      formData.set("packageHeaderImage", values.packageHeaderImage);
      formData.set("position", values.position);
      formData.set("meta_title", values.meta_title);
      formData.set("meta_desc", values.meta_desc);
      formData.set("meta_keywords", values.meta_keywords);
      if (values.umrahSubType) {
        formData.set("umrahSubType", values.umrahSubType);
      }

      let response = await updatePackageDetail(formData, editedData._id);
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      // await dispatch(blogsActions.onRequest({}));
      await setSubmitting(false);
      next();
      // await setAddUserModal();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };
  console.log("editedData", editedData);

  useEffect(() => {
    if (packegeDetail) {
      setEditedData({
        ...packegeDetail,
        packageIcon: map(packegeDetail?.packageIcon, "_id", []),
        // meta_title: 
      });
    } else {
      let initialValues = {
        title: "",
        forType: "",
        isActive: "",
        subTitle: "",
        shortDescription: "",
        englishDate: "",
        noOfDays: "",
        price: "",
        priceCaption: "",
        packageListingImage: "",
        packageHeaderImage: "",
        meta_desc:"",
        meta_title:"",
        meta_keywords:""
      };
      setEditedData(initialValues);
    }
  }, [packegeDetail]);

  useEffect(() => {
    _getIconList();
  }, []);

  const handleSelectChange = (values) => {
    console.log("values", values);
  };

  const _getIconList = async () => {
    await dispatch(iconsActions.onRequest({ limit: 50 }));
    // if (editedData?.packageIcon && editedData?.packageIcon?.length > 0) {
    //   const packageIcons = map(editedData?.packageIcon, "_id", []);
    //   formikRef.current.setFieldValue("packageIcon", packageIcons);
    // }
  };
  console.log("package icon", editedData);
  return (
    <div className="" role="document">
      <div className="">
        <Formik
          enableReinitialize
          initialValues={editedData}
          validate={ValidateInputPackagesDetails}
          initialTouched={true}
          innerRef={formikRef}
          onSubmit={(values, { setSubmitting }) => {
            console.log("values", values);
            // setSubmitting(false);
            if (packegeDetail) {
              handleUpdate(values, setSubmitting);
            } else {
              handleSubmit(values, setSubmitting);
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            // console.log("values", values);
            // console.log("errors", errors);

            if (values) {
              // console.log(
              //   "dates",
              //   values.englishDate,
              //   new Date(values.englishDate)
              // );
              return (
                <form onSubmit={handleSubmit}>
                  {/* <div className="modal-header">
                  </div> */}
                  <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <div className="row">
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              Package Title
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="off"
                                name="title"
                                value={values.title}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                placeholder="Enter package title"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.title}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              Package Type
                            </label>
                            <div className="contact-name">
                              <select
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="off"
                                name="forType"
                                value={values.forType}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                              >
                                <option selected disabled value={""}>
                                  Select
                                </option>
                                <option value={"Umrah"}>Umrah</option>
                                <option value={"Hajj"}>Hajj</option>
                                <option value={"ramadan_umrah"}>Ramadan Umrah</option>
                                <option value={"Umrah Group"}>
                                  Umrah Group
                                </option>

                                <option value={"Other"}>Other</option>
                              </select>
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.forType}
                                </span>
                              )}
                            </div>
                          </div>
                          {values.forType === "Umrah" && (
                            <div className="form-group mb-3 col-md-6">
                              <label className="text-black font-w500">
                                Umrah Sub Type
                              </label>
                              <div className="contact-name">
                                <select
                                  type="text"
                                  className="form-control form-control-sm"
                                  autoComplete="off"
                                  name="umrahSubType"
                                  value={values.umrahSubType}
                                  onChange={handleChange}
                                  // onBlur={handleBlur}
                                >
                                  <option selected disabled value={""}>
                                    Select
                                  </option>
                                  <option value={"Umrah Full Package"}>
                                    Umrah Package
                                  </option>
                                  <option value={"Umrah Land Package"}>
                                    Land Umrah Package
                                  </option>
                                  <option value={"Umrah Group Package"}>
                                    {" "}
                                    Umrah Group Package
                                  </option>
                                </select>
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.umrahSubType}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              English Date
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="off"
                                name="englishDate"
                                value={values.englishDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                placeholder="Enter Date YYYY/MM/DD"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.englishDate}
                                </span>
                              )}
                            </div>
                          </div>
                          
                          {/* <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              Package Sub Title
                            </label>
                            <div className="contact-name">
                              <textarea
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="off"
                                name="subTitle"
                                value={values.subTitle}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter package Sub Title"
                                rows={4}
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.subTitle}
                                </span>
                              )}
                            </div>
                          </div> */}
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              Package Short Description
                            </label>
                            <div className="contact-name">
                              <textarea
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="off"
                                name="shortDescription"
                                value={values.shortDescription}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                placeholder="Enter Short Description"
                                rows={4}
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.shortDescription}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              No Of Days
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="off"
                                name="noOfDays"
                                value={values.noOfDays}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                placeholder="Enter no of Days"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.noOfDays}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              Price
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="off"
                                name="price"
                                value={values.price}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                placeholder="Enter price"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.price}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              Price inclusive of all taxes
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="off"
                                name="priceCaption"
                                value={values.priceCaption}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                placeholder="Enter price caption"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.priceCaption}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              Position
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="off"
                                name="position"
                                value={values.position}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                placeholder="Enter position"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.priceCaption}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              Slug
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="off"
                                name="slug"
                                value={values.slug}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                placeholder="Enter Slug"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.priceCaption}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              Package Listing Image
                            </label>
                            <div className="contact-name">
                              <input
                                className="form-control form-control-sm"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                // id="formFile"
                                // value={values.blog_image}
                                onChange={(e) => {
                                  setFieldValue(
                                    "packageListingImage",
                                    e.currentTarget.files[0]
                                  );
                                  setDisplayImage(
                                    URL.createObjectURL(
                                      e.currentTarget.files[0]
                                    )
                                  );
                                }}
                                // onBlur={handleBlur}
                                placeholder="Choose image"
                              />
                              <p className="text-primary mt-1">
                                (Image Size 335px * 210px)
                              </p>
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.packageListingImage}
                                </span>
                              )}
                            </div>
                            <Image
                              width={200}
                              src={
                                displayedImage
                                  ? displayedImage
                                  : values.packageListingImage
                              }
                            />
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              Package Detail Header Image
                            </label>
                            <div className="contact-name">
                              <input
                                className="form-control form-control-sm"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                // id="formFile"
                                // value={values.blog_image}
                                onChange={(e) => {
                                  setFieldValue(
                                    "packageHeaderImage",
                                    e.currentTarget.files[0]
                                  );
                                  setDisplayImage1(
                                    URL.createObjectURL(
                                      e.currentTarget.files[0]
                                    )
                                  );
                                }}
                                // onBlur={handleBlur}
                                placeholder="Choose image"
                              />
                              <p className="text-primary mt-1">
                                (Image Size 1440px * 286px)
                              </p>
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.packageHeaderImage}
                                </span>
                              )}
                            </div>
                            <Image
                              width={200}
                              src={
                                displayedImage1
                                  ? displayedImage1
                                  : values.packageHeaderImage
                              }
                            />
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                            Package Meta Title
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="meta_title"
                                value={values.meta_title}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                placeholder="Enter package meta title"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.meta_title}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                            Package Meta Description
                            </label>
                            <div className="contact-name">
                              <textarea
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="meta_desc"
                                value={values.meta_desc}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                placeholder="Enter package meta description"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.meta_desc}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              Package Meta Keywords
                            </label>
                            <div className="contact-name">
                              <textarea
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="meta_keywords"
                                value={values.meta_keywords}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                placeholder="Enter package meta description"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.meta_desc}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label className="text-black font-w500">
                              Package Icon
                            </label>
                            <div className="contact-name">
                              <Select
                                mode="multiple"
                                className="form-control"
                                placeholder="Please select"
                                value={values.packageIcon}
                                onChange={(values) => {
                                  setFieldValue("packageIcon", values);
                                }}
                                // onSearch={handleSearch}
                                // filterOption={(input, option) => {
                                //   console.log("input",input)
                                //   console.log("option",option)
                                // }}
                                filterOption={(input, option) => option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                                style={{
                                  height: "auto",
                                }}
                              >
                                {iconListData &&
                                  iconListData?.map?.((_d) => {
                                    return (
                                      <Select.Option key={_d.name} value={_d._id}>
                                        <span>
                                          <img
                                            src={_d.image}
                                            className="img-fluid"
                                            alt=""
                                            width={50}
                                          />
                                          <span style={{ marginLeft: 10 }}>
                                            {_d.name}
                                          </span>
                                        </span>
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.packageIcon}
                                </span>
                              )}
                            </div>
                          </div>
                       
                          <div className="form-group mb-3 col-md-6">
                            {/* <label className="text-black font-w500">
                              Is Active?
                            </label> */}
                            <div className="contact-name">
                              <div className="form-check custom-checkbox mb-3 checkbox-danger">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customCheckBox5"
                                  onChange={(e) => {
                                    setFieldValue("isActive", e.target.checked);
                                  }}
                                  checked={values.isActive === true}
                                />
                                <label
                                  className="form-check-label text-black font-w500"
                                  htmlFor="customCheckBox5"
                                >
                                  Active ?
                                </label>
                              </div>
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.isActive}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className="text-end toolbar toolbar-bottom p-2 modal-footer border-top-0">
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      // onClick={handleAddFormSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? "Adding..."
                        : packegeDetail
                        ? "Update Package"
                        : "Add Package"}
                    </button>
                    {packegeDetail && (
                      <button
                        className="btn btn-primary mr-2"
                        onClick={next}
                        disabled={isSubmitting}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </form>
              );
            }
          }}
        </Formik>
      </div>
    </div>
  );
}
