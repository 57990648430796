import React from "react";
import { Modal } from "react-bootstrap";
import { ValidateInput } from "./ValidateReview";
import { Formik } from "formik";
import swal from "sweetalert";
import { actions as blogsActions } from "../../../store/reducers/reviewsReducer";
import { useDispatch } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import { addNewReview } from "../../../services/Reviews";

export default function AddNewReview({
  userModal,
  setAddUserModal,
  _getBlogList,
}) {
  const dispatch = useDispatch();

  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);
    try {
      let response = await addNewReview(values);
      setAddUserModal();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await dispatch(blogsActions.onRequest({}));
      await setSubmitting(false);
      // await setAddUserModal();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };
  return (
    <div>
      {" "}
      <Modal
        size="lg"
        className="fade bd-example-modal-lg"
        show={userModal}
        onHide={setAddUserModal}
      >
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                personName: "",
                year: "",
                address: "",
                type: "",
                description: "",
              }}
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Add New Review</h4>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="row">
                            <div className="form-group mb-3 col-lg-6">
                              <label className="text-black font-w500">
                                Name
                              </label>
                              <div className="contact-name">
                                <input
                                  type="text"
                                  className="form-control"
                                  autoComplete="off"
                                  name="personName"
                                  value={values.personName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter person name"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.personName}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group mb-3 col-lg-6">
                              <label className="text-black font-w500">
                                Address
                              </label>
                              <div className="contact-name">
                                <textarea
                                  type="text"
                                  className="form-control"
                                  autoComplete="off"
                                  name="address"
                                  value={values.address}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter address"
                                  rows={2}
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.address}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group mb-3 col-lg-6">
                              <label className="text-black font-w500">
                                Year
                              </label>
                              <div className="contact-name">
                                <input
                                  type="text"
                                  className="form-control"
                                  autoComplete="off"
                                  name="year"
                                  value={values.year}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter Year"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.year}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group mb-3 col-lg-6">
                              <label className="text-black font-w500">
                                Type
                              </label>
                              <div className="contact-name">
                                <select
                                  type="text"
                                  className="form-control"
                                  autoComplete="off"
                                  name="type"
                                  value={values.type}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter blog title"
                                >
                                  <option selected disabled value={""}>
                                    Select type
                                  </option>
                                  <option value={"Hajj"}>Hajj</option>
                                  <option value={"Umrah"}>Umrah</option>
                                </select>
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.type}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Review
                            </label>
                            <div className="contact-name">
                              <textarea
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Write a Short Review"
                                rows={3}
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.description}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Adding..." : "Add"}
                      </button>
                      <button
                        type="button"
                        onClick={setAddUserModal}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
