
export function ValidateInput(data) {
  let errors = {};
  console.log("data", data);

  if (data.description === "") {
    errors.description = "Content is required";
  }

  // if (!validator.isEmpty(data.blog_url)) {
  //   if (!validator.isURL(data.blog_url)) {
  //     errors.blog_url = "URL  is invalid";
  //   }
  // }
  console.log("errors", errors);
  return errors;
}
