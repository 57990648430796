import moment from "moment";
import validator from "validator";

export function ValidateInput(data) {
  let errors = {};
  console.log("data", data);

  if (data.personName !== undefined && validator.isEmpty(data.personName)) {
    errors.personName = "Name  is required";
  }
  if (data.address !== undefined && validator.isEmpty(data.address)) {
    errors.address = "Address  is required";
  }
  if (data.year !== undefined && validator.isEmpty(data.year.toString())) {
    errors.year = "Year  is required";
  }
  if (!validator.isEmpty(data.year.toString())) {
    if (!moment(data.year.toString()).isValid()) {
      errors.year = "Please enter a valid year";
    }
    // if (moment(data.year).isBefore("1990")) {
    //   errors.year = "Please enter a valid year";
    // }
    // if (moment(data.year).isAfter(new Date().getFullYear())) {
    //   errors.year = "Please enter a valid year";
    // }
  }
  if (data.type !== undefined && validator.isEmpty(data.type)) {
    errors.type = "Type  is required";
  }

  if (data.description !== undefined && validator.isEmpty(data.description)) {
    errors.description = "Review  is required";
  }
  // if (!validator.isEmpty(data.blog_url)) {
  //   if (!validator.isURL(data.blog_url)) {
  //     errors.blog_url = "URL  is invalid";
  //   }
  // }
  console.log("errors", errors);
  return errors;
}
