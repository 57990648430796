import validator from "validator";

export function ValidateInput(data) {
  let errors = {};
  console.log("data", data);

  if (
    data.banner_content !== undefined &&
    validator.isEmpty(data.banner_content)
  ) {
    errors.banner_content = "Banner Content  is required";
  }
  if (data.banner_image === "") {
    errors.banner_image = "Banner Image  is required";
  }
  if (data.mobile_image === "") {
    errors.mobile_image = "Banner mobile Image  is required";
  }
  console.log("errors", errors);
  return errors;
}
