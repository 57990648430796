import swal from "sweetalert";
import {
  savePermissionsInLocalStorage,
  saveTokenInLocalStorage,
  saveUserInLocalStorage,
  signUp,
} from "../../services/AuthService";
import { loginApi } from "../../services/session";
import { adminPermissions, permissionsRoutes } from "../../constants/common";
// import { verifyObject } from "../../utilities/utils";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        // let authToken = response.data.data.token;
        // saveTokenInLocalStorage(dispatch, authToken);
        // dispatch(loginConfirmedAction(authToken));
        history.push("/login");
        swal({
          text: response.data.message,
          icon: "success",
          title: "Sign Up Success",
          timer: 2000,
          buttons: false,
        });
        dispatch(loadingToggleAction(false));
      })
      .catch((e) => {
        // const errorMessage = formatError(error.response.data);

        dispatch(loadingToggleAction(false));
        // swal("Server Error", errorMessage, "error");
        swal({
          text: e.data.message,
          title: "Login Failed",
          icon: "error",
          timer: 2000,
          buttons: false,
        });
      });
  };
}

export function logout(history) {
  history.push("/login");

  return {
    type: "CLEAR_LOCAL_STATE",
  };
}

export function loginAction(email, password, history) {
  return (dispatch) => {
    let payload = {
      email: email,
      password: password,
    };
    loginApi(payload)
      .then((response) => {
        console.log("response logged in", response);
        let authToken = response?.data?.data?.token;
        let role = response?.data?.data?.role;
        let userId = response?.data?.data?.userId;

        let permissions = response?.data?.data?.permission;

        if (role === "admin") {
          saveTokenInLocalStorage(dispatch, authToken);
          savePermissionsInLocalStorage(dispatch, adminPermissions);
          saveUserInLocalStorage(dispatch, role, userId);
          dispatch(loginConfirmedAction(authToken));
          // history.push(`/users-list`);
          window.location.href = "/users-list";
        } else {
          console.log("Permission ----->", permissions);
          let AllowedArray = [];
          if (permissions) {
            permissions?.map((d) => {
              if (d.actions.view === true) {
                AllowedArray = [...AllowedArray, d.title];
              }
            });
          }

          let foundRoute = permissionsRoutes.find(
            (d) => d.title === AllowedArray[0]
          );
          console.log("AllowedArray ----->", foundRoute);
          saveTokenInLocalStorage(dispatch, authToken);
          saveUserInLocalStorage(dispatch, role, userId);
          dispatch(loginConfirmedAction(authToken));
          savePermissionsInLocalStorage(dispatch, permissions);
          // history.push(`/${foundRoute.route}`);
          window.location.href = `/${foundRoute.route}`;
        }

        // window.location.href = "/inquiry-list";
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = error.data.message;
        swal("Oops", error.data.message, "error");
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
