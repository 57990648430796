import React, { useState } from "react";
import { Link } from "react-router-dom";
import { isEmail } from "validator";
// image
import logo from "../../images/logo.png";
import { verifyObject } from "../../utilities/utils";
import swal from "sweetalert";
import { forgotPassword } from "../../services/forgotPassword";
const ForgotPassword = ({ history }) => {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();
    await setSubmitting(true);
    try {
      let response = await forgotPassword({ email: email });
      console.log("response: ", response);
      if (response.status === 200) {
        await swal({
          title: "Success",
          text: response.data.message,
          buttons: false,
          icon: "success",
          timer: 1000,
        });
        await setSubmitting(false);
        history.push(`page-reset-password?email=${email}`);
      }
    } catch (e) {
      await setSubmitting(false);
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
    // history.push("/dashboard");
  };
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img className="logo-size" src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Forgot Password</h4>

                    <div className="form-group">
                      <label className="">
                        <strong>Email</strong>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        onChange={handleChange}
                        value={email}
                        placeholder="Enter your email address"
                      />
                    </div>
                    <div className="text-center  form-group mt-3">
                      <button
                        type="button"
                        onClick={onSubmit}
                        className="btn btn-primary btn-block"
                        disabled={
                          email === "" || isEmail(email) === false || submitting
                        }
                      >
                        {submitting ? "Submitting" : "SUBMIT"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
