import React from "react";
import { Nav, Tab } from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";

import moment from "moment";
import { DATE_FORMAT_12_HR } from "../../../constants/common";
import EvisaDocument from "./EvisaDocument";
const EvisaDetails = ({ evisaDetails }) => {
  // const evisaDetails = JSON.parse(localStorage.getItem("evisaDetail"));
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              {evisaDetails && (
                <div className="row">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <Tab.Container defaultActiveKey="Posts1">
                        <Nav as="ul" className="nav nav-tabs">
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link to="#my-posts" eventKey="Posts1">
                              E-Visa Charges
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li" i className="nav-item">
                            <Nav.Link to="#about-me" eventKey="Posts2">
                              E-Visa Documents
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane id="my-posts" eventKey="Posts1">
                            <div className="my-post-content pt-3">
                              <div className="col-lg-12">
                                <div className="card">
                                  <div className="card-header">
                                    {" "}
                                    E-Visa Detail{" "}
                                    <strong>
                                      {moment(evisaDetails.createdAt).format(
                                        DATE_FORMAT_12_HR
                                      )}
                                    </strong>{" "}
                                    <span className="float-right">
                                      <strong>{evisaDetails.fullName}</strong>
                                    </span>{" "}
                                  </div>
                                  <div className="card-body">
                                    <div className="row mb-5">
                                      <div className="mt-4 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                                        <h6>Applicant Details:</h6>
                                        <div>
                                          {" "}
                                          <strong>
                                            {evisaDetails.fullName}
                                          </strong>
                                        </div>
                                        <div>Email: {evisaDetails.email}</div>
                                        <div>
                                          Phone: {evisaDetails.mobileNumber}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="table-responsive">
                                      <table className="table table-striped">
                                        <thead>
                                          <tr>
                                            <th className="left">
                                              Your E-Visa Charges
                                            </th>
                                            <th className="center"></th>
                                            <th className="right">Total</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr></tr>
                                          {evisaDetails.us_ca_passports.length >
                                            0 && (
                                            <tr>
                                              <td className="center">{`US/Canada Passports `}</td>
                                              <td className="left strong">
                                                $200 x{" "}
                                                {
                                                  evisaDetails.us_ca_passports
                                                    .length
                                                }
                                              </td>
                                              <td className="left">
                                                $
                                                {evisaDetails.us_ca_passports
                                                  .length * 200}
                                              </td>
                                            </tr>
                                          )}
                                          {evisaDetails.other_passports.length >
                                            0 && (
                                            <tr>
                                              <td className="center">{`Other Nationalities Passports `}</td>
                                              <td className="left strong">
                                                $350 x{" "}
                                                {
                                                  evisaDetails.other_passports
                                                    .length
                                                }
                                              </td>
                                              <td className="left">
                                                $
                                                {evisaDetails.other_passports
                                                  .length * 350}
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-4 col-sm-5"> </div>
                                      <div className="col-lg-4 col-sm-5 ms-auto">
                                        <table className="table table-clear">
                                          <tbody>
                                            <tr>
                                              <td className="left">
                                                <strong>Subtotal</strong>
                                              </td>
                                              <td className="right">
                                                $
                                                {evisaDetails.other_passports
                                                  .length *
                                                  350 +
                                                  evisaDetails.us_ca_passports
                                                    .length *
                                                    200}
                                              </td>
                                            </tr>
                                            {/* <tr>
                                  <td className="left">
                                    <strong>Discount (20%)</strong>
                                  </td>
                                  <td className="right">$1,699,40</td>
                                </tr> */}
                                            {/* <tr>
                                  <td className="left">
                                    <strong>VAT (10%)</strong>
                                  </td>
                                  <td className="right">$679,76</td>
                                </tr> */}
                                            <tr>
                                              <td className="left">
                                                <strong>Total</strong>
                                              </td>
                                              <td className="right">
                                                <strong>
                                                  $
                                                  {evisaDetails.other_passports
                                                    .length *
                                                    350 +
                                                    evisaDetails.us_ca_passports
                                                      .length *
                                                      200}
                                                </strong>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane id="my-posts" eventKey="Posts2">
                            <div className="my-post-content pt-3 h-100">
                              {evisaDetails.us_ca_passports.length > 0 && (
                                <div className="row">
                                  <h3>US/Canada Passports</h3>
                                  <div
                                    style={{ height: 400, overflow: "auto" }}
                                  >
                                    {evisaDetails.us_ca_passports.map((d) => {
                                      return Object.keys(d).map((key) => {
                                        return d[key].map((v) => {
                                          return (
                                            <EvisaDocument
                                              title={key}
                                              documentItem={v}
                                            />
                                          );
                                        });
                                      });
                                    })}
                                  </div>
                                </div>
                              )}
                              <hr />
                              {evisaDetails.other_passports.length > 0 && (
                                <div className="row h-100">
                                  <h3>Other Passports</h3>
                                  <div
                                    style={{
                                      height: 400,
                                      overflow: "auto",
                                    }}
                                  >
                                    {evisaDetails.other_passports.map((d) => {
                                      return Object.keys(d).map((key) => {
                                        return d[key].map((v) => {
                                          return (
                                            <EvisaDocument
                                              title={key}
                                              documentItem={v}
                                              type="other"
                                            />
                                          );
                                        });
                                      });
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* review */}
      </div>
    </>
  );
};

export default EvisaDetails;
