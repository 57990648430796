/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import { ValidateInput } from "./ValidateFAQs";
import { Formik } from "formik";
import * as Yup from "yup";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import { addNewFaq, getFaqTypeList } from "../../../services/FAQs";
import { verifyObject } from "../../../utilities/utils";
import { actions as FaqActions } from "../../../store/reducers/faqReducer";
import { useDispatch } from "react-redux";
import { TINY_MCE_API_KEY, TINY_MCE_EDITOR } from "../../../constants/common";

export default function AddNewFAQ({ userModal, setAddUserModal }) {
  //   const filterColors = (inputValue: string) => {
  //   return colourOptions.filter((i) =>
  //     i.label.toLowerCase().includes(inputValue.toLowerCase())
  //   );
  const intialFormData = {
    faq_title: "",
    faq_question: "",
    faq_answer: "",
    faq_type: "",
  };
  const [addFormData, setAddFormData] = useState(intialFormData);
  const [isLoading, setLoading] = useState(false);
  const [faqTypes, setFaqTypes] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    _getFaqTypeList();
  }, []);

  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);

    try {
      let payload = {
        title: values.faq_title,
        question: values.faq_question,
        answer: values.faq_answer,
        faqType: values.faq_type,
      };
      let response = await addNewFaq(payload);

      console.log("Response roles", response);
      // setUserArray(response.data.data.docs);
      // this.setState()
      setAddUserModal();
      await setSubmitting(false);
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await dispatch(FaqActions.onRequest({}));
      await setAddFormData(intialFormData);
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };

  const _getFaqTypeList = async () => {
    try {
      await setLoading(true);
      let response = await getFaqTypeList({});
      console.log("Response roles", response);
      let faqTypes = response.data.data.docs;
      let options = [];
      faqTypes.map((d) => {
        options.push({
          value: d._id,
          label: d.name,
        });
        return null;
      });
      await setFaqTypes(faqTypes);
      await setLoading(false);

      // this.setState()
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setLoading(false);
    }
  };
  console.log("options", faqTypes);
  return (
    <div>
      {" "}
      <Modal size="lg" className="bd-example-modal-lg fade" show={userModal} onHide={setAddUserModal}>
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={addFormData}
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Add New FAQ</h4>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              FAQ Title
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="faq_title"
                                value={values.faq_title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter FAQ title"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.faq_title}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              FAQ Type
                            </label>
                            <div className="contact-name">
                              {/* <Select
                                // defaultValue={selectedOption}
                                // onChange={setSelectedOption}
                                options={faqTypes}
                                // loadOptions={loadOptions}
                                isLoading={isLoading}
                                onChange={(e) => {
                                  setFieldValue("faq_type", e.value);
                                }}
                                style={{
                                  lineHeight: "40px",
                                  color: "#7e7e7e",
                                  paddingLeft: " 15px",
                                }}
                              /> */}
                              <select
                                name='faq_type'
                                className="form-control"
                                autoComplete="off"
                                value={values.faq_type}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter FAQ title"
                              >
                                <option value='' > Select FAQ Type</option>
                                {faqTypes.map((k, i) => {
                                  return (
                                    <option value={k._id}>{k.name}</option>
                                  );
                                })}
                              </select>
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.faq_type}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              FAQ Question
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="faq_question"
                                value={values.faq_question}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter faq question"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.faq_question}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              FAQ Answer
                            </label>
                            <div className="contact-name">
                              <Editor
                              apiKey={TINY_MCE_API_KEY}

                                // initialValue="<p>This is the initial content of the editor</p>"
                                init={TINY_MCE_EDITOR}
                                
                                // init={{
                                //   height: 300,
                                //   menubar: false,
                                //   plugins: [
                                //     "advlist autolink lists link image code charmap print preview anchor",
                                //     "searchreplace visualblocks code fullscreen",
                                //     "insertdatetime media table paste code help wordcount",
                                //   ],
                                //   toolbar:
                                //     "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
                                //     "bullist numlist outdent indent | removeformat | help ",
                                //   content_style: "body { color: #828282 }",
                                // }}
                                onEditorChange={(e) => {
                                  setFieldValue("faq_answer", e);
                                }}
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.faq_answer}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        onClick={setAddUserModal}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
