import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { ValidateInput } from "./ValidateImages";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
// import { addNewImage } from "../../../services/Blogs";
import swal from "sweetalert";
import { actions as imageActions } from "../../../store/reducers/imageGallaryReducer";
import { useDispatch } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import {
  createNewImage,
  getAllImageGalleryCategories,
} from "../../../services/ImageGallary";
import { Image } from "antd";
import { TINY_MCE_API_KEY, TINY_MCE_EDITOR } from "../../../constants/common";

export default function AddNewImage({
  userModal,
  setAddUserModal,
  _getBlogList,
}) {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const [faqTypes, setFaqTypes] = useState([]);
  const [displayedImage, setDisplayImage] = useState([]);

  useEffect(() => {
    _getFaqTypeList();
  }, []);

  const _getFaqTypeList = async () => {
    try {
      let response = await getAllImageGalleryCategories({});
      console.log("Response roles", response);
      let faqTypes = response.data.data.docs;
      let options = [];
      faqTypes.map((d) => {
        options.push({
          value: d._id,
          label: d.name,
        });
        return null;
      });
      await setFaqTypes(faqTypes);

      // this.setState()
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
    }
  };
  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);
    try {
      let formData = new FormData();
      for (const image of values.image_file) {
        formData.append("images", image);
      }
      // formData.set("images", values.image_file);
      formData.set("title", values.title);
      formData.set("description", values.image_description);
      formData.set("type", values.image_type);
      let response = await createNewImage(formData);
      console.log("Response roles", response);

      // setAddUserModal();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await dispatch(imageActions.onRequest({}));
      await setSubmitting(false);
      await ResetForm();
      await setAddUserModal();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };
  const ResetForm = () => {
    formikRef?.current?.resetForm({
      title: "",
      image_file: "",
      image_description: "",
      image_type: null,
    });
    setDisplayImage([]);
  };
  return (
    <div>
      {" "}
      <Modal
        size="lg"
        className="fade bd-example-modal-lg"
        show={userModal}
        onHide={setAddUserModal}
      >
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                title: "",
                image_file: "",
                image_description: "",
                image_type: null,
              }}
              innerRef={formikRef}
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                // setSubmitting(false);
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Add New Image</h4>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Title
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="title"
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter title"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.title}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Image
                            </label>
                            <div className="contact-name">
                              <input
                                className="form-control"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                multiple
                                // id="formFile"
                                // value={values.blog_image}
                                onChange={(e) => {
                                  setFieldValue("image_file", e.target.files);
                                  let imagefiles = [];
                                  for (const singleFile of e.target.files) {
                                    // formData.append("images", image);
                                    imagefiles = [
                                      ...imagefiles,
                                      URL.createObjectURL(singleFile),
                                    ];
                                  }
                                  setDisplayImage([
                                    ...displayedImage,
                                    ...imagefiles,
                                  ]);
                                }}
                                onBlur={handleBlur}
                                placeholder="Choose image"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.image_file}
                                </span>
                              )}
                              <div className="displayed-img-cont">
                                {displayedImage?.map((im) => {
                                  return (
                                    <Image
                                      className="displayed-image"
                                      width={100}
                                      src={im}
                                      style={{
                                        marginRight: "10px",
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Image Type
                            </label>
                            <div className="contact-name">
                              <select
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="image_type"
                                value={values.image_type}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter blog title"
                              >
                                <option selected disabled value={""}>
                                  Select Image type
                                </option>
                                {faqTypes.map((ft) => {
                                  return (
                                    <option key={ft._id} value={ft._id}>
                                      {ft.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.image_type}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Description
                            </label>
                            <div className="contact-name">
                              <Editor
                                apiKey={TINY_MCE_API_KEY}
                                // initialValue="<p>This is the initial content of the editor</p>"
                                init={TINY_MCE_EDITOR}
                                // init={{
                                //   height: 300,
                                //   menubar: false,
                                //   plugins: [
                                //     "advlist autolink lists link image code charmap print preview anchor",
                                //     "searchreplace visualblocks code fullscreen",
                                //     "insertdatetime media table paste code help wordcount",
                                //   ],
                                //   toolbar:
                                //     "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
                                //     "bullist numlist outdent indent | removeformat | help ",
                                //   content_style: "body { color: #828282 }",
                                // }}
                                onEditorChange={(e) => {
                                  setFieldValue("image_description", e);
                                }}
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.image_description}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Adding..." : "Add"}
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setAddUserModal();
                          ResetForm();
                        }}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
