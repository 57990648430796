/* eslint-disable jsx-a11y/anchor-is-valid */
import { Popover } from "antd";
import { isArray } from "lodash";
import moment from "moment";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  DATE_FORMAT,
  EVISA_INQ_TYPE,
  FLIGHT_HOTEL_INQ_TYPE,
  FLIGHT_INQ_TYPE,
} from "../../../constants/common";
// import { render } from 'react-dom';
import PerfectScrollbar from "react-perfect-scrollbar";

import { slideDown, slideUp } from "./animation";
import "./style.css";
import ContentLoader from "../../../scss/components/common/ContentLoader";
import EvisaDetails from "./eVisaDetails";
import TableLoader from "../common/TableLoader";
import NotFoundLable from "../common/NotFoundLable";
import { formatPhoneNumber, verifyObject } from "../../../utilities/utils";
import swal from "sweetalert";
import {
  assignInquiry,
  deleteInquiry,
  exportInquires,
} from "../../../services/inquiries";
import { Button } from "react-bootstrap";
import { saveAs } from "file-saver";
import { getUsers } from "../../../services/Users";
import AssigneInquiryModal from "./AssignInquiryModal";

function formatDate(str) {
  return str.substr(0, 10);
}

function capitalize(str) {
  return str
    .split(" ")
    .map((s) => {
      return s.charAt(0).toUpperCase() + s.substr(1);
    })
    .join(" ");
}

class UserTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      showModal: false,
    };
  }

  toggleExpander = (e) => {
    if (e.target.type === "checkbox") return;

    if (!this.state.expanded) {
      this.setState({ expanded: true }, () => {
        if (this.refs.expanderBody) {
          slideDown(this.refs.expanderBody);
        }
      });
    } else {
      slideUp(this.refs.expanderBody, {
        onComplete: () => {
          this.setState({ expanded: false });
        },
      });
    }
  };
  deleteRecord = async (id) => {
    try {
      // await setLoading(true);
      let response = await deleteInquiry(id);
      console.log("Response roles", response);
      swal("Poof! Your record has been deleted!", {
        icon: "success",
        timer: 3000,
        buttons: false,
      });
      // _getBlogList();
      this?.props?.onRequestInquiries();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      console.log("eee", e);
      swal("Server Error", errorMessage, "error");
      // await setLoading(false);
    }
  };

  // _exportInquiry = async (d) => {
  //   console.log("dates", d);
  //   try {
  //     let payload = {
  //       type: 1,
  //       startDate: moment(d?.departureDate).format("YYYY-MM-DD"),
  //       endDate: moment(d?.returnDate).format("YYYY-MM-DD"),
  //     };
  //     let response = await exportInquires(payload);
  //   } catch (e) {
  //     console.log("Response roles");
  //     if (e) {
  //       var blob = new Blob([e], {
  //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       });
  //       saveAs(blob, `${d.fullName}-inquiry.xlsx`);
  //     } else {
  //       swal("Server Error", "Something went wrong", "error");
  //     }
  //   }
  // };
  assignInquiry = async (userId) => {
    this.setState({ isAssigning: true }, async () => {
      try {
        let payload = {
          userId: userId,
          inquiryId: this.props.user._id,
          inquiryType: 1,
        };
        // await setLoading(t rue);
        let response = await assignInquiry(payload);
        console.log("Response roles", response);
        swal("Success", response?.data.message, "success");
        this.setState({ isAssigning: false });
        if (this.props && typeof this.props.onRequestInquiries === "function") {
          this.props.onRequestInquiries();
        }
        //   await exportInquires(payload);
        // await setSubmitting(false);
        // await handleClose();
      } catch (e) {
        if (e?.response) {
          console.log("e", e?.response);
          let errorMessage = verifyObject(
            e,
            "response.data.message",
            "Something went wrong"
          );
          swal("Server Error", errorMessage, "error");
        }
        this.setState({ isAssigning: false });

        // await setSubmitting(false);
      }
    });
  };

  render() {
    const {
      state,
      user,
      updatingStatusID,
      saveDetail,
      handleStatusChange,
      handleCommitEdit,
      isCommentEditable,
      selectedComment,
      handleCommentChange,
      updatingStatus,
      handldSubmitCommentChange,
      updatingComment,
      permissions,
    } = this.props;
    const editPermissions = permissions?.editPermissions;
    const deletePermissions = permissions?.deletePermissions;
    console.log("user------>", user);
    return [
      <tr key={user.id} role="row" className="odd">
        <td className="text-nowrap">
          <p>
            <span>{user.refId}</span>
          </p>
        </td>
        <td>{moment(user?.createdAt).format("DD-MM-YYYY hh:mm A")}</td>
        {/* <td>
          <div className="concierge-bx d-flex align-items-center">
            <div>
              <h5 className="fs-16 mb-0 text-nowrap">
                <Link to={"#"} className="text-black">
                 
                </Link>
              </h5>
            </div>
          </div>
        </td> */}

        <td className="text-nowrap">
          <span>{user.fullName}</span>

          <p>{user.email}</p>
          <p>
            <a className="text-primary" href={`tel:${user?.mobileNumber}`}>
              {" "}
              {formatPhoneNumber(user?.mobileNumber, true)}
            </a>
          </p>
        </td>
        <td>
          {" "}
          {this.props.u_data.role !== "admin" && user?.assignedUser?.fullName}
          {this.state.isAssigning && <span>Assigning....</span>}
          {!this.state.isAssigning &&
            this.props.user.isAssignee !== "true" &&
            "UnAssigned"}
          {this.props.u_data.role === "admin" && (
            <Fragment>
              {!this.state.isAssigning && (
                <select
                  type="text"
                  className="form-control form-control-sm mt-3"
                  autoComplete="off"
                  name="assigne_user_id"
                  value={this.state.assigne_user_id || user?.assignedUser?._id}
                  onChange={(e) => {
                    this.setState({ assigne_user_id: e.target.value }, () => {
                      this.assignInquiry(e.target.value);
                    });
                  }}
                >
                  <option selected disabled value={""}>
                    Select User
                  </option>
                  {this.props?.allusers?.map((u) => {
                    return <option value={u._id}>{u?.fullName}</option>;
                  })}
                </select>
              )}
              {user?.assignedUser?._id !== this.props?.u_data?.userId && (
                <p>
                  <button
                    className="btn-sm btn btn-secondary mt-3"
                    onClick={() => this.assignInquiry(this.props.u_data.userId)}
                  >
                    Assign Myself
                  </button>
                </p>
              )}
            </Fragment>
          )}
          {this.props.u_data.role !== "admin" &&
            this.props.user.isAssignee !== "true" && (
              <p>
                <button
                  className="btn-sm btn-primary btn"
                  onClick={() => this.assignInquiry(this.props.u_data.userId)}
                >
                  Assign Myself
                </button>
              </p>
            )}
        </td>

        <td>
          {updatingStatusID !== user?._id && (
            <select
              onChange={(e) => handleStatusChange(e, user)}
              className="form-control form-control-sm"
              id="inputState"
              defaultValue={user?.status}
              disabled={!editPermissions?.Inquiries}
              // value={status}
            >
              <option value="pending">Pending</option>
              <option value="received">Received</option>
              <option value="in_progress">In-Progress</option>
              <option value="cancelled">Cancelled</option>
              <option value="completed">Completed</option>
            </select>
          )}
          {updatingStatusID === user?._id && updatingStatus && (
            <ContentLoader />
          )}
        </td>
        <td>
          {selectedComment?._id !== user._id && (
            <>
              <p>{user.comment ? user.comment : "-"}</p>
              {editPermissions?.Inquiries && (
                <i
                  onClick={() => handleCommitEdit(user)}
                  className="fa fa-edit"
                ></i>
              )}
            </>
          )}
          {isCommentEditable && selectedComment?._id === user._id && (
            <Fragment>
              <textarea
                // className="form-control"
                placeholder="Enter additional details"
                defaultValue={user.comment}
                onChange={(e) => handleCommentChange(e, user)}
                cols={40}
              />
              <br />
              <button
                className="btn-sm btn-secondary"
                onClick={handldSubmitCommentChange}
              >
                {selectedComment?._id === user._id && updatingComment
                  ? "Editing..."
                  : "Submit"}
              </button>
            </Fragment>
          )}
        </td>
        <td>
          {/* <Button
            className="btn-sm btn-secondary mb-2"
            onClick={() => this._exportInquiry(user)}
          >
            Export
          </Button> */}
          {/* <Button
            className="btn-sm btn-secondary mb-2"
            onClick={() => this.setState({ showModal: !this.state.showModal })}
          >
            Assign Inquiry
          </Button>
          {this.state.showModal && (
            <AssigneInquiryModal
              showModal={this.state.showModal}
              setShowModal={() => {
                this.setState({ showModal: !this.state.showModal });
              }}
              inqDetails={user}
              u_data={this.props.u_data}
            />
          )} */}
          {deletePermissions?.Inquiries ? (
            <Button
              className="btn-sm btn-primary"
              onClick={() => {
                return swal({
                  title: "Are you sure?",
                  text: "Once deleted, you will not be able to recover this record!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    this.deleteRecord(user._id);
                  } else {
                    swal("Your record is safe!");
                  }
                });
              }}
            >
              Delete
            </Button>
          ) : (
            "-"
          )}
        </td>
        <td>
          {this.state.expanded ? (
            <i
              onClick={this.toggleExpander}
              class="fa fa-chevron-down cursor-pointer ct-chevron-icon"
            ></i>
          ) : (
            <i
              onClick={this.toggleExpander}
              class="fa fa-chevron-up cursor-pointer ct-chevron-icon"
            ></i>
          )}
        </td>
      </tr>,
      this.state.expanded && (
        <tr className="expandable" key="tr-expander">
          <td className="uk-background-muted" colSpan={10}>
            <div ref="expanderBody" className="inner uk-grid">
              {state.tripType === "HajjInquiry" && (
                <td className="text-nowrap">
                  <h5>Total Travelers</h5>
                  <span>{user.totalTravellers}</span>
                </td>
              )}
              {state.tripType === "tourInquiry" && (
                <Fragment>
                  {/* <td className="text-nowrap">
                    <h5>Total Travelers</h5>
                    <span>
                      {user.totalTravellers ? user.totalTravellers : "-"}
                    </span>
                  </td> */}
                  {/* <td className="text-nowrap">
                    <h5>Full Name</h5>
                    <span>{user.fullName}</span>
                  </td>
                  <td className="text-nowrap">
                    <h5>Email</h5>
                    <span>{user.email}</span>
                  </td>
                  <td className="text-nowrap">
                    <h5>Phone</h5>
                    <span>{user.mobileNumber}</span>
                  </td> */}
                  <td className="text-nowrap">
                    <h5>Destination</h5>
                    <span>{user.destination}</span>
                  </td>
                  <td className="text-nowrap">
                    <h5>Hotel Preference</h5>
                    <span>{user.roomRating}</span>
                  </td>
                </Fragment>
              )}
              {state.tripType === "contactUs" && (
                <Fragment>
                  <td className="text-nowrap">
                    <h5>Package</h5>
                    <span>{user.package ? user.package : "-"}</span>
                  </td>
                  <td className="text-nowrap">
                    <h5>Description</h5>
                    <span>{user.message ? user.message : "-"}</span>
                  </td>
                </Fragment>
              )}
              {(state.tripType === FLIGHT_HOTEL_INQ_TYPE ||
                state.tripType === FLIGHT_INQ_TYPE) && (
                <Fragment>
                  <td>
                    <h5>Travel Dates</h5>
                    <div>
                      <span>
                        {moment(user.departureDate).format(DATE_FORMAT)}
                        {user.returnDate &&
                          ` To ${moment(user.returnDate).format(DATE_FORMAT)}`}
                      </span>
                    </div>
                  </td>
                  <td>
                    <h5>Trip Details</h5>
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: 500,
                      }}
                      className="text-capitalize font-10"
                    >
                      {`${user.from} To ${user.to}`}
                    </span>
                    <p>
                      <div>
                        <div className="card-content">
                          <address>
                            <div>
                              <a
                                className={`btn btn-${
                                  user.travelClass === "Economy"
                                    ? "primary"
                                    : "secondary"
                                } light btn-xs mb-1 me-1`}
                              >
                                {user.travelClass}
                              </a>
                              {user.tripType === "multi_city" ? (
                                <Popover
                                  content={() => {
                                    return (
                                      <PerfectScrollbar className="widget-timeline dlab-scroll style-1 ps p-3 height370">
                                        <ul className="timeline">
                                          {user.multiCity &&
                                            user.multiCity.map((d) => {
                                              return (
                                                <li>
                                                  <div className="timeline-badge info" />
                                                  <Link
                                                    className="timeline-panel c-pointer text-muted"
                                                    to="#"
                                                  >
                                                    <span>
                                                      {moment(
                                                        d.depatureDate
                                                      ).format(DATE_FORMAT)}
                                                    </span>
                                                    <h6 className="mb-0">
                                                      {`${d.from} To ${d.to}`}
                                                    </h6>
                                                  </Link>
                                                </li>
                                              );
                                            })}
                                        </ul>
                                        <div
                                          className="ps__rail-x"
                                          style={{
                                            left: 0,
                                            bottom: 0,
                                          }}
                                        >
                                          <div
                                            className="ps__thumb-x"
                                            tabIndex={0}
                                            style={{
                                              left: 0,
                                              width: 0,
                                            }}
                                          />
                                        </div>
                                        <div
                                          className="ps__rail-y"
                                          style={{
                                            top: 0,
                                            right: 0,
                                          }}
                                        >
                                          <div
                                            className="ps__thumb-y"
                                            tabIndex={0}
                                            style={{
                                              top: 0,
                                              height: 0,
                                            }}
                                          />
                                        </div>
                                      </PerfectScrollbar>
                                    );
                                  }}
                                >
                                  <a
                                    className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                  >
                                    {user.tripType
                                      ? user.tripType.replaceAll("_", " ")
                                      : ""}
                                  </a>
                                </Popover>
                              ) : (
                                <a
                                  className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                >
                                  {user.tripType
                                    ? user.tripType.replaceAll("_", " ")
                                    : ""}
                                </a>
                              )}
                              <Popover
                                placement="bottom"
                                content={() => {
                                  return (
                                    <div className="card-content">
                                      {user.noOfTravellerType &&
                                        user.noOfTravellerType.map((d) => {
                                          return (
                                            <address>
                                              <strong className="text-capitalize">
                                                {d.type}
                                              </strong>
                                              <br />
                                              <a>Member : {d.members}</a>
                                            </address>
                                          );
                                        })}
                                    </div>
                                  );
                                }}
                              >
                                <a
                                  className={`btn btn-warning light btn-xs mb-1 me-1 text-capitalize`}
                                >
                                  Travelers
                                </a>
                              </Popover>
                            </div>
                            {user.isDateFixed && (
                              <div>
                                <a
                                  className={`btn btn-success light btn-xs mb-1 me-1`}
                                >
                                  Fixed Dates
                                </a>
                              </div>
                            )}
                            {user.isDateFlexible && (
                              <div>
                                <a
                                  className={`btn btn-dark light btn-xs mb-1 me-1`}
                                >
                                  Flexible with Dates
                                </a>
                              </div>
                            )}
                          </address>
                        </div>
                      </div>
                    </p>
                  </td>
                  {state.tripType === FLIGHT_HOTEL_INQ_TYPE &&
                    user?.roomType && (
                      <td>
                        <h5>Room Details</h5>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          {user?.roomType &&
                            user?.roomType?.map((_r) => {
                              return (
                                <div>
                                  <a
                                    className={`btn btn-dark light btn-xs mb-1 me-1`}
                                  >
                                    {_r?.roomType} : {_r.noOfRoom}
                                  </a>
                                </div>
                              );
                            })}
                          {/* <span
                          style={{
                            fontSize: 13,
                            fontWeight: 500,
                          }}
                          className="text-capitalize font-10"
                        >
                          {`No Of Rooms : ${user.noOfRooms}`}
                        </span> */}
                          {user.roomRating && (
                            <span
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                              className="text-capitalize font-10"
                            >
                              {`Room Ratings : ${user.roomRating} Star`}
                            </span>
                          )}
                        </div>
                      </td>
                    )}
                </Fragment>
              )}

              {state.tripType === EVISA_INQ_TYPE && (
                <Fragment>
                  <EvisaDetails evisaDetails={user} />
                  {/* <td>
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: 500,
                      }}
                      className="text-capitalize font-10"
                    >
                      {user.us_ca_passports ? user.us_ca_passports.length : "-"}
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: 500,
                      }}
                      className="text-capitalize font-10"
                    >
                      {user.other_passports ? user.other_passports.length : "-"}
                    </span>
                  </td>
                  <td>
                    {" "}
                    <Link
                      to={`/evisa-detail`}
                      onClick={() => saveDetail(user)}
                      className="btn btn-secondary "
                    >
                      Details
                    </Link>{" "}
                  </td> */}
                </Fragment>
              )}
              {/* <div className="uk-width-1-4 uk-text-center">
                <img
                  className="uk-preserve-width uk-border-circle"
                  alt="avatar"
                />
              </div>
              <div className="uk-width-3-4">
                <h3>{capitalize(user.name.first + " " + user.name.last)}</h3>
                <p>
                  Address:
                  <br />
                  <i>
                    {capitalize(user.location.street)}
                    <br />
                    {user.location.postcode} {capitalize(user.location.city)}
                    <br />
                    {user.nat}
                  </i>
                </p>
                <p>
                  E-mail: {user.email}
                  <br />
                  Phone: {user.phone}
                </p>
                <p>Date of birth: {formatDate(user.dob)}</p>
              </div> */}
            </div>
          </td>
        </tr>
      ),
    ];
  }
}

class FlightinquiryTable extends React.Component {
  state = { users: null, allusers: [] };

  // componentDidMount() {
  //   this._getUsersList();
  // }
  // _getUsersList = async () => {
  //   try {
  //     const response = await getUsers();
  //     console.log("users", response);
  //     const users = response?.data?.data?.docs;
  //     // setStatus(e.target.value)
  //     this.setState({ allusers: users });
  //     // setAllUsers(users);
  //   } catch (e) {
  //     let errorMessage = verifyObject(
  //       e,
  //       "response.data.message",
  //       "Something went wrong"
  //     );

  //     swal("Server Error", errorMessage, "error");
  //   }
  // };
  render() {
    const { InquiryListData, isLoading } = this.props;
    console.log("this.props.", this.props);
    return (
      <main>
        <div className="table-container">
          <div className="uk-overflow-auto">
            <table className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer">
              <thead>
                <tr>
                  <th className="sorting_asc">Ref.ID</th>
                  <th> Date/Time</th>
                  <th className="sorting">Contact Info</th>
                  <th className="sorting">Assignee</th>
                  <th>Status</th>
                  <th>Comment</th>
                  <th>Actions</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr style={{ textAlign: "center" }}>
                    <td colSpan="8">
                      <TableLoader />
                    </td>
                  </tr>
                ) : null}
                {!isLoading && InquiryListData?.length === 0 && (
                  <tr>
                    <td colSpan="8">
                      <NotFoundLable message="No Inquiries found" />
                    </td>
                  </tr>
                )}
                {InquiryListData &&
                  isArray(InquiryListData) &&
                  InquiryListData.map((user, index) => {
                    return (
                      <UserTableRow
                        {...this.props}
                        key={index}
                        index={index + 1}
                        user={user}
                        allusers={this.props.allUsers}
                        u_data={this.props.u_data}
                        onRequestInquiries={this.props.onRequestInquiries}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    );
  }
}

export default FlightinquiryTable;
