import { post, patch, get, deletee } from "./index";

export const createNewVideo = (data) => {
  const request = {
    subUrl: `/videoGallery`,
    data,
  };
  return post(request);
};

export const getAllVideos = (params) => {
  const request = {
    subUrl: `/videoGallery`,
    params,
  };
  return get(request);
};

export const updateVideo = (data, id) => {
  const request = {
    subUrl: `/videoGallery/${id}`,
    data,
  };
  return patch(request);
};

export const deleteVideo = (id) => {
  const request = {
    subUrl: `/videoGallery/${id}`,
  };
  return deletee(request);
};

//Video Gallary Category

export const createNewVideoGallaryCategory = (data) => {
  const request = {
    subUrl: `/videoGalleryCategory`,
    data,
  };
  return post(request);
};

export const getAllVideoGallaryCategories = (params) => {
  const request = {
    subUrl: `/videoGalleryCategory/`,
    params,
  };
  return get(request);
};

export const updateVideoGallaryCategories = (data) => {
  const request = {
    subUrl: `/videoGalleryCategory/${data._id}`,
    data,
  };
  return patch(request);
};

export const deleteVideoGallaryCategories = (_id) => {
  const request = {
    subUrl: `/videoGalleryCategory/${_id}`,
  };
  return deletee(request);
};
