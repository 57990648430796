import { post, patch, get, deletee } from "./index";

export const addNewIcon = (data) => {
  const request = {
    subUrl: `/packageIcon`,
    data,
  };
  return post(request);
};

export const getIconList = (params) => {
  const request = {
    subUrl: `/packageIcon`,
    params,
  };
  return get(request);
};

export const updateIcon = (data, id) => {
  const request = {
    subUrl: `/packageIcon/${id}`,
    data,
  };
  return patch(request);
};

export const deleteIcon = (id) => {
  const request = {
    subUrl: `/packageIcon/${id}`,
  };
  return deletee(request);
};
export const getIconsByID = (id) => {
  const request = {
    subUrl: `/packageIcon/${id}`,
  };
  return get(request);
};
