/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Dropdown } from "react-bootstrap";
import { actions as usersActions } from "../../../store/reducers/usersReducer";
import { Button, Modal } from "react-bootstrap";
import Icon, { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

///Import
// import PendingBlog from "./Guest/PendingBlog";
// import BookedBlog from "./Guest/BookedBlog";
// import CanceledBlog from "./Guest/CanceledBlog";
// import RefundBlog from "./Guest/RefundBlog";

//Images
import AddNewUser from "./AddNewUser";
import { isArray } from "lodash";
import EditUserData from "./EditUserData";
import swal from "sweetalert";
import { verifyObject } from "../../../utilities/utils";
import { deleteUser, resetPassword, updateUser } from "../../../services/Users";
import { useDispatch, useSelector } from "react-redux";
import TableLoader from "../common/TableLoader";
import NotFoundLable from "../common/NotFoundLable";
import Pagination from "../../components/common/Pagination";

const UsersList = () => {
  const [editedData, setEditFormData] = useState("Newest");
  const [userModal, setUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordEye, showPasswordEye] = useState(false);

  // const [usersArray, setUserArray] = useState([]);
  // const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { data, isLoading, pagination } = useSelector((state) => state.users);
  const { permissions } = useSelector((state) => state?.localStore);

  const createPermissions = permissions?.createPermissions;
  const editPermissions = permissions?.editPermissions;
  const deletePermissions = permissions?.deletePermissions;

  // const [data, setData] = useState(
  //   document.querySelectorAll("#example2_wrapper tbody tr")
  // );

  const handleEditClick = (event, data) => {
    event.preventDefault();
    const formValues = {
      _id: data._id,
      user_name: data.fullName,
      login_id: data.email,
      role: data.role._id,
      designation: data.designation,
    };
    setEditFormData(formValues);
    handleEditModal();
  };
  const handleResetPasswordModal = (event, data) => {
    event.preventDefault();
    const formValues = {
      _id: data._id,
      user_name: data.fullName,
      login_id: data.email,
      role: data.role._id,
      designation: data.designation,
    };
    setShowPasswordModal(true);
    setEditFormData(formValues);
    // handleEditModal();
  };

  const handleEditModal = () => {
    setEditUserModal(!editUserModal);
  };
  const deleteRecord = async (_id) => {
    try {
      // await setLoading(true);
      let response = await deleteUser({ _id });
      console.log("Response roles", response);
      swal("Poof! Your record has been deleted!", {
        icon: "success",
        timer: 2000,
        buttons: false,
      });
      _getUsersList();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      // await setLoading(false);
    }
  };

  const handleUserStatus = async (user) => {
    try {
      // await setLoading(true);
      console.log("Edit Data", editedData);
      let response = await updateUser({
        _id: user?._id,
        status: user.status === "active" ? "inactive" : "active",
        // password: password,
      });
      console.log("Response roles", response);
      swal(
        `Poof! User ${
          user.status === "active" ? "Disabeld" : "Enabled"
        } Successfully`,
        {
          icon: "success",
          timer: 2000,
          buttons: false,
        }
      );
      _getUsersList();
      _clearPasswordData();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      // await setLoading(false);
    }
  };

  const _resetPassword = async (e) => {
    e.preventDefault();
    try {
      // await setLoading(true);
      console.log("Edit Data", editedData);
      let response = await resetPassword({
        userId: editedData?._id,
        password: password,
      });
      console.log("Response roles", response);
      swal("Poof! Password has been Changed!", {
        icon: "success",
        timer: 2000,
        buttons: false,
      });
      _getUsersList();
      _clearPasswordData();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      // await setLoading(false);
    }
  };

  const _getUsersList = () => {
    dispatch(usersActions.onRequest({}));
  };

  const onPageChange = ({ page }) => {
    console.log("page", page);
    dispatch(usersActions.onRequest({ page }));
  };
  useEffect(() => {
    // _getUserList();
    _getUsersList();
  }, []);

  const DropdownBlog = (user) => {
    return (
      <>
        {(editPermissions?.Users || deletePermissions?.Users) && (
          <Dropdown className="dropdown">
            <Dropdown.Toggle
              as="div"
              className="btn-link i-false"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              {editPermissions?.Users && (
                <Dropdown.Item
                  onClick={(e) => handleEditClick(e, user)}
                  className="dropdown-item"
                >
                  Edit
                </Dropdown.Item>
              )}
              {
                <Dropdown.Item
                  onClick={(e) => handleResetPasswordModal(e, user)}
                  className="dropdown-item"
                >
                  Reset Password
                </Dropdown.Item>
              }
              {
                <Dropdown.Item
                  onClick={() => handleUserStatus(user)}
                  className="dropdown-item"
                >
                  {user.status === "active" ? "Disable User" : "Enable User"}
                </Dropdown.Item>
              }
              {deletePermissions?.Users && (
                <Dropdown.Item
                  onClick={() => {
                    return swal({
                      title: "Are you sure?",
                      text: "Once deleted, you will not be able to recover this record!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        deleteRecord(user._id);
                      } else {
                        swal("Your record is safe!");
                      }
                    });
                  }}
                  className="dropdown-item"
                >
                  Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </>
    );
  };

  // Active data

  // use effect

  // console.log(document.querySelectorAll(".sorting_1 input")[0].checked);

  // const [state, setState] = useState({
  //   start: moment().subtract(29, "days"),
  //   end: moment(),
  // });
  // const { start, end } = state;
  // const handleCallback = (start, end) => {
  //   setState({ start, end });
  // };

  const handleModal = (value) => {
    setUserModal(!userModal);
  };
  const _clearPasswordData = (data) => {
    setPassword("");
    setShowPasswordModal(false);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2 flex-wrap">
          <div className="newest ms-3">
            {createPermissions?.Users && (
              <Link
                to={"#"}
                className="btn btn-primary font-w600 mb-2 me-auto"
                onClick={() => {
                  setEditFormData(null);
                  handleModal();
                }}
              >
                + New User
              </Link>
            )}
            <AddNewUser
              editedData={editedData}
              userModal={userModal}
              setAddUserModal={handleModal}
              _getUsersList={_getUsersList}
            />
            <EditUserData
              editedData={editedData}
              userModal={editUserModal}
              setAddUserModal={handleEditModal}
              _getUsersList={_getUsersList}
            />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <div
                  id="example2_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example2"
                    className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting_asc">Name</th>
                        <th className="sorting">Login ID</th>
                        <th className="sorting">Designation</th>
                        <th className="sorting">Privilage | Role</th>
                        <th className="sorting bg-none"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="8">
                            <TableLoader />
                          </td>
                        </tr>
                      ) : null}
                      {!isLoading && data.length === 0 && (
                        <tr>
                          <td colSpan="8">
                            <NotFoundLable message="No Users found" />
                          </td>
                        </tr>
                      )}
                      {data &&
                        isArray(data) &&
                        data.map((user) => {
                          return (
                            <tr key={user.id} role="row" className="odd">
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-nowrap">
                                      <Link to={"#"} className="text-black">
                                        {user.fullName}
                                      </Link>
                                    </h5>
                                    {/* <span className="text-primary fs-14">
                                      #EMP-00025
                                    </span> */}
                                  </div>
                                </div>
                              </td>
                              <td className="text-nowrap">
                                <span>{user.email}</span>
                              </td>
                              <td>
                                <div>
                                  <span>{user.designation}</span>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <span className="text-capitalize">
                                    {verifyObject(user, "role.name", null)}
                                  </span>
                                </div>
                              </td>
                              <td>
                                {/* <DropdownBlog  />
								00 */}
                                {DropdownBlog(user)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info"></div>
                    <div
                      className="dataTables_paginate paging_simple_numbers mb-0"
                      id="example2_paginate"
                    >
                      <Pagination
                        pagination={pagination}
                        data={data}
                        onPageChange={onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Password Reset Modal */}
      <Modal
        show={showPasswordModal}
        className="modal fade"
        id="postModal"
        onHide={_clearPasswordData}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Set Password</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={_clearPasswordData}
            >
              <span>×</span>
            </Button>
          </div>
          <form onSubmit={_resetPassword}>
            <div className="modal-body">
              <div class="password-container">
                <input
                  type={passwordEye ? "text" : "password"}
                  className="form-control"
                  value={password}
                  placeholder="Type Your Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span className="password-eye">
                  {passwordEye ? (
                    <EyeOutlined
                      onClick={() => showPasswordEye(false)}
                      className="cursor-pointer"
                    />
                  ) : (
                    <EyeInvisibleOutlined
                      onClick={() => showPasswordEye(true)}
                      className="cursor-pointer"
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                onClick={_clearPasswordData}
              >
                Close
              </button>
              <button
                disabled={password === ""}
                type="submit"
                className="btn btn-primary"
                // onClick={_resetPassword}
              >
                Change Password
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default UsersList;
