import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ValidateInput } from "./ValidateIcons";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import swal from "sweetalert";
import { updateBlog } from "../../../services/Blogs";
import { actions as blogsActions } from "../../../store/reducers/iconLibReducer";
import { useDispatch } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import { Image } from "antd";
import { updateIcon } from "../../../services/iconLib";

export default function EditBlog({ userModal, setAddUserModal, editedData }) {
  const dispatch = useDispatch();
  const [displayedImage, setDisplayImage] = useState(null);

  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);
    try {
      let formData = new FormData();
      formData.set("name", values.icon_name);
      if (values.icon_image_updated) {
        formData.set("image", values.icon_image_updated);
      } else {
        formData.set("image", values.icon_image);
      }
      let response = await updateIcon(formData, editedData.id);

      setAddUserModal();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await dispatch(blogsActions.onRequest({}));
      await setSubmitting(false);
    } catch (e) {
      console.log("eeee", e);
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };
  return (
    <div>
      {" "}
      <Modal
        className="fade bd-example-modal-lg"
        size="lg"
        show={userModal}
        onHide={setAddUserModal}
      >
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                ...editedData,
                icon_image_updated: null,
              }}
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                // setSubmitting(false);
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Update New Blog</h4>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Icon Title
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="icon_name"
                                value={values.icon_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter blog title"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.icon_name}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group mb-3 col-lg-6">
                              <label className="text-black font-w500">
                                Icon Image
                              </label>
                              <div className="contact-name">
                                <input
                                  className="form-control"
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  // id="formFile"
                                  // value={values.icon_image}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "icon_image_updated",
                                      e.currentTarget.files[0]
                                    );
                                    setDisplayImage(
                                      URL.createObjectURL(
                                        e.currentTarget.files[0]
                                      )
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  placeholder="Choose image"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.icon_image}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <Image
                                width={200}
                                src={
                                  displayedImage
                                    ? displayedImage
                                    : values.icon_image
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Updating..." : "Update"}
                      </button>
                      <button
                        type="button"
                        onClick={setAddUserModal}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
