/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import swal from "sweetalert";
import EditTermsConditions from "./EditTerms&Conditions";
import TableLoader from "../common/TableLoader";
import NotFoundLable from "../common/NotFoundLable";
import { useSelector } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import { getTermsConditionsList } from "../../../services/termsConditions";

const TermsConditionsList = () => {
  const [editedData, setEditFormData] = useState("Newest");
  const [editUserModal, setEditUserModal] = useState(false);
  const [termsConditions, setTermsConditions] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { permissions,user } = useSelector((state) => state?.localStore);
  const editPermissions = permissions?.editPermissions;

  const handleEditClick = (event, data) => {
    console.log("data", data);
    event.preventDefault();
    const formValues = {
      id: data._id,
      description: data.description,
    };
    setEditFormData(formValues);
    handleEditModal();
  };

  const handleEditModal = () => {
    setEditUserModal(!editUserModal);
  };

  const _getTermsConditionsList = async (status, id) => {
    setLoading(true);
    try {
      let response = await getTermsConditionsList();
      console.log("Response roles", response);
      setTermsConditions(response?.data?.data);
      setLoading(false);

      // setUserArray(response.data.data.docs);
    } catch (e) {
      setLoading(false);

      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
    }
  };

  useEffect(() => {
    _getTermsConditionsList();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2 flex-wrap">
          <div className="newest ms-3">
            <EditTermsConditions
              editedData={editedData}
              userModal={editUserModal}
              setAddUserModal={handleEditModal}
              _getTermsConditionsList={_getTermsConditionsList}
            />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <div
                  id="example2_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example2"
                    className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting"></th>
                        <th className="sorting_asc">
                          Terms & Conditions Content
                        </th>
                        {(editPermissions?.Terms || user.role === "admin") && (
                          <th className="sorting bg-none">
                            {" "}
                            <button
                              onClick={(e) =>
                                handleEditClick(e, termsConditions)
                              }
                              className="btn btn-primary"
                            >
                              Update
                            </button>{" "}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="8">
                            <TableLoader />
                          </td>
                        </tr>
                      ) : null}
                      {!isLoading && !termsConditions && (
                        <tr>
                          <td colSpan="8">
                            <NotFoundLable message="No Terms Conditions found" />
                          </td>
                        </tr>
                      )}

                      {termsConditions && (
                        <tr role="row" className="odd">
                          <td>
                            <div className="concierge-bx d-flex align-items-center">
                              <div>
                                <h5 className="fs-16 mb-0 text-nowrap">{1}</h5>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="concierge-bx d-flex align-items-center">
                              <div>
                                <h5 className="fs-16 mb-0 text-wrap">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: termsConditions.description,
                                    }}
                                  />
                                </h5>
                              </div>
                            </div>
                          </td>

                          {/* <td>{DropdownBlog(termsConditions)}</td> */}
                        </tr>
                      )}
                    </tbody>
                  </table>

                  {/* <PaginationComponent pagination={pagination} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TermsConditionsList;
