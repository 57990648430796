/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";
import { useDispatch } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import { copyPackage, getPackageDetail } from "../../../../services/packages";
import { verifyObject } from "../../../../utilities/utils";
import PageTitle from "../../../layouts/PageTitle";
import TableLoader from "../../common/TableLoader";
import PackageAdditionalInfoList from "../PackagesAdditionalnfo/PackageAdditionalInfoList";
import PackagePricingList from "../PackagesPricing/PackagePricingList";
import AddNewPackage from "./AddNewPackage";
import swal from "sweetalert";

const AddPackageDetails = () => {
  const [packegeDetail, setPackageDetails] = useState(null);
  const [fetching, setFetching] = useState(false);

  const [goSteps, setGoSteps] = useState(0);
  const dispatch = useDispatch(null);
  const { push } = useHistory();
  const { _id } = useParams();

  useEffect(() => {
    if (_id) {
      if (goSteps === 0) {
        _fetchPackageDetails();
        dispatch({
          type: "SET_PACKAGE_ID",
          payload: _id,
        });
      }
    }
  }, [goSteps]);

  const _fetchPackageDetails = async () => {
    try {
      setFetching(true);
      let packageDetails = await getPackageDetail(_id);
      setPackageDetails(verifyObject(packageDetails, "data.data", null));
      setFetching(false);
    } catch (e) {
      setFetching(false);
    }
  };

  const handleCopyPackage = async () => {
    try {
      setFetching(true);
      let copiedPackage = await copyPackage(_id);
      let copiedPkgId = copiedPackage?.data?.data?._id;
      // setPackageDetails(verifyObject(packageDetails, "data.data", null));
      if (copiedPkgId) {
        // successToast({
        //   content:
        //     "Your package was copied successfully, you can edit it here now",
        // });
        swal(
          "Package copied successfully",
          "Your package was copied successfully, you can edit it here now",
          "success"
        );
        push(`/packages-details/${copiedPkgId}`);
      }

      // console.log("package details", copiedPackage);

      setFetching(false);
    } catch (e) {
      setFetching(false);
    }
  };

  console.log(packegeDetail);

  return (
    <Fragment>
      <PageTitle
        to={"/packages-list"}
        activeMenu="Packages"
        motherMenu="Add Packages Details"
      />

      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            {/* <div className="card-header">
              <h4 className="card-title">Form step</h4>
            </div> */}
            <div className="card-body">
              <div className="form-wizard ">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {_id && (
                    <Button
                      onClick={handleCopyPackage}
                      className="btn btn-sm btn-secondary"
                    >
                      Copy Package
                    </Button>
                  )}
                </div>

                <Stepper
                  className="nav-wizard"
                  activeStep={goSteps}
                  label={true}
                >
                  <Step
                    label="Package Detail"
                    className="nav-link"
                    onClick={() => setGoSteps(0)}
                    // disabled={_id === undefined && goSteps > 0}
                  />
                  {/* <Step
                    label="Box Detail"
                    className="nav-link"
                    onClick={() => setGoSteps(1)}
                    disabled={_id === undefined && goSteps === 0}

                  />
                  <Step
                    label="Package Includes"
                    className="nav-link"
                    onClick={() => setGoSteps(2)}
                    disabled={_id === undefined && goSteps === 0}

                  /> */}
                  <Step
                    label="Additional Information"
                    className="nav-link"
                    onClick={() => setGoSteps(1)}
                    disabled={_id === undefined && goSteps === 0}
                  />
                  <Step
                    label="Pricing"
                    className="nav-link"
                    disabled={_id === undefined && goSteps === 0}
                    onClick={() => setGoSteps(2)}
                  />
                </Stepper>
                {fetching && <TableLoader />}
                {goSteps === 0 && (
                  <>
                    <AddNewPackage
                      packegeDetail={packegeDetail}
                      next={() => setGoSteps(1)}
                    />
                    {/* <button
                      className="btn btn-primary sw-btn-next ms-1"
                      onClick={() => setGoSteps(1)}
                    >
                      Next
                    </button> */}
                  </>
                )}
                {/* {goSteps === 1 && (
                  <>
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(0)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => setGoSteps(2)}
                      >
                        Next
                      </button>
                    </div>
                  </>
                )} */}
                {/* {goSteps === 2 && (
                  <>
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(1)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => setGoSteps(3)}
                      >
                        Next
                      </button>
                    </div>
                  </>
                )} */}
                {goSteps === 1 && (
                  <>
                    <PackageAdditionalInfoList packageId={_id} />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(0)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => setGoSteps(2)}
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
                {goSteps === 2 && (
                  <>
                    <PackagePricingList packageId={_id} />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(1)}
                      >
                        Prev
                      </button>
                      <Link
                        className="btn btn-primary sw-btn-next ms-1"
                        // onClick={() => setGoSteps(4)}
                        to={"/packages-list"}
                      >
                        Close
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddPackageDetails;
