export function ValidateInput(data) {
  let errors = {};
  console.log("data", data);
  if (data.title === "") {
    errors.title = "Image title  is required";
  }
  if (data.image_file === "") {
    errors.image_file = "Image  is required";
  }
  if (!data.image_type) {
    errors.image_type = "Image type  is required";
  }
  if (data.image_description === "") {
    errors.image_description = "Image Description  is required";
  }
  console.log("errors", errors);
  return errors;
}
