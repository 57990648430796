/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import ReactPaginate from "react-paginate";
export type IProps = {
  pageCount: number,
  onPageChange: () => {},
};

const Paginate = (props: IProps) => {
  const onPageChange = (data) => {
    // data.selected + 1 cause it starts from 0
    window.scroll(0, 0);
    props.onPageChange({ page: data.selected + 1 });
    
  };

  const { pageCount, page } = props;

  // console.log("PAGE : : : : : ", page)
  let paginate = null;
  if (pageCount > 1) {
    paginate = (
      <Fragment>
        <ReactPaginate
          pageCount={pageCount}
          disableInitialCallback={true}
          onPageChange={onPageChange}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          breakClassName={"page-item"}
          initialPage={page > 0 ? page - 1 : 0}
          breakLabel={<a className="page-link">...</a>}
          previousLabel={
            <a className="page-link">
              <i className="fa fa-angle-double-left" aria-hidden="true"></i>
            </a>
          }
          nextLabel={
            <a className="page-link">
              <i className="fa fa-angle-double-right" aria-hidden="true"></i>
            </a>
          }
          containerClassName={"pagination pagination-gutter"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-item page-indicator"
          nextLinkClassName="page-item page-indicator"
        />
      </Fragment>
    );
  }
  return paginate;
};

export default Paginate;
