/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { actions as tripInquiriesAction } from "../../../store/reducers/Inquiries";
import { actions as packageInquiriesAction } from "../../../store/reducers/packageInquiries";
import { Button } from "react-bootstrap";
import { saveAs } from "file-saver";

///Import
// import PendingBlog from "./Guest/PendingBlog";
// import BookedBlog from "./Guest/BookedBlog";
// import CanceledBlog from "./Guest/CanceledBlog";
// import RefundBlog from "./Guest/RefundBlog";

//Images
import { debounce, isArray } from "lodash";
import { verifyObject } from "../../../utilities/utils";
import { useDispatch, useSelector } from "react-redux";
import TableLoader from "../common/TableLoader";
import NotFoundLable from "../common/NotFoundLable";
import Pagination from "../../components/common/Pagination";
import moment from "moment";
import {
  DATE_FORMAT,
  EVISA_INQ_TYPE,
  FLIGHT_HOTEL_INQ_TYPE,
  FLIGHT_INQ_TYPE,
} from "../../../constants/common";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Popover, Radio } from "antd";
import "antd/es/popover/style/index";
import Packageinquiries from "./Packageinquiries";
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
import { DatePicker } from "antd";
import {
  exportInquires,
  updatePackageInquryComment,
  updatePackageInquryStatus,
} from "../../../services/inquiries";
import swal from "sweetalert";
import ContentLoader from "../../../scss/components/common/ContentLoader";
import FlightinquiryTable from "./FlightInquiryTable";
import PackageinquiryTable from "./PackageInquiryTable";
import ExportInquiryModal from "./ExportInquiryModal";
import { getUsers } from "../../../services/Users";

const { RangePicker } = DatePicker;

// import {}  from "antd"

const InquiryList = () => {
  const [state, setState] = useState({
    tripType: FLIGHT_INQ_TYPE,
  });
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingStatusID, setUpdatingStatusID] = useState(null);
  const [status, setStatus] = useState(null);
  const [InquiryListData, setData] = useState(null);

  const [selectedTab, setTab] = useState(FLIGHT_INQ_TYPE);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [selectedDate, setDate] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [packageFilter, setFilter] = useState({
    startDate: null,
    endDate: null,
    packageType: null,
  });
  const [isCommentEditable, setIsCommentEditable] = useState(false);
  const [selectedComment, setComment] = useState(null);
  const [changedComment, setChangedComment] = useState(null);
  const [updatingComment, setUpdatingComment] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  // const updateSearch = useCallback(debounce(updateSearch));
  const searchHandler = useCallback(
    debounce((e) => setSearchQuery(e), 1000),
    []
  );
  const { permissions, user } = useSelector((state) => state?.localStore);
  console.log("permissions", permissions.deletePermissions);

  const searchRef = useRef(null);

  const dispatch = useDispatch();
  const { data, isLoading, pagination } = useSelector(
    (state) => state.inquiries
  );

  useEffect(() => {
    setData(data);
  }, [data]);
  const packagesData = useSelector((state) => state.packageInq);

  // const _getTripInq = () => {
  //   dispatch(
  //     tripInquiriesAction.onRequest({
  //       type: state.tripType,
  //       startDate: packageFilter.startDate,
  //       endDate: packageFilter.endDate,
  //     })
  //   );
  // };

  // const _getPackageInq = () => {
  //   dispatch(packageInquiriesAction.onRequest({}));
  // };

  const onRequestInquiries = () => {
    dispatch(
      tripInquiriesAction.onRequest({
        page: page,
        limit: limit,
        ...packageFilter,
        search: searchValue,
        type: state.tripType,
      })
    );
  };

  const onRequestPkgInquiries = () => {
    dispatch(
      packageInquiriesAction.onRequest({
        page: page,
        limit: limit,
        ...packageFilter,
        type: state.tripType,
        search: searchValue,
      })
    );
  };

  // const onRequestInquiries = ({ page }) => {
  //   setPage(page);
  //   dispatch(
  //     tripInquiriesAction.onRequest({ page: page, type: state.tripType })
  //   );

  const setSearchQuery = (e) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const saveDetail = (detail) => {
    dispatch({
      type: "SET_EVISA_DETAILS",
      payload: detail,
    });
    localStorage.setItem("evisaDetail", JSON.stringify(detail));
  };

  // const handleTripType = (e) => {
  //   setState({
  //     ...state,
  //     tripType: e.target.value,
  //   });
  // };

  useEffect(() => {
    // _getUserList();
    // _getTripInq();
    if (
      state.tripType === FLIGHT_INQ_TYPE ||
      state.tripType === FLIGHT_HOTEL_INQ_TYPE ||
      state.tripType === EVISA_INQ_TYPE ||
      state.tripType === "HajjInquiry" ||
      state.tripType === "umrahInquiry" ||
      state.tripType === "tourInquiry" ||
      state.tripType === "contactUs"
    ) {
      console.log("Cleared");
      dispatch(
        tripInquiriesAction.onRequest({
          page: page,
          ...packageFilter,
          type: state.tripType,
          search: searchValue,
          limit: limit,
        })
      );
    } else {
      dispatch(
        packageInquiriesAction.onRequest({
          page: page,
          ...packageFilter,
          type: state.tripType,
          search: searchValue,
          limit: limit,
        })
      );
    }
  }, [
    packageFilter.startDate,
    packageFilter.endDate,
    state.tripType,
    searchValue,
  ]);

  // useEffect(() => {

  // }, [
  //   packageFilter.startDate,
  //   packageFilter.endDate,
  //   state.tripType,
  // ]);

  // useEffect(() => {
  //   // _getUserList();
  //   _getTripInq();
  // }, []);
  const onPageChange = ({ page, limitP }) => {
    setPage(page);

    // dispatch(
    //   tripInquiriesAction.onRequest({
    //     limit: limitP || limit,
    //     type: state.tripType,
    //   })
    // );

    if (
      state.tripType === FLIGHT_INQ_TYPE ||
      state.tripType === FLIGHT_HOTEL_INQ_TYPE ||
      state.tripType === EVISA_INQ_TYPE ||
      state.tripType === "HajjInquiry" ||
      state.tripType === "umrahInquiry" ||
      state.tripType === "tourInquiry" ||
      state.tripType === "contactUs"
    ) {
      console.log("Cleared");
      dispatch(
        tripInquiriesAction.onRequest({
          page: page,
          ...packageFilter,
          type: state.tripType,
          search: searchValue,
          limit: limit,
        })
      );
    } else {
      dispatch(
        packageInquiriesAction.onRequest({
          page: page,
          ...packageFilter,
          type: state.tripType,
          search: searchValue,
          limit: limit,
        })
      );
    }
  };
  const handleLimitChange = (e) => {
    let limitP = e.target.value;
    setLimit(e.target.value);
    onPageChange({ page: 1, limitP });
  };

  const handleDatePicker = (d) => {
    console.log(d);
    if (d) {
      setFilter({
        ...packageFilter,
        startDate: moment(d.$d).format("YYYY-MM-DD"),
        endDate: null,
      });
      setDate(d);
      setPage(1);

      setDateRange({
        startDate: null,
        endDate: null,
      });
    } else {
      // console.log("fdfdfd");
      // let ObjParams = {};
      // if (packageFilter.packageType) {
      //   ObjParams = {
      //     type: packageFilter.packageType,
      //   };
      // }
      // if (selectedTab === "trip_inq") {
      //   dispatch(
      //     tripInquiriesAction.onRequest({
      //       page: page,
      //       // ...ObjParams,
      //       type: state.tripType,
      //     })
      //   );
      // } else {
      //   dispatch(
      //     packageInquiriesAction.onRequest({
      //       page: verifyObject(packagesData, "pagination.page", 1),
      //       ...ObjParams,
      //     })
      //   );
      // }

      setDate(null);
    }

    // dispatch(
    //   tripInquiriesAction.onRequest({
    //     page: page,
    //     type: state.tripType,
    //     ...packageFilter,
    //   })
    // );
  };

  const handleDateRangePicker = (d) => {
    console.log("ddd");
    if (d) {
      setFilter({
        ...packageFilter,
        startDate: moment(d[0].$d).format("YYYY-MM-DD"),
        endDate: moment(d[1].$d).format("YYYY-MM-DD"),
      });
      setDateRange({
        startDate: d[0],
        endDate: d[1],
      });
      setDate(null);
    } else {
      // setFilter({
      //   ...packageFilter,
      //   startDate: moment(d[0].$d).format("YYYY-MM-DD"),
      //   endDate: moment(d[1].$d).format("YYYY-MM-DD"),
      // });
      setFilter({
        ...packageFilter,
        startDate: null,
        endDate: null,
      });
      setDateRange({
        startDate: null,
        endDate: null,
      });
      setDate(null);
      let ObjParams = {};
      if (packageFilter.packageType) {
        ObjParams = {
          type: packageFilter.packageType,
        };
      }
      if (selectedTab === "trip_inq") {
        dispatch(
          tripInquiriesAction.onRequest({
            page: page,
            limit: limit,

            type: state.tripType,
          })
        );
      } else {
        dispatch(
          packageInquiriesAction.onRequest({
            page: page,
            ...ObjParams,
          })
        );
      }
    }
    setPage(1);

    // dispatch(
    //   tripInquiriesAction.onRequest({
    //     page: page,
    //     type: state.tripType,
    //     ...packageFilter,
    //   })
    // );
  };

  // const handleTypeChage = (d) => {
  //   setFilter({
  //     ...packageFilter,
  //     packageType: d.target.value,
  //   });
  // dispatch(
  //   tripInquiriesAction.onRequest({
  //     page: page,
  //     type: state.tripType,
  //     ...packageFilter,
  //   })
  // );
  // };
  useEffect(() => {
    _getUsersList();
  }, []);

  const _getUsersList = async () => {
    try {
      const response = await getUsers();
      const users = response?.data?.data?.docs;
      // setStatus(e.target.value)
      // this.setState({ users: users });

      setAllUsers(users);
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );

      swal("Server Error", errorMessage, "error");
    }
  };
  const handleStatusChange = async (e, d) => {
    setUpdatingStatus(true);
    setUpdatingStatusID(d?._id);
    try {
      let payload = {
        status: e.target.value,
        inquiryType: 1,
        _id: d._id,
      };
      setStatus(e.target.value);
      let response = await updatePackageInquryStatus(payload);
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });

      // await _getTripInq();
      setData((InquiryListData) => {
        let inqi = InquiryListData;
        let _f = inqi?.find((i) => i._id === d._id);
        _f.status = e.target.value;

        console.log("_f", inqi);
        return inqi;
      });
      await setUpdatingStatus(false);
      await setUpdatingStatusID(null);
    } catch (err) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      // setStatus(e.target.value)

      swal("Server Error", errorMessage, "error");
      await setUpdatingStatus(false);
      await setUpdatingStatusID(null);

      // await setSubmitting(false);
    }
  };

  const handleCommitEdit = async (d) => {
    setIsCommentEditable(true);
    setComment(d);
    // setComment(d)
  };

  const handleCommentChange = async (e, d) => {
    if (d._id === selectedComment._id) {
      setChangedComment(e.target.value);
    }
  };
  const handldSubmitCommentChange = async (o) => {
    await setUpdatingComment(true);
    try {
      let payload = {
        comment: changedComment ? changedComment : selectedComment?.comment,
        inquiryType: 1,
        _id: selectedComment._id,
      };
      let response = await updatePackageInquryComment(payload);
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      setData((PackageListData) => {
        let inqi = PackageListData;
        let _f = inqi?.find((i) => i._id === selectedComment._id);
        _f.comment = changedComment ? changedComment : selectedComment?.comment;
        console.log("_f", inqi);
        return inqi;
      });
      await setUpdatingComment(false);
      await setUpdatingStatusID(null);
      await setChangedComment("");
      await setComment(null);
    } catch (err) {
      let errorMessage = verifyObject(
        err,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setUpdatingComment(false);
      await setUpdatingStatusID(null);
      await setChangedComment("");
      await setIsCommentEditable(false);
      await setComment(null);
    }
  };
  console.log(" allUsers={allUsers}", allUsers);
  const packageDataPagination = packagesData?.pagination;
  return (
    <>
      <div className="inquiry-filters-container">
        {user.role === "admin" && (
          <div className="filter-item">
            <Button
              className="btn-sm btn-secondary mb-2"
              onClick={() => setShowModal(true)}
            >
              Export Inquiries
            </Button>
            <ExportInquiryModal
              showModal={showModal}
              setShowModal={() => setShowModal(false)}
              user={user}
            />
          </div>
        )}

        <div className="filter-item">
          <input
            // value={[dateRange.startDate, dateRange.endDate]}
            name={"search"}
            className="form-control form-control-sm"
            placeholder="Search by name, email, ref id"
            style={{
              width: "250px",
            }}
            ref={searchRef}
            onChange={searchHandler}
          />
        </div>
        <div className="filter-item">
          <RangePicker
            value={[dateRange.startDate, dateRange.endDate]}
            onChange={handleDateRangePicker}
          />
        </div>
        <div className="filter-item">
          <DatePicker
            onChange={handleDatePicker}
            className="form-control form-control-sm"
            placeholder="Select Start Date"
            value={selectedDate}
            // placeholderText="DD/MM/YYYY"
            // selected={selectedDate}
            // dateFormat="dd/MM/yyyy"
          />
        </div>
        {/* {selectedTab !== "trip_inq" && (
          <div className="filter-item">
            <select
              onChange={handleTypeChage}
              className="form-control form-control-sm"
              value={packageFilter.packageType}
              name="packageType"
            >
              <option selected disabled value={null}>
                Please Select Type
              </option>
              <option value="Hajj">Hajj</option>
              <option value="Umrah">Umrah</option>
            </select>
          </div>
        )}
        {selectedTab === "trip_inq" && (
          <div className="filter-item">
            <select
              onChange={handleTripType}
              className="form-control form-control-sm"
              value={state.tripType}
              name="packageType"
            >
              <option selected disabled value={null}>
                Please Select Type
              </option>
              <option value={FLIGHT_INQ_TYPE}>{`Flights`}</option>
              <option value={FLIGHT_HOTEL_INQ_TYPE}>
                {"Flights and Hotels"}
              </option>
              <option value={EVISA_INQ_TYPE}>{"E-Visa"}</option>
            </select>
          </div>
        )} */}
      </div>

      <div className="row mt-4">
        <div className="profile-tab">
          <div className="custom-tab-1">
            <Tab.Container
              onSelect={(key) => {
                setTab(key);
                setPage(1);
                setLimit(20);
                setSearchValue("");
                if (searchRef) {
                  searchRef.current.value = "";
                }

                setState({
                  ...state,
                  tripType: key,
                });
                setDateRange({
                  startDate: null,
                  endDate: null,
                });
                setFilter({
                  startDate: null,
                  endDate: null,
                });

                setDate(null);
              }}
              activeKey={selectedTab}
            >
              <Nav as="ul" className="nav nav-tabs">
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link to="#my-posts" eventKey={FLIGHT_INQ_TYPE}>
                    Flights Inquiries{" "}
                    {`${
                      selectedTab === FLIGHT_INQ_TYPE
                        ? pagination.totalDocs
                          ? `(${pagination?.totalDocs})`
                          : ""
                        : ""
                    }`}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link to="#my-posts" eventKey={FLIGHT_HOTEL_INQ_TYPE}>
                    Flights And Hotels Inquiries{" "}
                    {`${
                      selectedTab === FLIGHT_HOTEL_INQ_TYPE
                        ? pagination.totalDocs
                          ? `(${pagination?.totalDocs})`
                          : ""
                        : ""
                    }`}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" i className="nav-item">
                  <Nav.Link to="#about-me" eventKey={EVISA_INQ_TYPE}>
                    E-Visa Inquiries
                    {`${
                      selectedTab === EVISA_INQ_TYPE
                        ? pagination.totalDocs
                          ? `(${pagination?.totalDocs})`
                          : ""
                        : ""
                    }`}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" i className="nav-item">
                  <Nav.Link to="#about-me" eventKey="umrahInquiry">
                    Umrah Inquiries
                    {`${
                      selectedTab === "umrahInquiry"
                        ? pagination.totalDocs
                          ? `(${pagination?.totalDocs})`
                          : ""
                        : ""
                    }`}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" i className="nav-item">
                  <Nav.Link to="#about-me" eventKey="HajjInquiry">
                    Hajj Inquiries
                    {`${
                      selectedTab === "HajjInquiry"
                        ? pagination.totalDocs
                          ? `(${pagination?.totalDocs})`
                          : ""
                        : ""
                    }`}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" i className="nav-item">
                  <Nav.Link to="#about-me" eventKey="tourInquiry">
                    Tour Inquiries
                    {`${
                      selectedTab === "tourInquiry"
                        ? packageDataPagination &&
                          packageDataPagination.totalDocs
                          ? `(${packageDataPagination?.totalDocs})`
                          : ""
                        : ""
                    }`}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" i className="nav-item">
                  <Nav.Link to="#about-me" eventKey="contactUs">
                    Contact Us
                    {`${
                      selectedTab === "contactUs"
                        ? packageDataPagination &&
                          packageDataPagination.totalDocs
                          ? `(${packageDataPagination?.totalDocs})`
                          : ""
                        : ""
                    }`}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" i className="nav-item">
                  <Nav.Link to="#about-me" eventKey="Hajj">
                    Hajj Packages
                    {`${
                      selectedTab === "Hajj"
                        ? packageDataPagination &&
                          packageDataPagination.totalDocs
                          ? `(${packageDataPagination?.totalDocs})`
                          : ""
                        : ""
                    }`}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" i className="nav-item">
                  <Nav.Link to="#about-me" eventKey="Umrah">
                    Umrah Packages
                    {`${
                      selectedTab === "Umrah"
                        ? packageDataPagination &&
                          packageDataPagination.totalDocs
                          ? `(${packageDataPagination?.totalDocs})`
                          : ""
                        : ""
                    }`}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" i className="nav-item">
                  <Nav.Link to="#about-me" eventKey="Other">
                    Other Packages
                    {`${
                      selectedTab === "Other"
                        ? packageDataPagination &&
                          packageDataPagination.totalDocs
                          ? `(${packageDataPagination?.totalDocs})`
                          : ""
                        : ""
                    }`}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane id="my-posts" eventKey={FLIGHT_INQ_TYPE}>
                  <div className="my-post-content pt-3">
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-body p-0">
                          <div className="table-responsive">
                            <div
                              id="example2_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              <FlightinquiryTable
                                allUsers={allUsers}
                                state={state}
                                updatingStatusID={updatingStatusID}
                                saveDetail={saveDetail}
                                handleStatusChange={handleStatusChange}
                                handleCommitEdit={handleCommitEdit}
                                isCommentEditable={isCommentEditable}
                                selectedComment={selectedComment}
                                handleCommentChange={handleCommentChange}
                                updatingStatus={updatingStatus}
                                handldSubmitCommentChange={
                                  handldSubmitCommentChange
                                }
                                permissions={permissions}
                                updatingComment={updatingComment}
                                InquiryListData={InquiryListData}
                                isLoading={isLoading}
                                onRequestInquiries={onRequestInquiries}
                                u_data={user}
                              />
                            </div>
                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                              <div className="dataTables_info"></div>
                              <div
                                className="dataTables_paginate paging_simple_numbers mb-0"
                                id="example2_paginate"
                              >
                                <Pagination
                                  pagination={pagination}
                                  data={data}
                                  limit={limit}
                                  onPageChange={onPageChange}
                                  handleLimitChange={handleLimitChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane id="my-posts" eventKey={FLIGHT_HOTEL_INQ_TYPE}>
                  <div
                    id="example2_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div className="my-post-content pt-3">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body p-0">
                            <div className="table-responsive">
                              <FlightinquiryTable
                                state={state}
                                updatingStatusID={updatingStatusID}
                                saveDetail={saveDetail}
                                handleStatusChange={handleStatusChange}
                                handleCommitEdit={handleCommitEdit}
                                isCommentEditable={isCommentEditable}
                                selectedComment={selectedComment}
                                handleCommentChange={handleCommentChange}
                                updatingStatus={updatingStatus}
                                handldSubmitCommentChange={
                                  handldSubmitCommentChange
                                }
                                updatingComment={updatingComment}
                                InquiryListData={InquiryListData}
                                isLoading={isLoading}
                                permissions={permissions}
                                u_data={user}
                                onRequestInquiries={onRequestInquiries}
                                allUsers={allUsers}
                              />
                              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                <div className="dataTables_info"></div>
                                <div
                                  className="dataTables_paginate paging_simple_numbers mb-0"
                                  id="example2_paginate"
                                >
                                  <Pagination
                                    pagination={pagination}
                                    data={data}
                                    limit={limit}
                                    onPageChange={onPageChange}
                                    handleLimitChange={handleLimitChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* //! Display None For Non Collapisable Table */}
                  <div
                    style={{ display: "none" }}
                    className="my-post-content pt-3"
                  >
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-body p-0">
                          <div className="table-responsive">
                            <div
                              id="example2_wrapper"
                              className="dataTables_wrapper no-footer"
                            >
                              <table
                                id="example2"
                                className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                              >
                                <thead>
                                  <tr role="row">
                                    <th className="sorting_asc">Ref.ID</th>
                                    <th className="sorting_asc">Name</th>
                                    <th className="sorting">Contact</th>
                                    {state.tripType === EVISA_INQ_TYPE && (
                                      <Fragment>
                                        <th className="sorting">
                                          No Of US/Canada Passport
                                        </th>
                                        <th className="sorting">
                                          No Of Other Passport
                                        </th>

                                        <th className="sorting"></th>
                                      </Fragment>
                                    )}

                                    {(state.tripType ===
                                      FLIGHT_HOTEL_INQ_TYPE ||
                                      state.tripType === FLIGHT_INQ_TYPE) && (
                                      <Fragment>
                                        <th className="sorting">
                                          Travel Dates
                                        </th>
                                        <th className="sorting">
                                          Trip Details
                                        </th>
                                        {state.tripType ===
                                          FLIGHT_HOTEL_INQ_TYPE && (
                                          <th className="sorting">
                                            Room Details
                                          </th>
                                        )}
                                        <th className="sorting bg-none"></th>
                                      </Fragment>
                                    )}
                                    <th width="12%">Status</th>
                                    <th width="12%">Comment</th>
                                    <th width="12%">Inquiry Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {isLoading ? (
                                    <tr style={{ textAlign: "center" }}>
                                      <td colSpan="8">
                                        <TableLoader />
                                      </td>
                                    </tr>
                                  ) : null}
                                  {!isLoading && data.length === 0 && (
                                    <tr>
                                      <td colSpan="8">
                                        <NotFoundLable message="No Users found" />
                                      </td>
                                    </tr>
                                  )}
                                  {InquiryListData &&
                                    isArray(InquiryListData) &&
                                    InquiryListData.map((user) => {
                                      return (
                                        <tr
                                          key={user.id}
                                          role="row"
                                          className="odd"
                                        >
                                          <td className="text-nowrap">
                                            <p>
                                              <span>{user.refId}</span>
                                            </p>
                                          </td>
                                          <td>
                                            <div className="concierge-bx d-flex align-items-center">
                                              <div>
                                                <h5 className="fs-16 mb-0 text-nowrap">
                                                  <Link
                                                    to={"#"}
                                                    className="text-black"
                                                  >
                                                    {user.fullName}
                                                  </Link>
                                                </h5>
                                                {/* <span className="text-primary fs-14">
                                      #EMP-00025
                                    </span> */}
                                              </div>
                                            </div>
                                          </td>
                                          <td className="text-nowrap">
                                            <span>{user.email}</span>
                                            <p>
                                              <span>{user.mobileNumber}</span>
                                            </p>
                                          </td>
                                          {(state.tripType ===
                                            FLIGHT_HOTEL_INQ_TYPE ||
                                            state.tripType ===
                                              FLIGHT_INQ_TYPE) && (
                                            <Fragment>
                                              <td>
                                                <div>
                                                  <span>
                                                    {moment(
                                                      user.departureDate
                                                    ).format(DATE_FORMAT)}
                                                    {user.returnDate &&
                                                      ` To ${moment(
                                                        user.returnDate
                                                      ).format(DATE_FORMAT)}`}
                                                  </span>
                                                </div>
                                              </td>
                                              <td>
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    fontWeight: 500,
                                                  }}
                                                  className="text-capitalize font-10"
                                                >
                                                  {`${user.from} To ${user.to}`}
                                                </span>
                                                <p>
                                                  <div>
                                                    <div className="card-content">
                                                      <address>
                                                        <div>
                                                          <a
                                                            className={`btn btn-${
                                                              user.travelClass ===
                                                              "Economy"
                                                                ? "primary"
                                                                : "secondary"
                                                            } light btn-xs mb-1 me-1`}
                                                          >
                                                            {user.travelClass}
                                                          </a>
                                                          {user.tripType ===
                                                          "multi_city" ? (
                                                            <Popover
                                                              content={() => {
                                                                return (
                                                                  <PerfectScrollbar className="widget-timeline dlab-scroll style-1 ps p-3 height370">
                                                                    <ul className="timeline">
                                                                      {user.multiCity &&
                                                                        user.multiCity.map(
                                                                          (
                                                                            d
                                                                          ) => {
                                                                            return (
                                                                              <li>
                                                                                <div className="timeline-badge info" />
                                                                                <Link
                                                                                  className="timeline-panel c-pointer text-muted"
                                                                                  to="#"
                                                                                >
                                                                                  <span>
                                                                                    {moment(
                                                                                      d.depatureDate
                                                                                    ).format(
                                                                                      DATE_FORMAT
                                                                                    )}
                                                                                  </span>
                                                                                  <h6 className="mb-0">
                                                                                    {`${d.from} To ${d.to}`}
                                                                                  </h6>
                                                                                </Link>
                                                                              </li>
                                                                            );
                                                                          }
                                                                        )}
                                                                    </ul>
                                                                    <div
                                                                      className="ps__rail-x"
                                                                      style={{
                                                                        left: 0,
                                                                        bottom: 0,
                                                                      }}
                                                                    >
                                                                      <div
                                                                        className="ps__thumb-x"
                                                                        tabIndex={
                                                                          0
                                                                        }
                                                                        style={{
                                                                          left: 0,
                                                                          width: 0,
                                                                        }}
                                                                      />
                                                                    </div>
                                                                    <div
                                                                      className="ps__rail-y"
                                                                      style={{
                                                                        top: 0,
                                                                        right: 0,
                                                                      }}
                                                                    >
                                                                      <div
                                                                        className="ps__thumb-y"
                                                                        tabIndex={
                                                                          0
                                                                        }
                                                                        style={{
                                                                          top: 0,
                                                                          height: 0,
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </PerfectScrollbar>
                                                                );
                                                              }}
                                                            >
                                                              <a
                                                                className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                                              >
                                                                {user.tripType
                                                                  ? user.tripType.replaceAll(
                                                                      "_",
                                                                      " "
                                                                    )
                                                                  : ""}
                                                              </a>
                                                            </Popover>
                                                          ) : (
                                                            <a
                                                              className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                                            >
                                                              {user.tripType
                                                                ? user.tripType.replaceAll(
                                                                    "_",
                                                                    " "
                                                                  )
                                                                : ""}
                                                            </a>
                                                          )}
                                                          <Popover
                                                            placement="bottom"
                                                            content={() => {
                                                              return (
                                                                <div className="card-content">
                                                                  {user.noOfTravellerType &&
                                                                    user.noOfTravellerType.map(
                                                                      (d) => {
                                                                        return (
                                                                          <address>
                                                                            <strong className="text-capitalize">
                                                                              {
                                                                                d.type
                                                                              }
                                                                            </strong>
                                                                            <br />
                                                                            <a>
                                                                              Member
                                                                              :{" "}
                                                                              {
                                                                                d.members
                                                                              }
                                                                            </a>
                                                                          </address>
                                                                        );
                                                                      }
                                                                    )}
                                                                </div>
                                                              );
                                                            }}
                                                          >
                                                            <a
                                                              className={`btn btn-warning light btn-xs mb-1 me-1 text-capitalize`}
                                                            >
                                                              Travelers
                                                            </a>
                                                          </Popover>
                                                        </div>
                                                        {user.isDateFixed && (
                                                          <div>
                                                            <a
                                                              className={`btn btn-success light btn-xs mb-1 me-1`}
                                                            >
                                                              Date Is Fixed
                                                            </a>
                                                          </div>
                                                        )}
                                                        {user.isDateFlexible && (
                                                          <div>
                                                            <a
                                                              className={`btn btn-dark light btn-xs mb-1 me-1`}
                                                            >
                                                              Date Is Flexible
                                                            </a>
                                                          </div>
                                                        )}
                                                      </address>
                                                    </div>
                                                  </div>
                                                </p>
                                              </td>
                                              {state.tripType ===
                                                FLIGHT_HOTEL_INQ_TYPE && (
                                                <td className="sorting">
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 13,
                                                        fontWeight: 500,
                                                      }}
                                                      className="text-capitalize font-10"
                                                    >
                                                      {`No Of Rooms : ${user.noOfRooms}`}
                                                    </span>
                                                    {user.roomRating && (
                                                      <span
                                                        style={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                        }}
                                                        className="text-capitalize font-10"
                                                      >
                                                        {`Room Ratings : ${user.roomRating} Star`}
                                                      </span>
                                                    )}
                                                  </div>
                                                </td>
                                              )}
                                              <td></td>
                                            </Fragment>
                                          )}

                                          {state.tripType ===
                                            EVISA_INQ_TYPE && (
                                            <Fragment>
                                              <td>
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    fontWeight: 500,
                                                  }}
                                                  className="text-capitalize font-10"
                                                >
                                                  {user.us_ca_passports
                                                    ? user.us_ca_passports
                                                        .length
                                                    : "-"}
                                                </span>
                                              </td>
                                              <td>
                                                <span
                                                  style={{
                                                    fontSize: 13,
                                                    fontWeight: 500,
                                                  }}
                                                  className="text-capitalize font-10"
                                                >
                                                  {user.other_passports
                                                    ? user.other_passports
                                                        .length
                                                    : "-"}
                                                </span>
                                              </td>
                                              <td>
                                                {" "}
                                                <Link
                                                  to={`/evisa-detail`}
                                                  onClick={() =>
                                                    saveDetail(user)
                                                  }
                                                  className="btn btn-secondary "
                                                >
                                                  Details
                                                </Link>{" "}
                                              </td>
                                            </Fragment>
                                          )}
                                          <td>
                                            {updatingStatusID !== user?._id && (
                                              <select
                                                onChange={(e) =>
                                                  handleStatusChange(e, user)
                                                }
                                                className="form-control form-control-sm"
                                                id="inputState"
                                                defaultValue={user?.status}
                                                // value={status}
                                              >
                                                <option value="pending">
                                                  Pending
                                                </option>
                                                <option value="received">
                                                  Received
                                                </option>
                                                <option value="in_progress">
                                                  In-Progress
                                                </option>
                                                <option value="cancelled">
                                                  Cancelled
                                                </option>
                                                <option value="completed">
                                                  Completed
                                                </option>
                                              </select>
                                            )}
                                            {updatingStatusID === user?._id &&
                                              updatingStatus && (
                                                <ContentLoader />
                                              )}
                                          </td>
                                          <td>
                                            {selectedComment?._id !==
                                              user._id && (
                                              <>
                                                <p>{user.comment}</p>
                                                <i
                                                  onClick={() =>
                                                    handleCommitEdit(user)
                                                  }
                                                  className="fa fa-edit"
                                                ></i>
                                              </>
                                            )}
                                            {isCommentEditable &&
                                              selectedComment?._id ===
                                                user._id && (
                                                <Fragment>
                                                  <textarea
                                                    className="form-control form-control-sm"
                                                    placeholder="Enter additional details"
                                                    defaultValue={user.comment}
                                                    onChange={(e) =>
                                                      handleCommentChange(
                                                        e,
                                                        user
                                                      )
                                                    }
                                                  />
                                                  <br />
                                                  <button
                                                    className="btn-sm btn-secondary"
                                                    onClick={
                                                      handldSubmitCommentChange
                                                    }
                                                  >
                                                    {selectedComment?._id ===
                                                      user._id &&
                                                    updatingComment
                                                      ? "Editing..."
                                                      : "Submit"}
                                                  </button>
                                                </Fragment>
                                              )}
                                          </td>
                                          <td>
                                            {moment(user?.createdAt).format(
                                              "DD-MM-YYYY HH:mm A"
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>

                              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                <div className="dataTables_info"></div>
                                <div
                                  className="dataTables_paginate paging_simple_numbers mb-0"
                                  id="example2_paginate"
                                >
                                  <Pagination
                                    pagination={pagination}
                                    data={data}
                                    limit={limit}
                                    onPageChange={onPageChange}
                                    handleLimitChange={handleLimitChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane id="my-posts" eventKey={EVISA_INQ_TYPE}>
                  <FlightinquiryTable
                    state={state}
                    updatingStatusID={updatingStatusID}
                    saveDetail={saveDetail}
                    handleStatusChange={handleStatusChange}
                    handleCommitEdit={handleCommitEdit}
                    isCommentEditable={isCommentEditable}
                    selectedComment={selectedComment}
                    handleCommentChange={handleCommentChange}
                    updatingStatus={updatingStatus}
                    handldSubmitCommentChange={handldSubmitCommentChange}
                    updatingComment={updatingComment}
                    InquiryListData={InquiryListData}
                    isLoading={isLoading}
                    permissions={permissions}
                    onRequestInquiries={onRequestInquiries}
                    u_data={user}
                    allUsers={allUsers}
                  />
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info"></div>
                    <div
                      className="dataTables_paginate paging_simple_numbers mb-0"
                      id="example2_paginate"
                    >
                      <Pagination
                        pagination={pagination}
                        data={data}
                        limit={limit}
                        onPageChange={onPageChange}
                        handleLimitChange={handleLimitChange}
                      />
                    </div>
                  </div>
                  {/* //! Display None For Non Collapisable Table */}
                  <div
                    style={{ display: "none" }}
                    className="my-post-content pt-3"
                  >
                    <div className="my-post-content pt-3">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body p-0">
                            <div className="table-responsive">
                              <div
                                id="example2_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table
                                  id="example2"
                                  className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                                >
                                  <thead>
                                    <tr role="row">
                                      <th className="sorting_asc">Ref.ID</th>
                                      <th className="sorting_asc">Name</th>
                                      <th className="sorting">Contact</th>
                                      {state.tripType === EVISA_INQ_TYPE && (
                                        <Fragment>
                                          <th className="sorting">
                                            No Of US/Canada Passport
                                          </th>
                                          <th className="sorting">
                                            No Of Other Passport
                                          </th>

                                          <th className="sorting"></th>
                                        </Fragment>
                                      )}

                                      {(state.tripType ===
                                        FLIGHT_HOTEL_INQ_TYPE ||
                                        state.tripType === FLIGHT_INQ_TYPE) && (
                                        <Fragment>
                                          <th className="sorting">
                                            Travel Dates
                                          </th>
                                          <th className="sorting">
                                            Trip Details
                                          </th>
                                          {state.tripType ===
                                            FLIGHT_HOTEL_INQ_TYPE && (
                                            <th className="sorting">
                                              Room Details
                                            </th>
                                          )}
                                          <th className="sorting bg-none"></th>
                                        </Fragment>
                                      )}
                                      <th width="12%">Status</th>
                                      <th width="12%">Comment</th>
                                      <th width="12%">Inquiry Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isLoading ? (
                                      <tr style={{ textAlign: "center" }}>
                                        <td colSpan="8">
                                          <TableLoader />
                                        </td>
                                      </tr>
                                    ) : null}
                                    {!isLoading && data.length === 0 && (
                                      <tr>
                                        <td colSpan="8">
                                          <NotFoundLable message="No Users found" />
                                        </td>
                                      </tr>
                                    )}
                                    {InquiryListData &&
                                      isArray(InquiryListData) &&
                                      InquiryListData.map((user) => {
                                        return (
                                          <tr
                                            key={user.id}
                                            role="row"
                                            className="odd"
                                          >
                                            <td className="text-nowrap">
                                              <p>
                                                <span>{user.refId}</span>
                                              </p>
                                            </td>
                                            <td>
                                              <div className="concierge-bx d-flex align-items-center">
                                                <div>
                                                  <h5 className="fs-16 mb-0 text-nowrap">
                                                    <Link
                                                      to={"#"}
                                                      className="text-black"
                                                    >
                                                      {user.fullName}
                                                    </Link>
                                                  </h5>
                                                  {/* <span className="text-primary fs-14">
                                      #EMP-00025
                                    </span> */}
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-nowrap">
                                              <span>{user.email}</span>
                                              <p>
                                                <span>{user.mobileNumber}</span>
                                              </p>
                                            </td>
                                            {(state.tripType ===
                                              FLIGHT_HOTEL_INQ_TYPE ||
                                              state.tripType ===
                                                FLIGHT_INQ_TYPE) && (
                                              <Fragment>
                                                <td>
                                                  <div>
                                                    <span>
                                                      {moment(
                                                        user.departureDate
                                                      ).format(DATE_FORMAT)}
                                                      {user.returnDate &&
                                                        ` To ${moment(
                                                          user.returnDate
                                                        ).format(DATE_FORMAT)}`}
                                                    </span>
                                                  </div>
                                                </td>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {`${user.from} To ${user.to}`}
                                                  </span>
                                                  <p>
                                                    <div>
                                                      <div className="card-content">
                                                        <address>
                                                          <div>
                                                            <a
                                                              className={`btn btn-${
                                                                user.travelClass ===
                                                                "Economy"
                                                                  ? "primary"
                                                                  : "secondary"
                                                              } light btn-xs mb-1 me-1`}
                                                            >
                                                              {user.travelClass}
                                                            </a>
                                                            {user.tripType ===
                                                            "multi_city" ? (
                                                              <Popover
                                                                content={() => {
                                                                  return (
                                                                    <PerfectScrollbar className="widget-timeline dlab-scroll style-1 ps p-3 height370">
                                                                      <ul className="timeline">
                                                                        {user.multiCity &&
                                                                          user.multiCity.map(
                                                                            (
                                                                              d
                                                                            ) => {
                                                                              return (
                                                                                <li>
                                                                                  <div className="timeline-badge info" />
                                                                                  <Link
                                                                                    className="timeline-panel c-pointer text-muted"
                                                                                    to="#"
                                                                                  >
                                                                                    <span>
                                                                                      {moment(
                                                                                        d.depatureDate
                                                                                      ).format(
                                                                                        DATE_FORMAT
                                                                                      )}
                                                                                    </span>
                                                                                    <h6 className="mb-0">
                                                                                      {`${d.from} To ${d.to}`}
                                                                                    </h6>
                                                                                  </Link>
                                                                                </li>
                                                                              );
                                                                            }
                                                                          )}
                                                                      </ul>
                                                                      <div
                                                                        className="ps__rail-x"
                                                                        style={{
                                                                          left: 0,
                                                                          bottom: 0,
                                                                        }}
                                                                      >
                                                                        <div
                                                                          className="ps__thumb-x"
                                                                          tabIndex={
                                                                            0
                                                                          }
                                                                          style={{
                                                                            left: 0,
                                                                            width: 0,
                                                                          }}
                                                                        />
                                                                      </div>
                                                                      <div
                                                                        className="ps__rail-y"
                                                                        style={{
                                                                          top: 0,
                                                                          right: 0,
                                                                        }}
                                                                      >
                                                                        <div
                                                                          className="ps__thumb-y"
                                                                          tabIndex={
                                                                            0
                                                                          }
                                                                          style={{
                                                                            top: 0,
                                                                            height: 0,
                                                                          }}
                                                                        />
                                                                      </div>
                                                                    </PerfectScrollbar>
                                                                  );
                                                                }}
                                                              >
                                                                <a
                                                                  className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                                                >
                                                                  {user.tripType
                                                                    ? user.tripType.replaceAll(
                                                                        "_",
                                                                        " "
                                                                      )
                                                                    : ""}
                                                                </a>
                                                              </Popover>
                                                            ) : (
                                                              <a
                                                                className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                                              >
                                                                {user.tripType
                                                                  ? user.tripType.replaceAll(
                                                                      "_",
                                                                      " "
                                                                    )
                                                                  : ""}
                                                              </a>
                                                            )}
                                                            <Popover
                                                              placement="bottom"
                                                              content={() => {
                                                                return (
                                                                  <div className="card-content">
                                                                    {user.noOfTravellerType &&
                                                                      user.noOfTravellerType.map(
                                                                        (d) => {
                                                                          return (
                                                                            <address>
                                                                              <strong className="text-capitalize">
                                                                                {
                                                                                  d.type
                                                                                }
                                                                              </strong>
                                                                              <br />
                                                                              <a>
                                                                                Member
                                                                                :{" "}
                                                                                {
                                                                                  d.members
                                                                                }
                                                                              </a>
                                                                            </address>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </div>
                                                                );
                                                              }}
                                                            >
                                                              <a
                                                                className={`btn btn-warning light btn-xs mb-1 me-1 text-capitalize`}
                                                              >
                                                                Travelers
                                                              </a>
                                                            </Popover>
                                                          </div>
                                                          {user.isDateFixed && (
                                                            <div>
                                                              <a
                                                                className={`btn btn-success light btn-xs mb-1 me-1`}
                                                              >
                                                                Date Is Fixed
                                                              </a>
                                                            </div>
                                                          )}
                                                          {user.isDateFlexible && (
                                                            <div>
                                                              <a
                                                                className={`btn btn-dark light btn-xs mb-1 me-1`}
                                                              >
                                                                Date Is Flexible
                                                              </a>
                                                            </div>
                                                          )}
                                                        </address>
                                                      </div>
                                                    </div>
                                                  </p>
                                                </td>
                                                {state.tripType ===
                                                  FLIGHT_HOTEL_INQ_TYPE && (
                                                  <td className="sorting">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                        }}
                                                        className="text-capitalize font-10"
                                                      >
                                                        {`No Of Rooms : ${user.noOfRooms}`}
                                                      </span>
                                                      <span
                                                        style={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                        }}
                                                        className="text-capitalize font-10"
                                                      >
                                                        {`Room Ratings : ${user.roomRating} Star`}
                                                      </span>
                                                    </div>
                                                  </td>
                                                )}
                                                <td></td>
                                              </Fragment>
                                            )}

                                            {state.tripType ===
                                              EVISA_INQ_TYPE && (
                                              <Fragment>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {user.us_ca_passports
                                                      ? user.us_ca_passports
                                                          .length
                                                      : "-"}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {user.other_passports
                                                      ? user.other_passports
                                                          .length
                                                      : "-"}
                                                  </span>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <Link
                                                    to={`/evisa-detail`}
                                                    onClick={() =>
                                                      saveDetail(user)
                                                    }
                                                    className="btn btn-secondary "
                                                  >
                                                    Details
                                                  </Link>{" "}
                                                </td>
                                              </Fragment>
                                            )}
                                            <td>
                                              {updatingStatusID !==
                                                user?._id && (
                                                <select
                                                  onChange={(e) =>
                                                    handleStatusChange(e, user)
                                                  }
                                                  className="form-control form-control-sm"
                                                  id="inputState"
                                                  defaultValue={user?.status}
                                                  // value={status}
                                                >
                                                  <option value="pending">
                                                    Pending
                                                  </option>
                                                  <option value="received">
                                                    Received
                                                  </option>
                                                  <option value="in_progress">
                                                    In-Progress
                                                  </option>
                                                  <option value="cancelled">
                                                    Cancelled
                                                  </option>
                                                  <option value="completed">
                                                    Completed
                                                  </option>
                                                </select>
                                              )}
                                              {updatingStatusID === user?._id &&
                                                updatingStatus && (
                                                  <ContentLoader />
                                                )}
                                            </td>
                                            <td>
                                              {selectedComment?._id !==
                                                user._id && (
                                                <>
                                                  <p>{user.comment}</p>
                                                  <i
                                                    onClick={() =>
                                                      handleCommitEdit(user)
                                                    }
                                                    className="fa fa-edit"
                                                  ></i>
                                                </>
                                              )}
                                              {isCommentEditable &&
                                                selectedComment?._id ===
                                                  user._id && (
                                                  <Fragment>
                                                    <textarea
                                                      className="form-control form-control-sm"
                                                      placeholder="Enter additional details"
                                                      defaultValue={
                                                        user.comment
                                                      }
                                                      onChange={(e) =>
                                                        handleCommentChange(
                                                          e,
                                                          user
                                                        )
                                                      }
                                                    />
                                                    <br />
                                                    <button
                                                      className="btn-sm btn-secondary"
                                                      onClick={
                                                        handldSubmitCommentChange
                                                      }
                                                    >
                                                      {selectedComment?._id ===
                                                        user._id &&
                                                      updatingComment
                                                        ? "Editing..."
                                                        : "Submit"}
                                                    </button>
                                                  </Fragment>
                                                )}
                                            </td>
                                            <td>
                                              {moment(user?.createdAt).format(
                                                "DD-MM-YYYY HH:mm A"
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>

                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div className="dataTables_info"></div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example2_paginate"
                                  >
                                    <Pagination
                                      pagination={pagination}
                                      data={data}
                                      limit={limit}
                                      onPageChange={onPageChange}
                                      handleLimitChange={handleLimitChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane id="my-posts" eventKey={"HajjInquiry"}>
                  <FlightinquiryTable
                    state={state}
                    updatingStatusID={updatingStatusID}
                    saveDetail={saveDetail}
                    handleStatusChange={handleStatusChange}
                    handleCommitEdit={handleCommitEdit}
                    isCommentEditable={isCommentEditable}
                    selectedComment={selectedComment}
                    handleCommentChange={handleCommentChange}
                    updatingStatus={updatingStatus}
                    handldSubmitCommentChange={handldSubmitCommentChange}
                    updatingComment={updatingComment}
                    InquiryListData={InquiryListData}
                    isLoading={isLoading}
                    permissions={permissions}
                    onRequestInquiries={onRequestInquiries}
                    u_data={user}
                    allUsers={allUsers}
                  />
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info"></div>
                    <div
                      className="dataTables_paginate paging_simple_numbers mb-0"
                      id="example2_paginate"
                    >
                      <Pagination
                        pagination={pagination}
                        data={data}
                        limit={limit}
                        onPageChange={onPageChange}
                        handleLimitChange={handleLimitChange}
                      />
                    </div>
                  </div>
                  {/* //! Display None For Non Collapisable Table */}
                  <div
                    style={{ display: "none" }}
                    className="my-post-content pt-3"
                  >
                    <div className="my-post-content pt-3">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body p-0">
                            <div className="table-responsive">
                              <div
                                id="example2_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table
                                  id="example2"
                                  className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                                >
                                  <thead>
                                    <tr role="row">
                                      <th className="sorting_asc">Ref.ID</th>
                                      <th className="sorting_asc">Name</th>
                                      <th className="sorting">Contact</th>
                                      {state.tripType === "HajjInquiry" && (
                                        <th className="sorting">
                                          Total Travelers
                                        </th>
                                      )}
                                      {state.tripType === EVISA_INQ_TYPE && (
                                        <Fragment>
                                          <th className="sorting">
                                            No Of US/Canada Passport
                                          </th>
                                          <th className="sorting">
                                            No Of Other Passport
                                          </th>

                                          <th className="sorting"></th>
                                        </Fragment>
                                      )}

                                      {(state.tripType ===
                                        FLIGHT_HOTEL_INQ_TYPE ||
                                        state.tripType === FLIGHT_INQ_TYPE) && (
                                        <Fragment>
                                          <th className="sorting">
                                            Travel Dates
                                          </th>
                                          <th className="sorting">
                                            Trip Details
                                          </th>
                                          {state.tripType ===
                                            FLIGHT_HOTEL_INQ_TYPE && (
                                            <th className="sorting">
                                              Room Details
                                            </th>
                                          )}
                                          <th className="sorting bg-none"></th>
                                        </Fragment>
                                      )}

                                      <th width="12%">Status</th>
                                      <th width="12%">Comment</th>
                                      <th width="12%">Inquiry Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isLoading ? (
                                      <tr style={{ textAlign: "center" }}>
                                        <td colSpan="8">
                                          <TableLoader />
                                        </td>
                                      </tr>
                                    ) : null}
                                    {!isLoading && data.length === 0 && (
                                      <tr>
                                        <td colSpan="8">
                                          <NotFoundLable message="No Users found" />
                                        </td>
                                      </tr>
                                    )}
                                    {InquiryListData &&
                                      isArray(InquiryListData) &&
                                      InquiryListData.map((user) => {
                                        return (
                                          <tr
                                            key={user.id}
                                            role="row"
                                            className="odd"
                                          >
                                            <td className="text-nowrap">
                                              <p>
                                                <span>{user.refId}</span>
                                              </p>
                                            </td>
                                            <td>
                                              <div className="concierge-bx d-flex align-items-center">
                                                <div>
                                                  <h5 className="fs-16 mb-0 text-nowrap">
                                                    <Link
                                                      to={"#"}
                                                      className="text-black"
                                                    >
                                                      {user.fullName}
                                                    </Link>
                                                  </h5>
                                                  {/* <span className="text-primary fs-14">
                                      #EMP-00025
                                    </span> */}
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-nowrap">
                                              <span>{user.email}</span>
                                              <p>
                                                <span>{user.mobileNumber}</span>
                                              </p>
                                            </td>
                                            <td className="text-nowrap">
                                              <span>
                                                {user.totalTravellers}
                                              </span>
                                            </td>
                                            {(state.tripType ===
                                              FLIGHT_HOTEL_INQ_TYPE ||
                                              state.tripType ===
                                                FLIGHT_INQ_TYPE) && (
                                              <Fragment>
                                                <td>
                                                  <div>
                                                    <span>
                                                      {moment(
                                                        user.departureDate
                                                      ).format(DATE_FORMAT)}
                                                      {user.returnDate &&
                                                        ` To ${moment(
                                                          user.returnDate
                                                        ).format(DATE_FORMAT)}`}
                                                    </span>
                                                  </div>
                                                </td>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {`${user.from} To ${user.to}`}
                                                  </span>
                                                  <p>
                                                    <div>
                                                      <div className="card-content">
                                                        <address>
                                                          <div>
                                                            <a
                                                              className={`btn btn-${
                                                                user.travelClass ===
                                                                "Economy"
                                                                  ? "primary"
                                                                  : "secondary"
                                                              } light btn-xs mb-1 me-1`}
                                                            >
                                                              {user.travelClass}
                                                            </a>
                                                            {user.tripType ===
                                                            "multi_city" ? (
                                                              <Popover
                                                                content={() => {
                                                                  return (
                                                                    <PerfectScrollbar className="widget-timeline dlab-scroll style-1 ps p-3 height370">
                                                                      <ul className="timeline">
                                                                        {user.multiCity &&
                                                                          user.multiCity.map(
                                                                            (
                                                                              d
                                                                            ) => {
                                                                              return (
                                                                                <li>
                                                                                  <div className="timeline-badge info" />
                                                                                  <Link
                                                                                    className="timeline-panel c-pointer text-muted"
                                                                                    to="#"
                                                                                  >
                                                                                    <span>
                                                                                      {moment(
                                                                                        d.depatureDate
                                                                                      ).format(
                                                                                        DATE_FORMAT
                                                                                      )}
                                                                                    </span>
                                                                                    <h6 className="mb-0">
                                                                                      {`${d.from} To ${d.to}`}
                                                                                    </h6>
                                                                                  </Link>
                                                                                </li>
                                                                              );
                                                                            }
                                                                          )}
                                                                      </ul>
                                                                      <div
                                                                        className="ps__rail-x"
                                                                        style={{
                                                                          left: 0,
                                                                          bottom: 0,
                                                                        }}
                                                                      >
                                                                        <div
                                                                          className="ps__thumb-x"
                                                                          tabIndex={
                                                                            0
                                                                          }
                                                                          style={{
                                                                            left: 0,
                                                                            width: 0,
                                                                          }}
                                                                        />
                                                                      </div>
                                                                      <div
                                                                        className="ps__rail-y"
                                                                        style={{
                                                                          top: 0,
                                                                          right: 0,
                                                                        }}
                                                                      >
                                                                        <div
                                                                          className="ps__thumb-y"
                                                                          tabIndex={
                                                                            0
                                                                          }
                                                                          style={{
                                                                            top: 0,
                                                                            height: 0,
                                                                          }}
                                                                        />
                                                                      </div>
                                                                    </PerfectScrollbar>
                                                                  );
                                                                }}
                                                              >
                                                                <a
                                                                  className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                                                >
                                                                  {user.tripType
                                                                    ? user.tripType.replaceAll(
                                                                        "_",
                                                                        " "
                                                                      )
                                                                    : ""}
                                                                </a>
                                                              </Popover>
                                                            ) : (
                                                              <a
                                                                className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                                              >
                                                                {user.tripType
                                                                  ? user.tripType.replaceAll(
                                                                      "_",
                                                                      " "
                                                                    )
                                                                  : ""}
                                                              </a>
                                                            )}
                                                            <Popover
                                                              placement="bottom"
                                                              content={() => {
                                                                return (
                                                                  <div className="card-content">
                                                                    {user.noOfTravellerType &&
                                                                      user.noOfTravellerType.map(
                                                                        (d) => {
                                                                          return (
                                                                            <address>
                                                                              <strong className="text-capitalize">
                                                                                {
                                                                                  d.type
                                                                                }
                                                                              </strong>
                                                                              <br />
                                                                              <a>
                                                                                Member
                                                                                :{" "}
                                                                                {
                                                                                  d.members
                                                                                }
                                                                              </a>
                                                                            </address>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </div>
                                                                );
                                                              }}
                                                            >
                                                              <a
                                                                className={`btn btn-warning light btn-xs mb-1 me-1 text-capitalize`}
                                                              >
                                                                Travelers
                                                              </a>
                                                            </Popover>
                                                          </div>
                                                          {user.isDateFixed && (
                                                            <div>
                                                              <a
                                                                className={`btn btn-success light btn-xs mb-1 me-1`}
                                                              >
                                                                Date Is Fixed
                                                              </a>
                                                            </div>
                                                          )}
                                                          {user.isDateFlexible && (
                                                            <div>
                                                              <a
                                                                className={`btn btn-dark light btn-xs mb-1 me-1`}
                                                              >
                                                                Date Is Flexible
                                                              </a>
                                                            </div>
                                                          )}
                                                        </address>
                                                      </div>
                                                    </div>
                                                  </p>
                                                </td>
                                                {state.tripType ===
                                                  FLIGHT_HOTEL_INQ_TYPE && (
                                                  <td className="sorting">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                        }}
                                                        className="text-capitalize font-10"
                                                      >
                                                        {`No Of Rooms : ${user.noOfRooms}`}
                                                      </span>
                                                      <span
                                                        style={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                        }}
                                                        className="text-capitalize font-10"
                                                      >
                                                        {`Room Ratings : ${user.roomRating} Star`}
                                                      </span>
                                                    </div>
                                                  </td>
                                                )}
                                                <td></td>
                                              </Fragment>
                                            )}

                                            {state.tripType ===
                                              EVISA_INQ_TYPE && (
                                              <Fragment>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {user.us_ca_passports
                                                      ? user.us_ca_passports
                                                          .length
                                                      : "-"}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {user.other_passports
                                                      ? user.other_passports
                                                          .length
                                                      : "-"}
                                                  </span>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <Link
                                                    to={`/evisa-detail`}
                                                    onClick={() =>
                                                      saveDetail(user)
                                                    }
                                                    className="btn btn-secondary "
                                                  >
                                                    Details
                                                  </Link>{" "}
                                                </td>
                                              </Fragment>
                                            )}
                                            <td>
                                              {updatingStatusID !==
                                                user?._id && (
                                                <select
                                                  onChange={(e) =>
                                                    handleStatusChange(e, user)
                                                  }
                                                  className="form-control form-control-sm"
                                                  id="inputState"
                                                  defaultValue={user?.status}
                                                  // value={status}
                                                >
                                                  <option value="pending">
                                                    Pending
                                                  </option>
                                                  <option value="received">
                                                    Received
                                                  </option>
                                                  <option value="in_progress">
                                                    In-Progress
                                                  </option>
                                                  <option value="cancelled">
                                                    Cancelled
                                                  </option>
                                                  <option value="completed">
                                                    Completed
                                                  </option>
                                                </select>
                                              )}
                                              {updatingStatusID === user?._id &&
                                                updatingStatus && (
                                                  <ContentLoader />
                                                )}
                                            </td>
                                            <td>
                                              {selectedComment?._id !==
                                                user._id && (
                                                <>
                                                  <p>{user.comment}</p>
                                                  <i
                                                    onClick={() =>
                                                      handleCommitEdit(user)
                                                    }
                                                    className="fa fa-edit"
                                                  ></i>
                                                </>
                                              )}
                                              {isCommentEditable &&
                                                selectedComment?._id ===
                                                  user._id && (
                                                  <Fragment>
                                                    <textarea
                                                      className="form-control form-control-sm"
                                                      placeholder="Enter additional details"
                                                      defaultValue={
                                                        user.comment
                                                      }
                                                      onChange={(e) =>
                                                        handleCommentChange(
                                                          e,
                                                          user
                                                        )
                                                      }
                                                    />
                                                    <br />
                                                    <button
                                                      className="btn-sm btn-secondary"
                                                      onClick={
                                                        handldSubmitCommentChange
                                                      }
                                                    >
                                                      {selectedComment?._id ===
                                                        user._id &&
                                                      updatingComment
                                                        ? "Editing..."
                                                        : "Submit"}
                                                    </button>
                                                  </Fragment>
                                                )}
                                            </td>
                                            <td>
                                              {moment(user?.createdAt).format(
                                                "DD-MM-YYYY HH:mm A"
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>

                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div className="dataTables_info"></div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example2_paginate"
                                  >
                                    <Pagination
                                      pagination={pagination}
                                      data={data}
                                      limit={limit}
                                      onPageChange={onPageChange}
                                      handleLimitChange={handleLimitChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane id="my-posts" eventKey={"umrahInquiry"}>
                  <FlightinquiryTable
                    state={state}
                    updatingStatusID={updatingStatusID}
                    saveDetail={saveDetail}
                    handleStatusChange={handleStatusChange}
                    handleCommitEdit={handleCommitEdit}
                    isCommentEditable={isCommentEditable}
                    selectedComment={selectedComment}
                    handleCommentChange={handleCommentChange}
                    updatingStatus={updatingStatus}
                    handldSubmitCommentChange={handldSubmitCommentChange}
                    updatingComment={updatingComment}
                    InquiryListData={InquiryListData}
                    isLoading={isLoading}
                    permissions={permissions}
                    onRequestInquiries={onRequestInquiries}
                    u_data={user}
                    allUsers={allUsers}
                  />
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info"></div>
                    <div
                      className="dataTables_paginate paging_simple_numbers mb-0"
                      id="example2_paginate"
                    >
                      <Pagination
                        pagination={pagination}
                        data={data}
                        limit={limit}
                        onPageChange={onPageChange}
                        handleLimitChange={handleLimitChange}
                      />
                    </div>
                  </div>
                  {/* //! Display None For Non Collapisable Table */}
                  <div
                    style={{ display: "none" }}
                    className="my-post-content pt-3"
                  >
                    <div className="my-post-content pt-3">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body p-0">
                            <div className="table-responsive">
                              <div
                                id="example2_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table
                                  id="example2"
                                  className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                                >
                                  <thead>
                                    <tr role="row">
                                      <th className="sorting_asc">Ref.ID</th>
                                      <th className="sorting_asc">Name</th>
                                      <th className="sorting">Contact</th>
                                      {state.tripType === "HajjInquiry" && (
                                        <th className="sorting">
                                          Total Travelers
                                        </th>
                                      )}
                                      {state.tripType === EVISA_INQ_TYPE && (
                                        <Fragment>
                                          <th className="sorting">
                                            No Of US/Canada Passport
                                          </th>
                                          <th className="sorting">
                                            No Of Other Passport
                                          </th>

                                          <th className="sorting"></th>
                                        </Fragment>
                                      )}

                                      {(state.tripType ===
                                        FLIGHT_HOTEL_INQ_TYPE ||
                                        state.tripType === FLIGHT_INQ_TYPE) && (
                                        <Fragment>
                                          <th className="sorting">
                                            Travel Dates
                                          </th>
                                          <th className="sorting">
                                            Trip Details
                                          </th>
                                          {state.tripType ===
                                            FLIGHT_HOTEL_INQ_TYPE && (
                                            <th className="sorting">
                                              Room Details
                                            </th>
                                          )}
                                          <th className="sorting bg-none"></th>
                                        </Fragment>
                                      )}

                                      <th width="12%">Status</th>
                                      <th width="12%">Comment</th>
                                      <th width="12%">Inquiry Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isLoading ? (
                                      <tr style={{ textAlign: "center" }}>
                                        <td colSpan="8">
                                          <TableLoader />
                                        </td>
                                      </tr>
                                    ) : null}
                                    {!isLoading && data.length === 0 && (
                                      <tr>
                                        <td colSpan="8">
                                          <NotFoundLable message="No Users found" />
                                        </td>
                                      </tr>
                                    )}
                                    {InquiryListData &&
                                      isArray(InquiryListData) &&
                                      InquiryListData.map((user) => {
                                        return (
                                          <tr
                                            key={user.id}
                                            role="row"
                                            className="odd"
                                          >
                                            <td className="text-nowrap">
                                              <p>
                                                <span>{user.refId}</span>
                                              </p>
                                            </td>
                                            <td>
                                              <div className="concierge-bx d-flex align-items-center">
                                                <div>
                                                  <h5 className="fs-16 mb-0 text-nowrap">
                                                    <Link
                                                      to={"#"}
                                                      className="text-black"
                                                    >
                                                      {user.fullName}
                                                    </Link>
                                                  </h5>
                                                  {/* <span className="text-primary fs-14">
                                      #EMP-00025
                                    </span> */}
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-nowrap">
                                              <span>{user.email}</span>
                                              <p>
                                                <span>{user.mobileNumber}</span>
                                              </p>
                                            </td>
                                            <td className="text-nowrap">
                                              <span>
                                                {user.totalTravellers}
                                              </span>
                                            </td>
                                            {(state.tripType ===
                                              FLIGHT_HOTEL_INQ_TYPE ||
                                              state.tripType ===
                                                FLIGHT_INQ_TYPE) && (
                                              <Fragment>
                                                <td>
                                                  <div>
                                                    <span>
                                                      {moment(
                                                        user.departureDate
                                                      ).format(DATE_FORMAT)}
                                                      {user.returnDate &&
                                                        ` To ${moment(
                                                          user.returnDate
                                                        ).format(DATE_FORMAT)}`}
                                                    </span>
                                                  </div>
                                                </td>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {`${user.from} To ${user.to}`}
                                                  </span>
                                                  <p>
                                                    <div>
                                                      <div className="card-content">
                                                        <address>
                                                          <div>
                                                            <a
                                                              className={`btn btn-${
                                                                user.travelClass ===
                                                                "Economy"
                                                                  ? "primary"
                                                                  : "secondary"
                                                              } light btn-xs mb-1 me-1`}
                                                            >
                                                              {user.travelClass}
                                                            </a>
                                                            {user.tripType ===
                                                            "multi_city" ? (
                                                              <Popover
                                                                content={() => {
                                                                  return (
                                                                    <PerfectScrollbar className="widget-timeline dlab-scroll style-1 ps p-3 height370">
                                                                      <ul className="timeline">
                                                                        {user.multiCity &&
                                                                          user.multiCity.map(
                                                                            (
                                                                              d
                                                                            ) => {
                                                                              return (
                                                                                <li>
                                                                                  <div className="timeline-badge info" />
                                                                                  <Link
                                                                                    className="timeline-panel c-pointer text-muted"
                                                                                    to="#"
                                                                                  >
                                                                                    <span>
                                                                                      {moment(
                                                                                        d.depatureDate
                                                                                      ).format(
                                                                                        DATE_FORMAT
                                                                                      )}
                                                                                    </span>
                                                                                    <h6 className="mb-0">
                                                                                      {`${d.from} To ${d.to}`}
                                                                                    </h6>
                                                                                  </Link>
                                                                                </li>
                                                                              );
                                                                            }
                                                                          )}
                                                                      </ul>
                                                                      <div
                                                                        className="ps__rail-x"
                                                                        style={{
                                                                          left: 0,
                                                                          bottom: 0,
                                                                        }}
                                                                      >
                                                                        <div
                                                                          className="ps__thumb-x"
                                                                          tabIndex={
                                                                            0
                                                                          }
                                                                          style={{
                                                                            left: 0,
                                                                            width: 0,
                                                                          }}
                                                                        />
                                                                      </div>
                                                                      <div
                                                                        className="ps__rail-y"
                                                                        style={{
                                                                          top: 0,
                                                                          right: 0,
                                                                        }}
                                                                      >
                                                                        <div
                                                                          className="ps__thumb-y"
                                                                          tabIndex={
                                                                            0
                                                                          }
                                                                          style={{
                                                                            top: 0,
                                                                            height: 0,
                                                                          }}
                                                                        />
                                                                      </div>
                                                                    </PerfectScrollbar>
                                                                  );
                                                                }}
                                                              >
                                                                <a
                                                                  className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                                                >
                                                                  {user.tripType
                                                                    ? user.tripType.replaceAll(
                                                                        "_",
                                                                        " "
                                                                      )
                                                                    : ""}
                                                                </a>
                                                              </Popover>
                                                            ) : (
                                                              <a
                                                                className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                                              >
                                                                {user.tripType
                                                                  ? user.tripType.replaceAll(
                                                                      "_",
                                                                      " "
                                                                    )
                                                                  : ""}
                                                              </a>
                                                            )}
                                                            <Popover
                                                              placement="bottom"
                                                              content={() => {
                                                                return (
                                                                  <div className="card-content">
                                                                    {user.noOfTravellerType &&
                                                                      user.noOfTravellerType.map(
                                                                        (d) => {
                                                                          return (
                                                                            <address>
                                                                              <strong className="text-capitalize">
                                                                                {
                                                                                  d.type
                                                                                }
                                                                              </strong>
                                                                              <br />
                                                                              <a>
                                                                                Member
                                                                                :{" "}
                                                                                {
                                                                                  d.members
                                                                                }
                                                                              </a>
                                                                            </address>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </div>
                                                                );
                                                              }}
                                                            >
                                                              <a
                                                                className={`btn btn-warning light btn-xs mb-1 me-1 text-capitalize`}
                                                              >
                                                                Travelers
                                                              </a>
                                                            </Popover>
                                                          </div>
                                                          {user.isDateFixed && (
                                                            <div>
                                                              <a
                                                                className={`btn btn-success light btn-xs mb-1 me-1`}
                                                              >
                                                                Date Is Fixed
                                                              </a>
                                                            </div>
                                                          )}
                                                          {user.isDateFlexible && (
                                                            <div>
                                                              <a
                                                                className={`btn btn-dark light btn-xs mb-1 me-1`}
                                                              >
                                                                Date Is Flexible
                                                              </a>
                                                            </div>
                                                          )}
                                                        </address>
                                                      </div>
                                                    </div>
                                                  </p>
                                                </td>
                                                {state.tripType ===
                                                  FLIGHT_HOTEL_INQ_TYPE && (
                                                  <td className="sorting">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                        }}
                                                        className="text-capitalize font-10"
                                                      >
                                                        {`No Of Rooms : ${user.noOfRooms}`}
                                                      </span>
                                                      <span
                                                        style={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                        }}
                                                        className="text-capitalize font-10"
                                                      >
                                                        {`Room Ratings : ${user.roomRating} Star`}
                                                      </span>
                                                    </div>
                                                  </td>
                                                )}
                                                <td></td>
                                              </Fragment>
                                            )}

                                            {state.tripType ===
                                              EVISA_INQ_TYPE && (
                                              <Fragment>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {user.us_ca_passports
                                                      ? user.us_ca_passports
                                                          .length
                                                      : "-"}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {user.other_passports
                                                      ? user.other_passports
                                                          .length
                                                      : "-"}
                                                  </span>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <Link
                                                    to={`/evisa-detail`}
                                                    onClick={() =>
                                                      saveDetail(user)
                                                    }
                                                    className="btn btn-secondary "
                                                  >
                                                    Details
                                                  </Link>{" "}
                                                </td>
                                              </Fragment>
                                            )}
                                            <td>
                                              {updatingStatusID !==
                                                user?._id && (
                                                <select
                                                  onChange={(e) =>
                                                    handleStatusChange(e, user)
                                                  }
                                                  className="form-control form-control-sm"
                                                  id="inputState"
                                                  defaultValue={user?.status}
                                                  // value={status}
                                                >
                                                  <option value="pending">
                                                    Pending
                                                  </option>
                                                  <option value="received">
                                                    Received
                                                  </option>
                                                  <option value="in_progress">
                                                    In-Progress
                                                  </option>
                                                  <option value="cancelled">
                                                    Cancelled
                                                  </option>
                                                  <option value="completed">
                                                    Completed
                                                  </option>
                                                </select>
                                              )}
                                              {updatingStatusID === user?._id &&
                                                updatingStatus && (
                                                  <ContentLoader />
                                                )}
                                            </td>
                                            <td>
                                              {selectedComment?._id !==
                                                user._id && (
                                                <>
                                                  <p>{user.comment}</p>
                                                  <i
                                                    onClick={() =>
                                                      handleCommitEdit(user)
                                                    }
                                                    className="fa fa-edit"
                                                  ></i>
                                                </>
                                              )}
                                              {isCommentEditable &&
                                                selectedComment?._id ===
                                                  user._id && (
                                                  <Fragment>
                                                    <textarea
                                                      className="form-control form-control-sm"
                                                      placeholder="Enter additional details"
                                                      defaultValue={
                                                        user.comment
                                                      }
                                                      onChange={(e) =>
                                                        handleCommentChange(
                                                          e,
                                                          user
                                                        )
                                                      }
                                                    />
                                                    <br />
                                                    <button
                                                      className="btn-sm btn-secondary"
                                                      onClick={
                                                        handldSubmitCommentChange
                                                      }
                                                    >
                                                      {selectedComment?._id ===
                                                        user._id &&
                                                      updatingComment
                                                        ? "Editing..."
                                                        : "Submit"}
                                                    </button>
                                                  </Fragment>
                                                )}
                                            </td>
                                            <td>
                                              {moment(user?.createdAt).format(
                                                "DD-MM-YYYY HH:mm A"
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>

                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div className="dataTables_info"></div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example2_paginate"
                                  >
                                    <Pagination
                                      pagination={pagination}
                                      data={data}
                                      limit={limit}
                                      onPageChange={onPageChange}
                                      handleLimitChange={handleLimitChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane id="my-posts" eventKey={"tourInquiry"}>
                  <FlightinquiryTable
                    state={state}
                    updatingStatusID={updatingStatusID}
                    saveDetail={saveDetail}
                    handleStatusChange={handleStatusChange}
                    handleCommitEdit={handleCommitEdit}
                    isCommentEditable={isCommentEditable}
                    selectedComment={selectedComment}
                    handleCommentChange={handleCommentChange}
                    updatingStatus={updatingStatus}
                    handldSubmitCommentChange={handldSubmitCommentChange}
                    updatingComment={updatingComment}
                    InquiryListData={InquiryListData}
                    isLoading={isLoading}
                    permissions={permissions}
                    onRequestInquiries={onRequestInquiries}
                    u_data={user}
                    allUsers={allUsers}
                  />
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info"></div>
                    <div
                      className="dataTables_paginate paging_simple_numbers mb-0"
                      id="example2_paginate"
                    >
                      <Pagination
                        pagination={pagination}
                        data={data}
                        limit={limit}
                        onPageChange={onPageChange}
                        handleLimitChange={handleLimitChange}
                      />
                    </div>
                  </div>
                  {/* //! Display None For Non Collapisable Table */}
                  <div
                    style={{ display: "none" }}
                    className="my-post-content pt-3"
                  >
                    <div className="my-post-content pt-3">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body p-0">
                            <div className="table-responsive">
                              <div
                                id="example2_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table
                                  id="example2"
                                  className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                                >
                                  <thead>
                                    <tr role="row">
                                      <th className="sorting_asc">Ref.ID</th>
                                      <th className="sorting_asc">Name</th>
                                      <th className="sorting">Contact</th>
                                      {state.tripType === "HajjInquiry" && (
                                        <th className="sorting">
                                          Total Travelers
                                        </th>
                                      )}
                                      {state.tripType === EVISA_INQ_TYPE && (
                                        <Fragment>
                                          <th className="sorting">
                                            No Of US/Canada Passport
                                          </th>
                                          <th className="sorting">
                                            No Of Other Passport
                                          </th>

                                          <th className="sorting"></th>
                                        </Fragment>
                                      )}

                                      {(state.tripType ===
                                        FLIGHT_HOTEL_INQ_TYPE ||
                                        state.tripType === FLIGHT_INQ_TYPE) && (
                                        <Fragment>
                                          <th className="sorting">
                                            Travel Dates
                                          </th>
                                          <th className="sorting">
                                            Trip Details
                                          </th>
                                          {state.tripType ===
                                            FLIGHT_HOTEL_INQ_TYPE && (
                                            <th className="sorting">
                                              Room Details
                                            </th>
                                          )}
                                          <th className="sorting bg-none"></th>
                                        </Fragment>
                                      )}

                                      <th width="12%">Status</th>
                                      <th width="12%">Comment</th>
                                      <th width="12%">Inquiry Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isLoading ? (
                                      <tr style={{ textAlign: "center" }}>
                                        <td colSpan="8">
                                          <TableLoader />
                                        </td>
                                      </tr>
                                    ) : null}
                                    {!isLoading && data.length === 0 && (
                                      <tr>
                                        <td colSpan="8">
                                          <NotFoundLable message="No Users found" />
                                        </td>
                                      </tr>
                                    )}
                                    {InquiryListData &&
                                      isArray(InquiryListData) &&
                                      InquiryListData.map((user) => {
                                        return (
                                          <tr
                                            key={user.id}
                                            role="row"
                                            className="odd"
                                          >
                                            <td className="text-nowrap">
                                              <p>
                                                <span>{user.refId}</span>
                                              </p>
                                            </td>
                                            <td>
                                              <div className="concierge-bx d-flex align-items-center">
                                                <div>
                                                  <h5 className="fs-16 mb-0 text-nowrap">
                                                    <Link
                                                      to={"#"}
                                                      className="text-black"
                                                    >
                                                      {user.fullName}
                                                    </Link>
                                                  </h5>
                                                  {/* <span className="text-primary fs-14">
                                      #EMP-00025
                                    </span> */}
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-nowrap">
                                              <span>{user.email}</span>
                                              <p>
                                                <span>{user.mobileNumber}</span>
                                              </p>
                                            </td>
                                            <td className="text-nowrap">
                                              <span>
                                                {user.totalTravellers}
                                              </span>
                                            </td>
                                            {(state.tripType ===
                                              FLIGHT_HOTEL_INQ_TYPE ||
                                              state.tripType ===
                                                FLIGHT_INQ_TYPE) && (
                                              <Fragment>
                                                <td>
                                                  <div>
                                                    <span>
                                                      {moment(
                                                        user.departureDate
                                                      ).format(DATE_FORMAT)}
                                                      {user.returnDate &&
                                                        ` To ${moment(
                                                          user.returnDate
                                                        ).format(DATE_FORMAT)}`}
                                                    </span>
                                                  </div>
                                                </td>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {`${user.from} To ${user.to}`}
                                                  </span>
                                                  <p>
                                                    <div>
                                                      <div className="card-content">
                                                        <address>
                                                          <div>
                                                            <a
                                                              className={`btn btn-${
                                                                user.travelClass ===
                                                                "Economy"
                                                                  ? "primary"
                                                                  : "secondary"
                                                              } light btn-xs mb-1 me-1`}
                                                            >
                                                              {user.travelClass}
                                                            </a>
                                                            {user.tripType ===
                                                            "multi_city" ? (
                                                              <Popover
                                                                content={() => {
                                                                  return (
                                                                    <PerfectScrollbar className="widget-timeline dlab-scroll style-1 ps p-3 height370">
                                                                      <ul className="timeline">
                                                                        {user.multiCity &&
                                                                          user.multiCity.map(
                                                                            (
                                                                              d
                                                                            ) => {
                                                                              return (
                                                                                <li>
                                                                                  <div className="timeline-badge info" />
                                                                                  <Link
                                                                                    className="timeline-panel c-pointer text-muted"
                                                                                    to="#"
                                                                                  >
                                                                                    <span>
                                                                                      {moment(
                                                                                        d.depatureDate
                                                                                      ).format(
                                                                                        DATE_FORMAT
                                                                                      )}
                                                                                    </span>
                                                                                    <h6 className="mb-0">
                                                                                      {`${d.from} To ${d.to}`}
                                                                                    </h6>
                                                                                  </Link>
                                                                                </li>
                                                                              );
                                                                            }
                                                                          )}
                                                                      </ul>
                                                                      <div
                                                                        className="ps__rail-x"
                                                                        style={{
                                                                          left: 0,
                                                                          bottom: 0,
                                                                        }}
                                                                      >
                                                                        <div
                                                                          className="ps__thumb-x"
                                                                          tabIndex={
                                                                            0
                                                                          }
                                                                          style={{
                                                                            left: 0,
                                                                            width: 0,
                                                                          }}
                                                                        />
                                                                      </div>
                                                                      <div
                                                                        className="ps__rail-y"
                                                                        style={{
                                                                          top: 0,
                                                                          right: 0,
                                                                        }}
                                                                      >
                                                                        <div
                                                                          className="ps__thumb-y"
                                                                          tabIndex={
                                                                            0
                                                                          }
                                                                          style={{
                                                                            top: 0,
                                                                            height: 0,
                                                                          }}
                                                                        />
                                                                      </div>
                                                                    </PerfectScrollbar>
                                                                  );
                                                                }}
                                                              >
                                                                <a
                                                                  className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                                                >
                                                                  {user.tripType
                                                                    ? user.tripType.replaceAll(
                                                                        "_",
                                                                        " "
                                                                      )
                                                                    : ""}
                                                                </a>
                                                              </Popover>
                                                            ) : (
                                                              <a
                                                                className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                                              >
                                                                {user.tripType
                                                                  ? user.tripType.replaceAll(
                                                                      "_",
                                                                      " "
                                                                    )
                                                                  : ""}
                                                              </a>
                                                            )}
                                                            <Popover
                                                              placement="bottom"
                                                              content={() => {
                                                                return (
                                                                  <div className="card-content">
                                                                    {user.noOfTravellerType &&
                                                                      user.noOfTravellerType.map(
                                                                        (d) => {
                                                                          return (
                                                                            <address>
                                                                              <strong className="text-capitalize">
                                                                                {
                                                                                  d.type
                                                                                }
                                                                              </strong>
                                                                              <br />
                                                                              <a>
                                                                                Member
                                                                                :{" "}
                                                                                {
                                                                                  d.members
                                                                                }
                                                                              </a>
                                                                            </address>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </div>
                                                                );
                                                              }}
                                                            >
                                                              <a
                                                                className={`btn btn-warning light btn-xs mb-1 me-1 text-capitalize`}
                                                              >
                                                                Travelers
                                                              </a>
                                                            </Popover>
                                                          </div>
                                                          {user.isDateFixed && (
                                                            <div>
                                                              <a
                                                                className={`btn btn-success light btn-xs mb-1 me-1`}
                                                              >
                                                                Date Is Fixed
                                                              </a>
                                                            </div>
                                                          )}
                                                          {user.isDateFlexible && (
                                                            <div>
                                                              <a
                                                                className={`btn btn-dark light btn-xs mb-1 me-1`}
                                                              >
                                                                Date Is Flexible
                                                              </a>
                                                            </div>
                                                          )}
                                                        </address>
                                                      </div>
                                                    </div>
                                                  </p>
                                                </td>
                                                {state.tripType ===
                                                  FLIGHT_HOTEL_INQ_TYPE && (
                                                  <td className="sorting">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                        }}
                                                        className="text-capitalize font-10"
                                                      >
                                                        {`No Of Rooms : ${user.noOfRooms}`}
                                                      </span>
                                                      <span
                                                        style={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                        }}
                                                        className="text-capitalize font-10"
                                                      >
                                                        {`Room Ratings : ${user.roomRating} Star`}
                                                      </span>
                                                    </div>
                                                  </td>
                                                )}
                                                <td></td>
                                              </Fragment>
                                            )}

                                            {state.tripType ===
                                              EVISA_INQ_TYPE && (
                                              <Fragment>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {user.us_ca_passports
                                                      ? user.us_ca_passports
                                                          .length
                                                      : "-"}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {user.other_passports
                                                      ? user.other_passports
                                                          .length
                                                      : "-"}
                                                  </span>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <Link
                                                    to={`/evisa-detail`}
                                                    onClick={() =>
                                                      saveDetail(user)
                                                    }
                                                    className="btn btn-secondary "
                                                  >
                                                    Details
                                                  </Link>{" "}
                                                </td>
                                              </Fragment>
                                            )}
                                            <td>
                                              {updatingStatusID !==
                                                user?._id && (
                                                <select
                                                  onChange={(e) =>
                                                    handleStatusChange(e, user)
                                                  }
                                                  className="form-control form-control-sm"
                                                  id="inputState"
                                                  defaultValue={user?.status}
                                                  // value={status}
                                                >
                                                  <option value="pending">
                                                    Pending
                                                  </option>
                                                  <option value="received">
                                                    Received
                                                  </option>
                                                  <option value="in_progress">
                                                    In-Progress
                                                  </option>
                                                  <option value="cancelled">
                                                    Cancelled
                                                  </option>
                                                  <option value="completed">
                                                    Completed
                                                  </option>
                                                </select>
                                              )}
                                              {updatingStatusID === user?._id &&
                                                updatingStatus && (
                                                  <ContentLoader />
                                                )}
                                            </td>
                                            <td>
                                              {selectedComment?._id !==
                                                user._id && (
                                                <>
                                                  <p>{user.comment}</p>
                                                  <i
                                                    onClick={() =>
                                                      handleCommitEdit(user)
                                                    }
                                                    className="fa fa-edit"
                                                  ></i>
                                                </>
                                              )}
                                              {isCommentEditable &&
                                                selectedComment?._id ===
                                                  user._id && (
                                                  <Fragment>
                                                    <textarea
                                                      className="form-control form-control-sm"
                                                      placeholder="Enter additional details"
                                                      defaultValue={
                                                        user.comment
                                                      }
                                                      onChange={(e) =>
                                                        handleCommentChange(
                                                          e,
                                                          user
                                                        )
                                                      }
                                                    />
                                                    <br />
                                                    <button
                                                      className="btn-sm btn-secondary"
                                                      onClick={
                                                        handldSubmitCommentChange
                                                      }
                                                    >
                                                      {selectedComment?._id ===
                                                        user._id &&
                                                      updatingComment
                                                        ? "Editing..."
                                                        : "Submit"}
                                                    </button>
                                                  </Fragment>
                                                )}
                                            </td>
                                            <td>
                                              {moment(user?.createdAt).format(
                                                "DD-MM-YYYY HH:mm A"
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>

                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div className="dataTables_info"></div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example2_paginate"
                                  >
                                    <Pagination
                                      pagination={pagination}
                                      data={data}
                                      limit={limit}
                                      onPageChange={onPageChange}
                                      handleLimitChange={handleLimitChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane id="my-posts" eventKey={"contactUs"}>
                  <FlightinquiryTable
                    state={state}
                    updatingStatusID={updatingStatusID}
                    saveDetail={saveDetail}
                    handleStatusChange={handleStatusChange}
                    handleCommitEdit={handleCommitEdit}
                    isCommentEditable={isCommentEditable}
                    selectedComment={selectedComment}
                    handleCommentChange={handleCommentChange}
                    updatingStatus={updatingStatus}
                    handldSubmitCommentChange={handldSubmitCommentChange}
                    updatingComment={updatingComment}
                    InquiryListData={InquiryListData}
                    isLoading={isLoading}
                    permissions={permissions}
                    onRequestInquiries={onRequestInquiries}
                    u_data={user}
                    allUsers={allUsers}
                  />
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info"></div>
                    <div
                      className="dataTables_paginate paging_simple_numbers mb-0"
                      id="example2_paginate"
                    >
                      <Pagination
                        pagination={pagination}
                        data={data}
                        limit={limit}
                        onPageChange={onPageChange}
                        handleLimitChange={handleLimitChange}
                      />
                    </div>
                  </div>
                  {/* //! Display None For Non Collapisable Table */}
                  <div
                    style={{ display: "none" }}
                    className="my-post-content pt-3"
                  >
                    <div className="my-post-content pt-3">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body p-0">
                            <div className="table-responsive">
                              <div
                                id="example2_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table
                                  id="example2"
                                  className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                                >
                                  <thead>
                                    <tr role="row">
                                      <th className="sorting_asc">Ref.ID</th>
                                      <th className="sorting_asc">Name</th>
                                      <th className="sorting">Contact</th>
                                      {state.tripType === "HajjInquiry" && (
                                        <th className="sorting">
                                          Total Travelers
                                        </th>
                                      )}
                                      {state.tripType === EVISA_INQ_TYPE && (
                                        <Fragment>
                                          <th className="sorting">
                                            No Of US/Canada Passport
                                          </th>
                                          <th className="sorting">
                                            No Of Other Passport
                                          </th>

                                          <th className="sorting"></th>
                                        </Fragment>
                                      )}

                                      {(state.tripType ===
                                        FLIGHT_HOTEL_INQ_TYPE ||
                                        state.tripType === FLIGHT_INQ_TYPE) && (
                                        <Fragment>
                                          <th className="sorting">
                                            Travel Dates
                                          </th>
                                          <th className="sorting">
                                            Trip Details
                                          </th>
                                          {state.tripType ===
                                            FLIGHT_HOTEL_INQ_TYPE && (
                                            <th className="sorting">
                                              Room Details
                                            </th>
                                          )}
                                          <th className="sorting bg-none"></th>
                                        </Fragment>
                                      )}

                                      <th width="12%">Status</th>
                                      <th width="12%">Comment</th>
                                      <th width="12%">Inquiry Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isLoading ? (
                                      <tr style={{ textAlign: "center" }}>
                                        <td colSpan="8">
                                          <TableLoader />
                                        </td>
                                      </tr>
                                    ) : null}
                                    {!isLoading && data.length === 0 && (
                                      <tr>
                                        <td colSpan="8">
                                          <NotFoundLable message="No Users found" />
                                        </td>
                                      </tr>
                                    )}
                                    {InquiryListData &&
                                      isArray(InquiryListData) &&
                                      InquiryListData.map((user) => {
                                        return (
                                          <tr
                                            key={user.id}
                                            role="row"
                                            className="odd"
                                          >
                                            <td className="text-nowrap">
                                              <p>
                                                <span>{user.refId}</span>
                                              </p>
                                            </td>
                                            <td>
                                              <div className="concierge-bx d-flex align-items-center">
                                                <div>
                                                  <h5 className="fs-16 mb-0 text-nowrap">
                                                    <Link
                                                      to={"#"}
                                                      className="text-black"
                                                    >
                                                      {user.fullName}
                                                    </Link>
                                                  </h5>
                                                  {/* <span className="text-primary fs-14">
                                      #EMP-00025
                                    </span> */}
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-nowrap">
                                              <span>{user.email}</span>
                                              <p>
                                                <span>{user.mobileNumber}</span>
                                              </p>
                                            </td>
                                            <td className="text-nowrap">
                                              <span>
                                                {user.totalTravellers}
                                              </span>
                                            </td>
                                            {(state.tripType ===
                                              FLIGHT_HOTEL_INQ_TYPE ||
                                              state.tripType ===
                                                FLIGHT_INQ_TYPE) && (
                                              <Fragment>
                                                <td>
                                                  <div>
                                                    <span>
                                                      {moment(
                                                        user.departureDate
                                                      ).format(DATE_FORMAT)}
                                                      {user.returnDate &&
                                                        ` To ${moment(
                                                          user.returnDate
                                                        ).format(DATE_FORMAT)}`}
                                                    </span>
                                                  </div>
                                                </td>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {`${user.from} To ${user.to}`}
                                                  </span>
                                                  <p>
                                                    <div>
                                                      <div className="card-content">
                                                        <address>
                                                          <div>
                                                            <a
                                                              className={`btn btn-${
                                                                user.travelClass ===
                                                                "Economy"
                                                                  ? "primary"
                                                                  : "secondary"
                                                              } light btn-xs mb-1 me-1`}
                                                            >
                                                              {user.travelClass}
                                                            </a>
                                                            {user.tripType ===
                                                            "multi_city" ? (
                                                              <Popover
                                                                content={() => {
                                                                  return (
                                                                    <PerfectScrollbar className="widget-timeline dlab-scroll style-1 ps p-3 height370">
                                                                      <ul className="timeline">
                                                                        {user.multiCity &&
                                                                          user.multiCity.map(
                                                                            (
                                                                              d
                                                                            ) => {
                                                                              return (
                                                                                <li>
                                                                                  <div className="timeline-badge info" />
                                                                                  <Link
                                                                                    className="timeline-panel c-pointer text-muted"
                                                                                    to="#"
                                                                                  >
                                                                                    <span>
                                                                                      {moment(
                                                                                        d.depatureDate
                                                                                      ).format(
                                                                                        DATE_FORMAT
                                                                                      )}
                                                                                    </span>
                                                                                    <h6 className="mb-0">
                                                                                      {`${d.from} To ${d.to}`}
                                                                                    </h6>
                                                                                  </Link>
                                                                                </li>
                                                                              );
                                                                            }
                                                                          )}
                                                                      </ul>
                                                                      <div
                                                                        className="ps__rail-x"
                                                                        style={{
                                                                          left: 0,
                                                                          bottom: 0,
                                                                        }}
                                                                      >
                                                                        <div
                                                                          className="ps__thumb-x"
                                                                          tabIndex={
                                                                            0
                                                                          }
                                                                          style={{
                                                                            left: 0,
                                                                            width: 0,
                                                                          }}
                                                                        />
                                                                      </div>
                                                                      <div
                                                                        className="ps__rail-y"
                                                                        style={{
                                                                          top: 0,
                                                                          right: 0,
                                                                        }}
                                                                      >
                                                                        <div
                                                                          className="ps__thumb-y"
                                                                          tabIndex={
                                                                            0
                                                                          }
                                                                          style={{
                                                                            top: 0,
                                                                            height: 0,
                                                                          }}
                                                                        />
                                                                      </div>
                                                                    </PerfectScrollbar>
                                                                  );
                                                                }}
                                                              >
                                                                <a
                                                                  className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                                                >
                                                                  {user.tripType
                                                                    ? user.tripType.replaceAll(
                                                                        "_",
                                                                        " "
                                                                      )
                                                                    : ""}
                                                                </a>
                                                              </Popover>
                                                            ) : (
                                                              <a
                                                                className={`btn btn-info light btn-xs mb-1 me-1 text-capitalize`}
                                                              >
                                                                {user.tripType
                                                                  ? user.tripType.replaceAll(
                                                                      "_",
                                                                      " "
                                                                    )
                                                                  : ""}
                                                              </a>
                                                            )}
                                                            <Popover
                                                              placement="bottom"
                                                              content={() => {
                                                                return (
                                                                  <div className="card-content">
                                                                    {user.noOfTravellerType &&
                                                                      user.noOfTravellerType.map(
                                                                        (d) => {
                                                                          return (
                                                                            <address>
                                                                              <strong className="text-capitalize">
                                                                                {
                                                                                  d.type
                                                                                }
                                                                              </strong>
                                                                              <br />
                                                                              <a>
                                                                                Member
                                                                                :{" "}
                                                                                {
                                                                                  d.members
                                                                                }
                                                                              </a>
                                                                            </address>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </div>
                                                                );
                                                              }}
                                                            >
                                                              <a
                                                                className={`btn btn-warning light btn-xs mb-1 me-1 text-capitalize`}
                                                              >
                                                                Travelers
                                                              </a>
                                                            </Popover>
                                                          </div>
                                                          {user.isDateFixed && (
                                                            <div>
                                                              <a
                                                                className={`btn btn-success light btn-xs mb-1 me-1`}
                                                              >
                                                                Date Is Fixed
                                                              </a>
                                                            </div>
                                                          )}
                                                          {user.isDateFlexible && (
                                                            <div>
                                                              <a
                                                                className={`btn btn-dark light btn-xs mb-1 me-1`}
                                                              >
                                                                Date Is Flexible
                                                              </a>
                                                            </div>
                                                          )}
                                                        </address>
                                                      </div>
                                                    </div>
                                                  </p>
                                                </td>
                                                {state.tripType ===
                                                  FLIGHT_HOTEL_INQ_TYPE && (
                                                  <td className="sorting">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                        }}
                                                        className="text-capitalize font-10"
                                                      >
                                                        {`No Of Rooms : ${user.noOfRooms}`}
                                                      </span>
                                                      <span
                                                        style={{
                                                          fontSize: 13,
                                                          fontWeight: 500,
                                                        }}
                                                        className="text-capitalize font-10"
                                                      >
                                                        {`Room Ratings : ${user.roomRating} Star`}
                                                      </span>
                                                    </div>
                                                  </td>
                                                )}
                                                <td></td>
                                              </Fragment>
                                            )}

                                            {state.tripType ===
                                              EVISA_INQ_TYPE && (
                                              <Fragment>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {user.us_ca_passports
                                                      ? user.us_ca_passports
                                                          .length
                                                      : "-"}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 500,
                                                    }}
                                                    className="text-capitalize font-10"
                                                  >
                                                    {user.other_passports
                                                      ? user.other_passports
                                                          .length
                                                      : "-"}
                                                  </span>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <Link
                                                    to={`/evisa-detail`}
                                                    onClick={() =>
                                                      saveDetail(user)
                                                    }
                                                    className="btn btn-secondary "
                                                  >
                                                    Details
                                                  </Link>{" "}
                                                </td>
                                              </Fragment>
                                            )}
                                            <td>
                                              {updatingStatusID !==
                                                user?._id && (
                                                <select
                                                  onChange={(e) =>
                                                    handleStatusChange(e, user)
                                                  }
                                                  className="form-control form-control-sm"
                                                  id="inputState"
                                                  defaultValue={user?.status}
                                                  // value={status}
                                                >
                                                  <option value="pending">
                                                    Pending
                                                  </option>
                                                  <option value="received">
                                                    Received
                                                  </option>
                                                  <option value="in_progress">
                                                    In-Progress
                                                  </option>
                                                  <option value="cancelled">
                                                    Cancelled
                                                  </option>
                                                  <option value="completed">
                                                    Completed
                                                  </option>
                                                </select>
                                              )}
                                              {updatingStatusID === user?._id &&
                                                updatingStatus && (
                                                  <ContentLoader />
                                                )}
                                            </td>
                                            <td>
                                              {selectedComment?._id !==
                                                user._id && (
                                                <>
                                                  <p>{user.comment}</p>
                                                  <i
                                                    onClick={() =>
                                                      handleCommitEdit(user)
                                                    }
                                                    className="fa fa-edit"
                                                  ></i>
                                                </>
                                              )}
                                              {isCommentEditable &&
                                                selectedComment?._id ===
                                                  user._id && (
                                                  <Fragment>
                                                    <textarea
                                                      className="form-control form-control-sm"
                                                      placeholder="Enter additional details"
                                                      defaultValue={
                                                        user.comment
                                                      }
                                                      onChange={(e) =>
                                                        handleCommentChange(
                                                          e,
                                                          user
                                                        )
                                                      }
                                                    />
                                                    <br />
                                                    <button
                                                      className="btn-sm btn-secondary"
                                                      onClick={
                                                        handldSubmitCommentChange
                                                      }
                                                    >
                                                      {selectedComment?._id ===
                                                        user._id &&
                                                      updatingComment
                                                        ? "Editing..."
                                                        : "Submit"}
                                                    </button>
                                                  </Fragment>
                                                )}
                                            </td>
                                            <td>
                                              {moment(user?.createdAt).format(
                                                "DD-MM-YYYY HH:mm A"
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>

                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div className="dataTables_info"></div>
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example2_paginate"
                                  >
                                    <Pagination
                                      pagination={pagination}
                                      data={data}
                                      limit={limit}
                                      onPageChange={onPageChange}
                                      handleLimitChange={handleLimitChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane id="my-posts" eventKey="Hajj">
                  <div className="my-post-content pt-3">
                    <Packageinquiries
                      onRequestPkgInquiries={onRequestPkgInquiries}
                      permissions={permissions}
                      user={user}
                      page={page}
                      limit={limit}
                      setPage={setPage}
                      setLimit={setLimit}
                      allUsers={allUsers}
                      onPageChange={onPageChange}
                      pagination={pagination}
                      data={data}
                      filters={{
                        ...packageFilter,
                        type: state.tripType,
                        search: searchValue,
                        limit: limit,
                      }}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane id="my-posts" eventKey="Umrah">
                  <div className="my-post-content pt-3">
                    <div className="p-4">
                      <Radio.Group
                        onChange={(e) => {
                          let key = e?.target?.value;
                          // setTab("Umrah");
                          setPage(1);
                          setLimit(20);
                          setSearchValue("");
                          if (searchRef) {
                            searchRef.current.value = "";
                          }

                          setState({
                            ...state,
                            tripType: key,
                          });
                          setDateRange({
                            startDate: null,
                            endDate: null,
                          });
                          setFilter({
                            startDate: null,
                            endDate: null,
                          });

                          setDate(null);
                        }}
                        value={state?.tripType}
                      >
                        <Radio value={"Umrah"}>Umrah Packages </Radio>
                        <Radio value={"ramadan_umrah"}>Ramdan Umrah </Radio>
                      </Radio.Group>
                    </div>

                    <Packageinquiries
                      onRequestPkgInquiries={onRequestPkgInquiries}
                      permissions={permissions}
                      user={user}
                      limit={limit}
                      page={page}
                      setLimit={setLimit}
                      allUsers={allUsers}
                      setPage={setPage}
                      onPageChange={onPageChange}
                      pagination={pagination}
                      data={data}
                      filters={{
                        ...packageFilter,
                        type: state.tripType,
                        search: searchValue,
                        limit: limit,
                      }}
                    />
                  </div>
                  {/* <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info"></div>
                    <div
                      className="dataTables_paginate paging_simple_numbers mb-0"
                      id="example2_paginate"
                    >
                      <Pagination
                        pagination={pagination}
                        data={data}
                        limit={limit}
                        onPageChange={onPageChange}
                        handleLimitChange={handleLimitChange}
                      />
                    </div>
                  </div> */}
                </Tab.Pane>
                <Tab.Pane id="my-posts" eventKey="Other">
                  <div className="my-post-content pt-3">
                    <Packageinquiries
                      onRequestPkgInquiries={onRequestPkgInquiries}
                      permissions={permissions}
                      user={user}
                      limit={limit}
                      page={page}
                      // limit={limit}
                      setPage={setPage}
                      setLimit={setLimit}
                      allUsers={allUsers}
                      onPageChange={onPageChange}
                      pagination={pagination}
                      data={data}
                      filters={{
                        ...packageFilter,
                        type: state.tripType,
                        search: searchValue,
                        limit: limit,
                      }}
                    />
                  </div>
                  {/* {selectedTab === "Other" && (
                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                      <div className="dataTables_info"></div>
                      <div
                        className="dataTables_paginate paging_simple_numbers mb-0"
                        id="example2_paginate"
                      >
                        <Pagination
                          pagination={pagination}
                          data={data}
                          limit={limit}
                          onPageChange={onPageChange}
                          handleLimitChange={handleLimitChange}
                        />
                      </div>
                    </div>
                  )} */}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};
export default InquiryList;
