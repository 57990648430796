import axios from "axios";
import { post } from "../services/index";
import swal from "sweetalert";
import { logout } from "../store/actions/AuthActions";
import { verifyObject } from "../utilities/utils";
import { localStore } from "../store/reducers/localStore";
import { isArray } from "lodash";

// export function signUp(email, password) {
//   //axios call
//   const postData = {
//     email,
//     password,
//     returnSecureToken: true,
//   };
//   return axios.post(
//     `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
//     postData
//   );
// }

export const signUp = (email, password) => {
  const request = {
    subUrl: `/admin/signup`,
    data: {
      email: email,
      password: password,
    },
  };
  return post(request);
};
export function login(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(dispatch, token) {
  // tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000,
  // );
  dispatch({
    type: "LOGIN_SUCCESS",
    payload: token,
  });

  // localStorage.setItem('token', JSON.stringify(tokenDetails));
}

export function savePermissionsInLocalStorage(dispatch, permissions) {
  // tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000,
  // );
  let viewPermissions = [];
  let editPermissions = [];
  let createPermissions = [];
  let deletePermissions = [];
  permissions &&
    isArray(permissions) &&
    permissions?.forEach((item) => {
      viewPermissions = {
        ...viewPermissions,
        [item.title]: item.actions["view"],
      };
      editPermissions = {
        ...editPermissions,
        [item.title]: item.actions["edit"],
      };

      createPermissions = {
        ...createPermissions,
        [item.title]: item.actions["create"],
      };
      deletePermissions = {
        ...deletePermissions,
        [item.title]: item.actions["delete"],
      };
    });

  dispatch({
    type: "SAVE_PERMISSIONS_SUCCESS",
    payload: {
      viewPermissions: viewPermissions,
      editPermissions: editPermissions,
      createPermissions: createPermissions,
      deletePermissions: deletePermissions,
    },
  });

  // localStorage.setItem('token', JSON.stringify(tokenDetails));
}

export function saveUserInLocalStorage(dispatch, role, userId) {
  // tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000,
  // );
  dispatch({
    type: "SAVE_USER_SUCCESS",
    payload: { role: role, userId: userId },
  });

  // localStorage.setItem('token', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  console.log("Auto login called");
  const state = JSON.parse(localStorage.getItem("state"));
  const token = verifyObject(state, "localStore.token", null);
  const user = verifyObject(state, "localStore.user", null);
  const permissions = verifyObject(state, "localStore.permissions", null);

  localStorage.setItem("state auto", JSON.stringify(state));

  const tokenDetailsString = token;
  //   let tokenDetails = "";

  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }

  dispatch({
    type: "LOGIN_SUCCESS",
    payload: tokenDetailsString,
  });

  dispatch({
    type: "SAVE_USER_SUCCESS",
    payload: user,
  });

  // console.log("Permissions: ", permissions);

  dispatch({
    type: "SAVE_PERMISSIONS_SUCCESS",
    payload: permissions,
  });
}
