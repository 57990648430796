
// import { currencies } from "utilities/utils";
const initialState = {
  token: null,
  user: null,
  local_package_id: null,
  evisaDetails: null,
  permissions: [],
};
export const localStore = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS": {
      return { ...state, token: action.payload };
    }
    case "SAVE_USER_SUCCESS": {
      return { ...state, user: action.payload };
    }

    case "SAVE_PERMISSIONS_SUCCESS": {
      return {
        ...state,
        permissions: action.payload,
      };
    }

    case "SET_PACKAGE_ID": {
      return { ...state, local_package_id: action.payload };
    }
    case "SET_EVISA_DETAILS": {
      return { ...state, evisaDetails: action.payload };
    }
    case "LOG_OUT":
      return initialState;

    case "CLEAR_LOCAL_STATE":
      return { ...initialState };
    default:
      return state;
  }
};
