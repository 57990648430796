/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import avater1 from "../../../images/avatar/1.jpg";

import PageTitle from "../../layouts/PageTitle";
import { verifyObject } from "../../../utilities/utils";
import swal from "sweetalert";
import { getFaqByID } from "../../../services/FAQs";
import TableLoader from "../common/TableLoader";
//import StarRating from './../ProductList/StarRating';

// const initialstate = false;
// const reducer = (state, action) => {
//   if (action.type === "reviewToggle") {
//     return { ...state, reviewToggle: !state.reviewToggle };
//   }
//   return state;
// };

const FAQDetails = () => {
  const [isLoading, setLoading] = useState(false);
  const [FaqDetails, setFaqDetail] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    _getFaqByID();
  }, []);

  const _getFaqByID = async () => {
    try {
      await setLoading(true);
      let response = await getFaqByID(id);
      console.log("Response roles", response);
      let FaqDetails = response.data.data;

      await setFaqDetail(FaqDetails);
      await setLoading(false);

      // this.setState()
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setLoading(false);
    }
  };
  return (
    <>
      <PageTitle motherMenu="FAQs" activeMenu="FAQ Detail" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                {/*Tab slider End*/}
                {isLoading && <TableLoader />}
                {FaqDetails && (
                  <div className="Col-lg-12">
                    <div className="product-detail-content">
                      {/*Product details*/}
                      <div className="new-arrival-content pr">
                        <h4>{FaqDetails.question}</h4>
                        {/* <div className="d-table mb-2">
                          <p className="price float-left d-block">
                            {FaqDetails.question}
                          </p>
                        </div> */}

                        <p
                          dangerouslySetInnerHTML={{
                            __html: FaqDetails.answer,
                          }}
                          className="text-content"
                        ></p>

                        {/*Quantity start*/}

                        {/*Quanatity End*/}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* review */}
      </div>
    </>
  );
};

export default FAQDetails;
