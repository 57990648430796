/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PageTitle from "../../layouts/PageTitle";
import { verifyObject } from "../../../utilities/utils";
import swal from "sweetalert";
import TableLoader from "../common/TableLoader";
import { getBlogsByID } from "../../../services/Blogs";
import { imageURL } from "../../../services";

//import StarRating from './../ProductList/StarRating';

// const initialstate = false;
// const reducer = (state, action) => {
//   if (action.type === "reviewToggle") {
//     return { ...state, reviewToggle: !state.reviewToggle };
//   }
//   return state;
// };

const BlogDetails = () => {
  const [isLoading, setLoading] = useState(false);
  const [BlogDetail, setBlogDetail] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    _getBlogByID();
  }, []);

  const _getBlogByID = async () => {
    try {
      await setLoading(true);
      let response = await getBlogsByID(id);
      console.log("Response roles", response);
      let BlogDetail = response.data.data;

      await setBlogDetail(BlogDetail);
      await setLoading(false);

      // this.setState()
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setLoading(false);
    }
  };
  return (
    <>
      <PageTitle motherMenu="Blogs" to={"/blogs"} activeMenu="Blogs Detail" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                {/*Tab slider End*/}
                {isLoading && <TableLoader />}
                {BlogDetail && (
                  <div className="Col-lg-12">
                    <div className="product-detail-content">
                      {/*Product details*/}
                      <img
                        src={`${imageURL}${BlogDetail.imagePath}${BlogDetail.image}`}
                        alt=""
                        // className="img-fluid mb-3 w-100 rounded"
                      />
                      <div className="new-arrival-content pr">
                        <h4>{BlogDetail.title}</h4>
                        {/* <div className="d-table mb-2">
                          <p className="price float-left d-block">
                            {BlogDetail.question}
                          </p>
                        </div> */}

                        <p
                          dangerouslySetInnerHTML={{
                            __html: BlogDetail.description,
                          }}
                          className="text-content"
                        ></p>

                        {/*Quantity start*/}

                        {/*Quanatity End*/}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* review */}
      </div>
    </>
  );
};

export default BlogDetails;
