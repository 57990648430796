import { listingReducer } from "../reducers/utils/listingReducer";
import { getPackagesList } from "../../services/packages";

const { reducer, actions, types } = listingReducer({
  name: `packages`,
  getApi: getPackagesList,
});
const packages = reducer;

export { packages, actions, types };
