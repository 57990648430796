import validator from "validator";
// first_name: "",
//     last_name: "",
//     phone: "",
//     email: "",
//     gender: "",
//     address_name: "",
//     addres_line_1: "",
//     addres_line_2: "",
//     addres_line_3: "",
//     city: "",
//     postal_code: "",
//     weight: "",
//     height: "",
//     dob: "",
export function ValidateInput(data) {
  console.log("DATA", data);
  let errors = {};

  if (data.role_name !== undefined && validator.isEmpty(data.role_name)) {
    errors.role_name = "Role name  is required";
  }
  console.log("errors", errors);
  return errors;
}
