import { post,patch ,get, deletee} from "./index";

export const getRoles = (params) => {
  const request = {
    subUrl: `/role`,
    params,
  };
  return get(request);
};

export const addnewRole = (data) => {
  const request = {
    subUrl: `/role`,
    data,
  };
  return post(request);
};

export const    updateRole = (data) => {
  const request = {
    subUrl: `/role/${data._id}`,
    data,
  };
  return patch(request);
};

export const deleteRole = (data) => {
  const request = {
    subUrl: `/role/${data._id}`,
    data,
  };
  return deletee(request);
};
