/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { isArray } from "lodash";
import swal from "sweetalert";
import { actions as reviewsActions } from "../../../store/reducers/reviewsReducer";

import TableLoader from "../common/TableLoader";
import NotFoundLable from "../common/NotFoundLable";
import { useDispatch, useSelector } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import Pagination from "../../components/common/Pagination";
import AddNewReview from "./AddNewReview";
import EditReview from "./EditReview";
import { deleteReview } from "../../../services/Reviews";

const ReviewsList = () => {
  const [editedData, setEditFormData] = useState("Newest");
  const [userModal, setUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);

  const dispatch = useDispatch();
  const { data, isLoading, pagination } = useSelector((state) => state.reviews);
  const { permissions,user } = useSelector((state) => state?.localStore);
  const createPermissions = permissions?.createPermissions;
  const editPermissions = permissions?.editPermissions;
  const deletePermissions = permissions?.deletePermissions;

  const handleEditClick = (event, data) => {
    console.log("data", data);
    event.preventDefault();
    const formValues = {
      id: data._id,
      personName: data.personName,
      year: data.year,
      address: data.address,
      type: data.type,
      description: data.description,
    };
    setEditFormData(formValues);
    handleEditModal();
  };

  const handleEditModal = () => {
    setEditUserModal(!editUserModal);
  };

  const _getBlogList = () => {
    dispatch(reviewsActions.onRequest({}));
  };

  const onPageChange = (data) => {
    // const { customerId: user_id } = props.localStore;
    const { page } = data;
    dispatch(reviewsActions.onRequest({ page }));
  };

  // const changeStatus = async (status, id) => {
  //   try {
  //     let formData = new FormData();
  //     formData.set("status", status);
  //     let response = await updateBlog(formData, id);

  //     console.log("Response roles", response);
  //     // setUserArray(response.data.data.docs);
  //     // this.setState()
  //     await swal({
  //       title: "Success",
  //       text: response.data.message,
  //       buttons: false,
  //       icon: "success",
  //       timer: 1000,
  //     });
  //     await dispatch(reviewsActions.onRequest({}));
  //   } catch (e) {
  //     console.log("eeee", e);
  //     let errorMessage = verifyObject(
  //       e,
  //       "response.data.message",
  //       "Something went wrong"
  //     );
  //     swal("Server Error", errorMessage, "error");
  //   }
  // };

  useEffect(() => {
    _getBlogList();
  }, []);

  const DropdownBlog = (u) => {
    return (
      <>
        {(editPermissions.Reviews ||
          deletePermissions.Reviews ||
          user.role === "admin") && (
          <Dropdown className="dropdown">
            <Dropdown.Toggle
              as="div"
              className="btn-link i-false"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              {(editPermissions.Reviews || user.role === "admin") && (
                <Dropdown.Item
                  onClick={(e) => handleEditClick(e, u)}
                  className="dropdown-item"
                >
                  Edit
                </Dropdown.Item>
              )}
              {(deletePermissions.Reviews || user.role === "admin") && (
                <Dropdown.Item
                  onClick={() => {
                    return swal({
                      title: "Are you sure?",
                      text: "Once deleted, you will not be able to recover this record!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        deleteRecord(u._id);
                      } else {
                        swal("Your record is safe!");
                      }
                    });
                  }}
                  className="dropdown-item"
                >
                  Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </>
    );
  };

  const handleModal = (value) => {
    setUserModal(!userModal);
  };

  const deleteRecord = async (id) => {
    try {
      // await setLoading(true);
      let response = await deleteReview(id);
      console.log("Response roles", response);
      swal("Poof! Your record has been deleted!", {
        icon: "success",
        timer: 3000,
        buttons: false,
      });
      _getBlogList();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      // await setLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2 flex-wrap">
          <div className="newest ms-3">
            {(createPermissions.Reviews || user.role === "admin") && (
              <Link
                to={"#"}
                className="btn btn-primary font-w600 mb-2 me-auto"
                onClick={() => {
                  setEditFormData(null);
                  handleModal();
                }}
              >
                + New Review
              </Link>
            )}
            <AddNewReview
              editedData={editedData}
              userModal={userModal}
              setAddUserModal={handleModal}
              _getBlogList={_getBlogList}
            />
            <EditReview
              editedData={editedData}
              userModal={editUserModal}
              setAddUserModal={handleEditModal}
              _getBlogList={_getBlogList}
            />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <div
                  id="example2_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example2"
                    className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting"> ID</th>
                        <th className="sorting_asc">Name</th>
                        <th className="sorting_asc">Type</th>
                        <th className="sorting_asc">Year</th>
                        <th className="sorting_asc">Description</th>
                        <th className="sorting bg-none"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="8">
                            <TableLoader />
                          </td>
                        </tr>
                      ) : null}
                      {!isLoading && data.length === 0 && (
                        <tr>
                          <td colSpan="8">
                            <NotFoundLable message="No Reviews Found" />
                          </td>
                        </tr>
                      )}
                      {data &&
                        isArray(data) &&
                        data.map((user, index) => {
                          return (
                            <tr key={user.id} role="row" className="odd">
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-nowrap">
                                      {index + 1}
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-nowrap">
                                      {user.personName}
                                    </h5>
                                    <p>{user.address}</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-nowrap">
                                      {user.type}
                                    </h5>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-nowrap">
                                      {user.year}
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-wrap">
                                      {/* <p
                                        dangerouslySetInnerHTML={{
                                          __html: user.description,
                                        }}
                                      ></p> */}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: `${user.description.slice(
                                            0,
                                            200
                                          )} ${
                                            user.description.length > 200
                                              ? "..."
                                              : ""
                                          }`,
                                        }}
                                      />
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td>{DropdownBlog(user)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  {/* <PaginationComponent pagination={pagination} /> */}
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info">
                      {/* Showing {activePag.current * sort + 1} to{" "}
                      {data.length > (activePag.current + 1) * sort
                        ? (activePag.current + 1) * sort
                        : data.length}{" "}
                      of {data.length} entries */}
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers mb-0"
                      id="example2_paginate"
                    >
                      <Pagination
                        pagination={pagination}
                        data={data}
                        onPageChange={onPageChange}
                      />

                      {/* <Link
                        className="paginate_button previous disabled"
                        to="/guest-list"
                        onClick={() =>
                          activePag.current > 0 &&
                          onClick(activePag.current - 1)
                        }
                      >
                        <i
                          className="fa fa-angle-double-left"
                          aria-hidden="true"
                        ></i>
                      </Link>
                      <span>
                        {paggination.map((number, i) => (
                          <Link
                            key={i}
                            to="/guest-list"
                            className={`paginate_button  ${
                              activePag.current === i ? "current" : ""
                            } `}
                            onClick={() => onClick(i)}
                          >
                            {number}
                          </Link>
                        ))}
                      </span>

                      <Link
                        className="paginate_button next"
                        to="/guest-list"
                        onClick={() =>
                          activePag.current + 1 < paggination.length &&
                          onClick(activePag.current + 1)
                        }
                      >
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        ></i>
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReviewsList;
