//@flow
import axios from "axios";
import { loadState } from "../app/localStorage";
// import { history } from "app/store";
// import history from "utilities/history.js";

export const authTokenKey = "Authorization";
// const dstore = store.dis
export const responseHeaderKeys = {
  pagination: "x-pagination",
};
export const imageURL = `https://chicagohaj-node.herokuapp.com`;

// const apiBaseUrls = {
//   prod: "https://chicagohaj-node.herokuapp.com",
//   dev: "https://chicagohaj-node.herokuapp.com",
//   stage: "https://chicagohaj-node.herokuapp.com",
// };

const apiBaseUrls = {
  prod: "https://api.chicagohajj.com",
  dev: "https://api.chicagohajj.com",
  stage: "https://api.chicagohajj.com",
};

const newApiBaseUrls = {
  prod: "",
  dev: "https://identitytoolkit.googleapis.com/v1/",
  stage: "https://identitytoolkit.googleapis.com/v1/",
};
const webSocketURL = {
  prod: "wss://api.alldaydr.com",
  dev: "wss://dev-api.alldaydr.com",
  // stage: "wss://alb-main-alldaydr-dev-248981017.eu-west-2.elb.amazonaws.com",
  // stage: "wss://dev.alldaydr.com",
  stage: "wss://staging-api.alldaydr.com",
};

export const appConfig = {
  mockEnv: false,
};

const react_app_env = {
  prod: "prod",
  dev: "dev",
  stage: "stage",
};
// console.log(process.env.REACT_APP_ENV);

let baseUrl = apiBaseUrls.dev;
let newBaseUrl = newApiBaseUrls.dev;
let baseWebSocketURL = webSocketURL.dev;
if (process.env.REACT_APP_ENV === react_app_env.stage) {
  baseUrl = apiBaseUrls.stage;
  baseWebSocketURL = webSocketURL.stage;
  newBaseUrl = newApiBaseUrls.stage;
} else if (process.env.REACT_APP_ENV === react_app_env.prod) {
  baseUrl = apiBaseUrls.prod;
  baseWebSocketURL = webSocketURL.prod;
  newBaseUrl = newApiBaseUrls.prod;
}
export const BASE_URL = baseUrl;
export const NEW_BASE_URL = newBaseUrl;
export type Irequest = {
  subUrl: string,
  method?: string,
  data?: object,
  params?: object,
  headers?: object,
};

export const get = (request) => {
  return commonFetch({ method: "get", ...request });
};

export const post = (request) => {
  return commonFetch({ method: "post", ...request });
};

export const patch = (request) => {
  return commonFetch({ method: "patch", ...request });
};

export const put = (request) => {
  return commonFetch({ method: "put", ...request });
};

export const deletee = (request) => {
  return commonFetch({ method: "delete", ...request });
};
export const get2 = (request) => {
  return commonFetch2({ method: "get", ...request });
};

const commonFetch = (request: Irequest) => {
  // axios.interceptors.request.use(
  //   (config) => {
  //     // perform a task before the request is sent
  //     console.log("Request was sent");

  //     return config;
  //   },
  //   (error) => {
  //     // handle the error

  //     return Promise.reject(error);
  //   }
  // );
  // axios.interceptors.response.use((response) => {
  //   // do something with the response data
  //   const { data } = response;
  //   if (data.status === 950 || data.status === 419) {
  //     localStorage.clear();
  //     // history.push("/");
  //     window.location.href ="/signin"
  //     // navigateToIndex();
  //     // store.dispatch(navigateToIndex());
  //     // this.props.history.push("/");
  //     // console.log("Response was received", response);
  //     // return false
  //     // return Promise.resolve(response);
  //   }
  //   return response;
  // });

  const {
    subUrl,
    method,
    data = {},
    params = {},
    headers = {},
    isOnlyURL,
    baseURLPassed,
    exportXL,
    // responseType: 'arraybuffer'
  } = request;
  let url = getFullUrl(subUrl, baseURLPassed);
  let passedHeaders = headers;
  let commonHeaders = getCommonHeaders();

  if (isOnlyURL) {
    url = subUrl;
    passedHeaders = {};
    commonHeaders = {};
  }
  let arrayBufferResponse = {};
  if (exportXL) {
    arrayBufferResponse = {
      responseType: "document",
    };
  }
  return axios({
    method,
    data,
    headers: { ...commonHeaders, ...passedHeaders },
    params,
    url,

    ...arrayBufferResponse,
  }).then((response) => {
    console.log("response",response)
    if (isOnlyURL) {
      if (response) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    } else {
      if (handleResponseStatus(response)) {
        return Promise.resolve(response);
      } else {
        // handleUnauthorizedResponses(response);

        return Promise.reject(response);
      }
    }
  });
};
const commonFetch2 = (request: Irequest) => {
  // axios.interceptors.request.use(
  //   (config) => {
  //     // perform a task before the request is sent
  //     console.log("Request was sent");

  //     return config;
  //   },
  //   (error) => {
  //     // handle the error

  //     return Promise.reject(error);
  //   }
  // );
  // axios.interceptors.response.use((response) => {
  //   // do something with the response data
  //   const { data } = response;
  //   if (data.status === 950 || data.status === 419) {
  //     localStorage.clear();
  //     // history.push("/");
  //     window.location.href ="/signin"
  //     // navigateToIndex();
  //     // store.dispatch(navigateToIndex());
  //     // this.props.history.push("/");
  //     // console.log("Response was received", response);
  //     // return false
  //     // return Promise.resolve(response);
  //   }
  //   return response;
  // });

  const { subUrl, method, data = {} } = request;

  // const url = getFullUrl(subUrl);
  // let commonHeaders = getCommonHeaders();

  // if (subUrl === "/appointments.json") {
  //   commonHeaders = getCommonHeaders(true);
  // }

  // console.log("PRAMAMS IN AXIOS", url);
  // var arrStr = encodeURIComponent(JSON.stringify(params))

  return axios({
    method,
    subUrl,
    data,
    // headers: { ...commonHeaders, ...headers },
    // params,
  }).then((response) => {
    if (handleResponseStatus(response)) {
      return Promise.resolve(response);
    } else {
      // handleUnauthorizedResponses(response);

      return Promise.reject(response);
    }
  });
};

// const handleUnauthorizedResponses = (response) => {
//   if (response.data.status === 950 || response.data.status === 419) {
//     localStorage.clear();
//     history.push("/signin");
//     // history.
//     // store.dispatch(navigateToIndex());
//     // navigateToindexFromToken();
//   }
// };
const handleResponseStatus = (response) => {
  console.log("responsne",response)
  if (response && response.data && response.data.success) {
    return true;
  }
  return false;
};

const getCommonHeaders = () => {
  const state = loadState();
  let token = null;
  if (state && state.localStore && state.localStore.token) {
    token = state.localStore.token;
  }
  if (state && state.localStore && state.localStore.reset_pass_token) {
    token = state.localStore.reset_pass_token;
  }
  return {
    "Content-Type": "application/json",
    [authTokenKey]: `Bearer ${token}`,
  };
};
const getFullUrl = (url, baseUrlPassed) => {
  if (baseUrlPassed) {
    return `${baseUrlPassed}${url}`;
  } else {
    return `${baseUrl}${url}`;
  }
};

export const getAnyTokenFromState = (state) => {
  const { localStore, session } = state;
  return {
    [authTokenKey]: localStore.token || session.token,
  };
};
export { baseWebSocketURL };
