import validator from "validator";
// first_name: "",
//     last_name: "",
//     phone: "",
//     email: "",
//     gender: "",
//     address_name: "",
//     addres_line_1: "",
//     addres_line_2: "",
//     addres_line_3: "",
//     city: "",
//     postal_code: "",
//     weight: "",
//     height: "",
//     dob: "",
export function ValidateInput(data) {
  console.log("DATA", data);
  let errors = {};

  if (data.user_name !== undefined && validator.isEmpty(data.user_name)) {
    errors.user_name = "User name  is required";
  }
  if (data.login_id !== undefined && validator.isEmpty(data.login_id)) {
    errors.login_id = "Login Id is required";
  }
  if (!validator.isEmpty(data.login_id)) {
    if (!validator.isEmail(data.login_id)) {
      errors.login_id = "Login Id is invalid";
    }
  }

  if (data.role !== undefined && validator.isEmpty(data.role)) {
    errors.role = "Role is required";
  }
  if (data.designation !== undefined && validator.isEmpty(data.designation)) {
    errors.designation = "Designation is required";
  }

  console.log("errors", errors);
  return errors;
}
