import React, { useRef } from "react";
import { Modal } from "antd";
import { ValidateInput } from "./ValidateIcons";
import { Formik } from "formik";
import { addNewBlog } from "../../../services/Blogs";
import swal from "sweetalert";
import { actions as iconsActions } from "../../../store/reducers/iconLibReducer";
import { useDispatch } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import { addNewIcon } from "../../../services/iconLib";

export default function AddNewBlog({ userModal, setAddUserModal }) {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const fileRef = useRef(null);

  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);
    try {
      let formData = new FormData();
      formData.set("name", values.icon_name);
      formData.set("image", values.icon_image);
      let response = await addNewIcon(formData);

      setAddUserModal();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await dispatch(iconsActions.onRequest({}));
      await setSubmitting(false);
      // await setAddUserModal();
      ResetForm();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };

  const ResetForm = () => {
    formikRef?.current?.resetForm({
      icon_name: "",
      icon_image: "",
    });
    fileRef.current.value = null;
  };
  return (
    <div>
      {" "}
      <Modal
        open={userModal}
        onCancel={() => {
          setAddUserModal();
          ResetForm();
        }}
        footer={false}
        width={700}
      >
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                icon_name: "",
                icon_image: "",
              }}
              innerRef={formikRef}
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                // setSubmitting(false);
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Add New Icon</h4>
                      {/* <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button> */}
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Icon Title
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="icon_name"
                                value={values.icon_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter icon title"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.icon_name}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Icon Image
                            </label>
                            <div className="contact-name">
                              <input
                                className="form-control"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                ref={fileRef}
                                // id="formFile"
                                // value={values && values.icon_image}
                                onChange={(e) => {
                                  setFieldValue(
                                    "icon_image",
                                    e.currentTarget.files[0]
                                  );
                                }}
                                // onBlur={handleBlur}
                                placeholder="Choose image"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.icon_image}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Adding..." : "Add"}
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setAddUserModal();
                          ResetForm();
                        }}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
