import React from "react";
import { Modal } from "react-bootstrap";
import { ValidateInputPackagesDetails } from "./ValidatePricingPackages";
import { Formik } from "formik";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { verifyObject } from "../../../../utilities/utils";
import {
  addPackagesPricing,
  updatePackagesPricing,
} from "../../../../services/packages";
import { actions as packagesActions } from "../../../../store/reducers/packagePricing";

export default function AddAdditionalInfo({
  userModal,
  setAddUserModal,
  editedData,
  packageId,
}) {
  const dispatch = useDispatch();
  const { local_package_id } = useSelector((state) => state.localStore);

  const handleSubmit = async (values, setSubmitting) => {
    console.log("packageId", packageId);
    try {
      let payload = {
        packageId: local_package_id,
        title: values.title,
        peoplePerRoom: values.peoplePerRoom,
        pricePerPerson: values.pricePerPerson,
        position: values.position,
      };
      let response = await addPackagesPricing(payload);
      console.log("response", response);
      dispatch(packagesActions.onRequest({ packageId: local_package_id }));
      setAddUserModal();
      await setSubmitting(false);
    } catch (e) {
      console.log("eeee", e);
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };

  const handleUpdates = async (values, setSubmitting) => {
    console.log("updated", packageId);
    try {
      let payload = {
        _id: editedData._id,
        packageId: local_package_id,
        title: values.title,
        peoplePerRoom: values.peoplePerRoom,
        pricePerPerson: values.pricePerPerson,
        position: values.position,
      };
      let response = await updatePackagesPricing(payload);
      console.log("response", response);
      dispatch(packagesActions.onRequest({ packageId: local_package_id }));
      setAddUserModal();
      await setSubmitting(false);
    } catch (e) {
      console.log("eeee", e);
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };

  let initialValues = {};
  if (editedData) {
    initialValues = {
      ...editedData,
    };
  } else {
    initialValues = {
      title: "",
      peoplePerRoom: "",
      pricePerPerson: "",
      position: "",
    };
  }
  return (
    <div>
      {" "}
      <Modal
        className="fade bd-example-modal-lg"
        size="lg"
        show={userModal}
        onHide={setAddUserModal}
      >
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                ...initialValues,
              }}
              validate={ValidateInputPackagesDetails}
              onSubmit={(values, { setSubmitting }) => {
                if (editedData) {
                  handleUpdates(values, setSubmitting);
                } else {
                  handleSubmit(values, setSubmitting);
                }
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                if (values) {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="modal-header">
                        <button
                          type="button"
                          className="btn-close"
                          onClick={setAddUserModal}
                          data-dismiss="modal"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <i className="flaticon-cancel-12 close"></i>
                        <div className="add-contact-box">
                          <div className="add-contact-content">
                            <div className="form-group mb-3">
                              <label className="text-black font-w500">
                                Title
                              </label>
                              <div className="contact-name">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  autoComplete="off"
                                  name="title"
                                  value={values.title}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter  title"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.title}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group mb-3">
                              <label className="text-black font-w500">
                                Position
                              </label>
                              <div className="contact-name">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  autoComplete="off"
                                  name="position"
                                  value={values.position}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter  position"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.position}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group mb-3">
                              <label className="text-black font-w500">
                                People Per Room
                              </label>
                              <div className="contact-name">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  autoComplete="off"
                                  name="peoplePerRoom"
                                  value={values.peoplePerRoom}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter  title"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.peoplePerRoom}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group mb-3">
                              <label className="text-black font-w500">
                                Price Per Person
                              </label>
                              <div className="contact-name">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  autoComplete="off"
                                  name="pricePerPerson"
                                  value={values.pricePerPerson}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter  title"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.pricePerPerson}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-secondary"
                          // onClick={handleAddFormSubmit}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                        <button
                          type="button"
                          onClick={setAddUserModal}
                          className="btn btn-danger"
                        >
                          {" "}
                          <i className="flaticon-delete-1"></i> Discard
                        </button>
                      </div>
                    </form>
                  );
                }
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
