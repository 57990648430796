import { post, get, deletee } from "./index";

export const getInquiriesList = (params) => {
  const request = {
    subUrl: `/inquiry`,
    params,
  };
  return get(request);
};

export const getPackageInquires = (params) => {
  const request = {
    subUrl: `/inquiry/getAllPackageInquiry`,
    params,
  };
  return get(request);
};

export const deleteInquiry = (id) => {
  const request = {
    subUrl: `/inquiry/${id}/Inquiry`,
  };
  return deletee(request);
};
export const assignInquiry = (data) => {
  const request = {
    subUrl: `/inquiry/assing-inquiry`,
    data,
  };
  return post(request);
};
export const deletePackage = (id) => {
  const request = {
    subUrl: `/inquiry/${id}/PackageInquiry`,
  };
  return deletee(request);
};
export const updatePackageInquryStatus = (data) => {
  const request = {
    subUrl: `/inquiry/updateInquiryStatus/${data._id}`,
    data,
  };
  delete data?._id;
  return post(request);
};

export const updatePackageInquryComment = (data) => {
  const request = {
    subUrl: `/inquiry/updateInquiryComment/${data._id}`,
    data,
  };
  delete data?._id;
  return post(request);
};

export const exportInquires = (data) => {
  const request = {
    subUrl: `/inquiry/export-inquiry`,
    data,
    exportXL: true,
  };
  return post(request);
};
