import React, { useRef } from "react";
import { Modal } from "antd";
import { ValidateInputAdditionalInfo as ValidateInput } from "./ValidatePackages";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { verifyObject } from "../../../../utilities/utils";
import {
  addAdditionalInfo,
  updateAdditionalInfo,
} from "../../../../services/packages";
import { actions as packagesActions } from "../../../../store/reducers/packagesAddInfoReducer";
import { TINY_MCE_API_KEY, TINY_MCE_EDITOR } from "../../../../constants/common";

export default function AddAdditionalInfo({
  userModal,
  setAddUserModal,
  editedData,
  packageId,
}) {
  const dispatch = useDispatch();
  // const [displayedImage, setDisplayImage] = useState(null);
  const { local_package_id } = useSelector((state) => state.localStore);
  const formikRef = useRef(null);

  const handleADDSubmit = async (values, setSubmitting) => {
    console.log("packageId", packageId);
    try {
      let payload = {
        tabTitle: values.tabTitle,
        description: values.description,
        packageId: local_package_id,
        position: values.position,
      };
      let response = await addAdditionalInfo(payload);
      console.log("response", response);
      dispatch(packagesActions.onRequest({ packageId: local_package_id }));
      setAddUserModal();
      await setSubmitting(false);
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };

  const handleUpdate = async (values, setSubmitting) => {
    console.log("handleUpdate", packageId);
    try {
      let payload = {
        _id: editedData._id,
        tabTitle: values.tabTitle,
        description: values.description,
        packageId: local_package_id,
        position: values.position,
      };
      let response = await updateAdditionalInfo(payload);
      console.log("response", response);
      dispatch(packagesActions.onRequest({ packageId: local_package_id }));
      setAddUserModal();
      await setSubmitting(false);
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };

  let initialValues = {};
  if (editedData) {
    initialValues = {
      ...editedData,
    };
  } else {
    initialValues = {
      tabTitle: "",
      description: "",
      position: "",
    };
  }

  const ResetForm = () => {
    formikRef?.current?.resetForm({
      tabTitle: "",
      description: "",
    });
  };
  return (
    <div>
      {" "}
      <Modal
        // className="fade bd-example-modal-lg "
        // size="lg"
        // show={userModal}
        // onHide={setAddUserModal}
        // style={{ width: "100%" }}
        open={userModal}
        onCancel={() => {
          setAddUserModal();
          ResetForm();
        }}
        footer={false}
        width={700}
      >
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                ...initialValues,
              }}
              innerRef={formikRef}
              enableReinitialize
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                // // setSubmitting(false);
                // if (editedData) {
                //   handleUpdate(values, setSubmitting);
                // } else {
                //   handleSubmit(values, setSubmitting);
                // }
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setSubmitting,
              }) => {
                if (values) {
                  console.log("errors", errors);

                  return (
                    <form
                    // onSubmit={(e) => {
                    //   if (editedData) {
                    //     handleUpdate(values, setSubmitting);
                    //   } else {
                    //     handleSubmit(values, setSubmitting);
                    //   }
                    // }}
                    >
                      <div className="modal-header">
                        {/* <button
                          type="button"
                          className="btn-close"
                          onClick={setAddUserModal}
                          data-dismiss="modal"
                        ></button> */}
                      </div>
                      <div className="modal-body">
                        <i className="flaticon-cancel-12 close"></i>
                        <div className="add-contact-box">
                          <div className="add-contact-content">
                            <div className="form-group mb-3">
                              <label className="text-black font-w500">
                                Title
                              </label>
                              <div className="contact-name">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  autoComplete="off"
                                  name="tabTitle"
                                  value={values.tabTitle}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter  title"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.tabTitle}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group mb-3">
                              <label className="text-black font-w500">
                                Position
                              </label>
                              <div className="contact-name">
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  autoComplete="off"
                                  name="position"
                                  value={values.position}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="Enter  position"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.position}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="form-group mb-3">
                              <label className="text-black font-w500">
                                Description
                              </label>
                              <div className="contact-name">
                                <Editor
                                  // initialValue="<p>This is the initial content of the editor</p>"
                                  value={values.description}
                                  init={TINY_MCE_EDITOR}
                                  onEditorChange={(e) => {
                                    setFieldValue("description", e);
                                  }}
                                  apiKey={TINY_MCE_API_KEY}
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.description}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          // type="submit"
                          className="btn btn-secondary"
                          //   onClick={handleAddFormSubmit}
                          onClick={(e) => {
                            e.preventDefault();
                            setSubmitting(true);
                            if (editedData) {
                              handleUpdate(values, setSubmitting);
                            } else {
                              handleADDSubmit(values, setSubmitting);
                            }
                          }}
                          disabled={
                            isSubmitting || Object.keys(errors).length !== 0
                          }
                        >
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setAddUserModal();
                            ResetForm();
                          }}
                          className="btn btn-danger"
                        >
                          {" "}
                          <i className="flaticon-delete-1"></i> Discard
                        </button>
                      </div>
                    </form>
                  );
                }
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
