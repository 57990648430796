import React, { Fragment } from "react";
// import { Paginate } from "components/common/index";
import Paginate from "./Paginate";
import "./Pagination.scss";

const Pagination = (props) => {
  const { pagination, onPageChange, handleLimitChange, limit } = props;
  console.log("limniy---->", props?.data);
  return (
    <Fragment>
      {pagination?.totalPages > 1 && (
        <div className="pagination-container">
          {handleLimitChange &&
            props.data?.length > 10 &&
            pagination.totalPages &&
            typeof handleLimitChange === "function" && (
              <select
                className="form-control form-control-sm pagination-dropdown"
                onChange={(e) => {
                  console.log("eeeeeeeeee", e.target.value);
                  handleLimitChange(e);
                  window.scroll(0, 0);
                }}
                name="limit"
                value={limit}
              >
                <option value={"10"}>Per Page 10</option>
                <option value={"20"}>Per Page 20</option>
                <option value={"30"}>Per Page 30</option>
                <option value={"40"}>Per Page 40</option>
                <option value={"50"}>Per Page 50</option>
              </select>
            )}
          {pagination.totalPages && props.data?.length > 0 ? (
            <Paginate
              pageCount={pagination.totalPages}
              onPageChange={onPageChange}
              page={
                pagination.nextPage
                  ? pagination.nextPage - 1
                  : pagination.totalPages
              }
            />
          ) : null}
        </div>
      )}
    </Fragment>
  );
};

export default Pagination;
