/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import swal from "sweetalert";
import { actions as packagesActions } from "../../../../store/reducers/packageReducer";
import { useDispatch, useSelector } from "react-redux";
import { verifyObject } from "../../../../utilities/utils";
import { copyPackage, deletePackage } from "../../../../services/packages";
import PackageListItem from "./PackageListItem";
import { Nav, Tab } from "react-bootstrap";

const PackagesList = () => {
  const { push } = useHistory();
  const [copying, setCopying] = useState();
  const dispatch = useDispatch();
  const [selectedTab, setTab] = useState("Hajj");
  const [filters, setFilters] = useState({
    umrahSubType: null,
  });
  const { data, isLoading, pagination } = useSelector(
    (state) => state.packages
  );
  const { permissions } = useSelector((state) => state?.localStore);

  const viewPermissions = permissions?.viewPermissions;
  const createPermissions = permissions?.createPermissions;
  const editPermissions = permissions?.editPermissions;
  const deletePermissions = permissions?.deletePermissions;

  const handleEditClick = (event, data) => {
    console.log("data", data);
    event.preventDefault();
    push(`/packages-details/${data._id}`);
  };

  const _getPackagesList = () => {
    dispatch(packagesActions.onRequest({ forType: selectedTab, ...filters }));
  };

  const onPageChange = (data) => {
    // const { customerId: user_id } = props.localStore;
    const { page } = data;

    dispatch(
      packagesActions.onRequest({ page, ...filters, forType: selectedTab })
    );
  };

  useEffect(() => {
    if (selectedTab) {
      _getPackagesList();
      window.scroll(0, 0);
    }
  }, [selectedTab, filters]);
  const handleUmrahSubTypeChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleCopyPackage = async (_id) => {
    try {
      setCopying(true);
      let copiedPackage = await copyPackage(_id);
      let copiedPkgId = copiedPackage?.data?.data?._id;
      // setPackageDetails(verifyObject(packageDetails, "data.data", null));
      if (copiedPkgId) {
        // successToast({
        //   content:
        //     "Your package was copied successfully, you can edit it here now",
        // });
        swal(
          "Package copied successfully",
          "Your package was copied successfully, you can edit it here now",
          "success"
        );

        push(`/packages-details/${copiedPkgId}`);
      }
      setCopying(false);

      // console.log("package details", copiedPackage);
    } catch (e) {
      setCopying(false);
      swal(
        "Package copying error",
        "Your package not copied due to some error",
        "error"
      );
    }
  };

  const DropdownBlog = (user) => {
    return (
      <>
        <Dropdown className="dropdown">
          <Dropdown.Toggle
            as="div"
            className="btn-link i-false"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu">
            {editPermissions.Packages && (
              <Dropdown.Item
                onClick={(e) => handleEditClick(e, user)}
                className="dropdown-item"
              >
                Edit
              </Dropdown.Item>
            )}
            {createPermissions.Packages && (
              <Dropdown.Item
                onClick={(e) => handleCopyPackage(user?._id)}
                className="dropdown-item"
                disabled={copying}
              >
                {copying ? "Copying..." : "Copy Package"}
              </Dropdown.Item>
            )}
            {deletePermissions.Packages && (
              <Dropdown.Item
                onClick={() => {
                  return swal({
                    title: "Are you sure?",
                    text: "Once deleted, you will not be able to recover this record!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      deleteRecord(user._id);
                    } else {
                      swal("Your record is safe!");
                    }
                  });
                }}
                className="dropdown-item"
              >
                Delete
              </Dropdown.Item>
            )}
            {editPermissions.Packages && (
              <Dropdown.Item
                onClick={(e) => push(`/packages-details/${user._id}`)}
                className="dropdown-item"
              >
                Show Details
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const deleteRecord = async (id) => {
    try {
      // await setLoading(true);
      let response = await deletePackage(id);
      console.log("Response roles", response);
      swal("Poof! Your record has been deleted!", {
        icon: "success",
        timer: 3000,
        buttons: false,
      });
      _getPackagesList();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      // await setLoading(false);
    }
  };

  return (
    <>
      <div
        id="scroll-id"
        className="d-flex justify-content-between align-items-center flex-wrap"
      >
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2 flex-wrap">
          <div className="inquiry-filters-container">
            {selectedTab === "Umrah" && (
              <div className="filter-item">
                <select
                  type="text"
                  className="form-control form-control-sm"
                  autoComplete="off"
                  name="umrahSubType"
                  value={filters.umrahSubType}
                  onChange={handleUmrahSubTypeChange}
                  // onBlur={handleBlur}
                >
                  <option selected disabled value={null}>
                    Select
                  </option>
                  <option value={"Umrah Full Package"}>Umrah Package</option>
                  <option value={"Umrah Land Package"}>
                    Land Umrah Package
                  </option>
                  <option value={"Umrah Group Package"}>
                    {" "}
                    Umrah Group Package
                  </option>
                </select>
              </div>
            )}
            {createPermissions?.Packages && (
              <div className="newest ms-3">
                <Link
                  to={"/packages-details"}
                  className="btn btn-primary font-w600 mb-2 me-auto"
                >
                  + New Package
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="profile-tab">
        <div className="custom-tab-1">
          <Tab.Container
            onSelect={(key) => {
              setTab(key);
              setFilters({ ...filters, umrahSubType: null });
            }}
            activeKey={selectedTab}
          >
            <Nav as="ul" className="nav nav-tabs">
              <Nav.Item as="li" className="nav-item">
                <Nav.Link to="#my-posts" eventKey={"Hajj"}>
                  Hajj Packages
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link to="#my-posts" eventKey={"Umrah"}>
                  Umrah Packages
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link to="#my-posts" eventKey={"ramadan_umrah"}>
                  Ramadan Umrah
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link to="#my-posts" eventKey={"Other"}>
                  Other Packages
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane id="my-posts" eventKey={"Hajj"}>
                <PackageListItem
                  DropdownBlog={DropdownBlog}
                  createPermissions={createPermissions}
                  deletePermissions={deletePermissions}
                  data={data}
                  editPermissions={editPermissions}
                  isLoading={isLoading}
                  onPageChange={onPageChange}
                  pagination={pagination}
                  forType={selectedTab}
                  _getPackagesList={_getPackagesList}
                />
              </Tab.Pane>
              <Tab.Pane id="my-posts" eventKey={"Umrah"}>
                <PackageListItem
                  DropdownBlog={DropdownBlog}
                  createPermissions={createPermissions}
                  deletePermissions={deletePermissions}
                  data={data}
                  editPermissions={editPermissions}
                  isLoading={isLoading}
                  onPageChange={onPageChange}
                  pagination={pagination}
                  forType={selectedTab}
                  _getPackagesList={_getPackagesList}
                />
              </Tab.Pane>
              <Tab.Pane id="my-posts" eventKey={"ramadan_umrah"}>
                <PackageListItem
                  DropdownBlog={DropdownBlog}
                  createPermissions={createPermissions}
                  deletePermissions={deletePermissions}
                  data={data}
                  editPermissions={editPermissions}
                  isLoading={isLoading}
                  onPageChange={onPageChange}
                  pagination={pagination}
                  forType={selectedTab}
                  _getPackagesList={_getPackagesList}
                />
              </Tab.Pane>
              <Tab.Pane id="my-posts" eventKey={"Other"}>
                <PackageListItem
                  DropdownBlog={DropdownBlog}
                  createPermissions={createPermissions}
                  deletePermissions={deletePermissions}
                  data={data}
                  editPermissions={editPermissions}
                  isLoading={isLoading}
                  onPageChange={onPageChange}
                  pagination={pagination}
                  forType={selectedTab}
                  _getPackagesList={_getPackagesList}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};
export default PackagesList;
