import validator from "validator";

export function ValidateInputAdditionalInfo(data) {
  let errors = {};
  console.log("data", data);

  if (data.tabTitle !== undefined && validator.isEmpty(data.tabTitle)) {
    errors.tabTitle = "Title  is required";
  }

  if (data.description !== undefined && validator.isEmpty(data.description)) {
    errors.description = "Description  is required";
  }
  if (data.position !== undefined && validator.isEmpty(data.position)) {
    errors.position = "Position  is required";
  }

  console.log("errors innn", errors);
  return errors;
}
