import { listingReducer } from "../reducers/utils/listingReducer";
import { getPackagesPricingList } from "../../services/packages";

const { reducer, actions, types } = listingReducer({
  name: `packagePricing`,
  getApi: getPackagesPricingList,
});
const packagePricing = reducer;

export { packagePricing, actions, types };
