import { getAllImages } from "../../services/ImageGallary";
import { listingReducer } from "./utils/listingReducer";

const { reducer, actions, types } = listingReducer({
  name: "imageGallary",
  getApi: getAllImages,
});

const imageGallary = reducer;
export { imageGallary, actions, types };
