import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
// import {}  from "react"

export default function RedirectComponent() {
  const { user } = useSelector((state) => state?.localStore);
  if (user?.role === "Staff") {
    return <Redirect to={"/inquiry-list"} />;
  } else {
    return <Redirect to="/" />;
  }
}
