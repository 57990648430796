import validator from "validator";

export function ValidateInput(data) {
  let errors = {};
  console.log("data", data);

  if (data.blog_title !== undefined && validator.isEmpty(data.blog_title)) {
    errors.blog_title = "Blog title  is required";
  }
  // if (data.blog_url !== undefined && validator.isEmpty(data.blog_url)) {
  //   errors.blog_url = "Blog URL  is required";
  // }
  if (data.blog_image === "") {
    errors.blog_image = "Blog Image  is required";
  }
  if (data.blog_status === "") {
    errors.blog_status = "Blog status  is required";
  }
  if (data.blog_description === "") {
    errors.blog_description = "Blog Description  is required";
  }
  if (data.slug === "") {
    errors.slug = "Blog slug  is required";
  }
  if (data.meta_title === "") {
    errors.meta_title = "Blog meta title  is required";
  }
  if (data.meta_desc === "") {
    errors.meta_desc = "Blog meta description  is required";
  }
  if (data.meta_keywords === "") {
    errors.meta_keywords = "Blog meta keywords  is required";
  }
  if (data.short_blog_description === "") {
    errors.short_blog_description = "Short Blog Description  is required";
  }
  // if (!validator.isEmpty(data.blog_url)) {
  //   if (!validator.isURL(data.blog_url)) {
  //     errors.blog_url = "URL  is invalid";
  //   }
  // }
  console.log("errors", errors);
  return errors;
}
