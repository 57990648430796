import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers";
import { loadState, saveState } from "../app/localStorage";
import { localStore } from "./reducers/localStore";
import { users } from "./reducers/usersReducer";
import { blogs } from "./reducers/BlogsReducer";
import { faqs } from "./reducers/faqReducer";
import { imageGallary } from "./reducers/imageGallaryReducer";
import { videoGallary } from "./reducers/videoGallaryReducer";
import { inquiries } from "./reducers/Inquiries";
import { packages } from "./reducers/packageReducer";
import { packagesAddInfo } from "./reducers/packagesAddInfoReducer";
import { packagePricing } from "./reducers/packagePricing";
import { packageInq } from "./reducers/packageInquiries";
import { reviews } from "./reducers/reviewsReducer";
import { transections } from "./reducers/transectionReducer";
import { termsConditions } from "./reducers/termsConditionsReducer";

import NetworkServices from "../utilities/network-service";
import { iconLib } from "./reducers/iconLibReducer";
import { bannerConfig } from "./reducers/BannerReducer";

//import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);
const persistedState = loadState();
const initialState = persistedState;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers = combineReducers({
  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  localStore: localStore,
  users: users,
  blogs: blogs,
  faqs: faqs,
  imageGallary: imageGallary,
  videoGallary: videoGallary,
  inquiries: inquiries,
  packages: packages,
  packagesAddInfo: packagesAddInfo,
  packagePricing: packagePricing,
  packageInq: packageInq,
  reviews: reviews,
  transections: transections,
  termsConditions: termsConditions,
  iconLib:iconLib,
  bannerConfig:bannerConfig
});

let tempState = {};
if (initialState) {
  tempState = initialState;
}
console.log("initialState",initialState)

export const store = createStore(reducers, composeEnhancers(middleware));
NetworkServices.setupInterceptors(store);

store.subscribe(() => {
  if (tempState !== store.getState().localStore) {
    saveState({
      localStore: store.getState().localStore,
    });
    tempState = store.getState().localStore;
  }
});
console.log("initialState", store.getState().localStore)

//const store = createStore(rootReducers);

export default store;
