import React, { useRef } from "react";
import { Modal, message } from "antd";
import { ValidateInput } from "./ValidateBlogs";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import { addNewBlog } from "../../../services/Blogs";
import swal from "sweetalert";
import { actions as blogsActions } from "../../../store/reducers/BlogsReducer";
import { useDispatch } from "react-redux";
import { errorToast, verifyObject } from "../../../utilities/utils";
import { TINY_MCE_API_KEY } from "../../../constants/common";
import { uploadSitemapFile } from "../../../services/sitemap";

export default function UploadSitemap({
  userModal,
  setAddUserModal,
  _getBlogList,
}) {
  // const dispatch = useDispatch();
  const formikRef = useRef();
  const fileRef = useRef();

  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);
    try {
      let formData = new FormData();
      formData.set("file", values.sitemap_file);

      let response = await uploadSitemapFile(formData);

      console.log("Response roles", response);
      await ResetForm();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await setSubmitting(false);

      // await setAddUserModal();
    } catch (e) {
      console.log("erroror", e);
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };
  const ResetForm = () => {
    formikRef?.current?.resetForm({
      blog_title: "",
      blog_url: "",
      blog_description: "",
      sitemap_file: "",
      blog_short_description: "",
      blog_status: "",
      slug: "",
    });
    fileRef.current.value = "";
  };
  return (
    <div>
      {" "}
      <div className="" role="document">
        <div className="">
          <Formik
            initialValues={{
              sitemap_file: "",
            }}
            innerRef={formikRef}
            validate={ValidateInput}
            onSubmit={(values, { setSubmitting }) => {
              console.log("values", values);
              handleSubmit(values, setSubmitting);
            }}
          >
            {({
              errors,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">Upload Sitemap</h4>
                  </div>
                  <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                      <div className="add-contact-content">
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Selet Site Map File
                          </label>
                          <div className="contact-name">
                            <input
                              className="form-control"
                              type="file"
                              accept=".xml"
                              ref={fileRef}
                              // id="formFile"
                              // value={values.sitemap_file}
                              onChange={(e) => {
                                console.log(
                                  "e.currentTarget.files[0]",
                                  e.target.files[0].name
                                );
                                if (
                                  e.target.files[0].name.split(".")[1] !== "xml"
                                ) {
                                  // errorToast({
                                  //   content: "Please select xml file",
                                  // });
                                  swal(
                                    "Input Error",
                                    "Please select xml file",
                                    "error"
                                  );

                                  e.target.value = null;
                                  return
                                } else if (
                                  e.target.files[0].name !== "sitemap.xml"
                                ) {
                                  errorToast({
                                    content:
                                      "Please select file name as sitemap",
                                  });
                                  swal(
                                    "Input Error",
                                    "Please select file name as sitemap",
                                    "error"
                                  );
                                  e.target.value = null;
                                  return

                                } else {
                                  setFieldValue(
                                    "sitemap_file",
                                    e.currentTarget.files[0]
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                              placeholder="Choose image"
                            />
                            {errors && (
                              <span
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.sitemap_file}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      //   onClick={handleAddFormSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Uploading..." : "Upload"}
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}
