import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal, Select } from "antd";
// import { ValidateInput } from "./ValidateBlogs";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import { addNewBlog } from "../../../services/Blogs";
import swal from "sweetalert";
import { actions as blogsActions } from "../../../store/reducers/BlogsReducer";
import { useDispatch } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import moment from "moment";
import { DatePicker } from "antd";
import { isEmpty } from "lodash";
import { saveAs } from "file-saver";

import { assignInquiry, exportInquires } from "../../../services/inquiries";
import { getUsers } from "../../../services/Users";
const { RangePicker } = DatePicker;

export default function AssigneInquiryModal({
  showModal,
  setShowModal,
  inqDetails,
  u_data,
}) {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [state, setState] = useState({});

  useEffect(() => {
    _getUsersList();
  }, []);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const formikRef = useRef(null);

  const handleSubmit = async (values, setSubmitting) => {
    try {
      let payload = {
        userId: u_data?.userId,
        inquiryId: inqDetails._id,
        inquiryType: 1,
      };
      // await setLoading(t rue);
      let response = await assignInquiry(payload);
      console.log("Response roles", response);
      swal("Success", response?.data.message, "success");

      //   await exportInquires(payload);
      await setSubmitting(false);
      await handleClose();
    } catch (e) {
      if (e?.response) {
        console.log("e", e?.response);
        let errorMessage = verifyObject(
          e,
          "response.data.message",
          "Something went wrong"
        );
        swal("Server Error", errorMessage, "error");
      }
      await setSubmitting(false);
    }
  };

  const handleSelectChange = (dropValues) => {
    console.log("dropValues", dropValues);
    setSelectedTypes(dropValues);
  };

  const handleClose = (e) => {
    setShowModal();
    formikRef.current.resetForm({
      inquiry_type: "",
    });
    setDateRange({
      startDate: null,
      endDate: null,
    });
  };
  const _getUsersList = async () => {
    try {
      const response = await getUsers();
      console.log("users", response);
      const users = response?.data?.data?.docs;
      // setStatus(e.target.value)
      setState({ ...state, users: users });
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );

      swal("Server Error", errorMessage, "error");
    }
  };
  console.log("Inq Details", inqDetails);
  return (
    <div>
      {" "}
      <Modal open={showModal} onCancel={handleClose} footer={false} width={700}>
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                assigne_user_id: "",
              }}
              //   validate={ValidateInput}
              enableReinitialize
              innerRef={formikRef}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                // setSubmitting(false);
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Assign Inquiry</h4>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <div className="form-group mb-3">
                              <label className="text-black font-w500">
                                Users
                              </label>
                              <div className="contact-name">
                                <select
                                  type="text"
                                  className="form-control form-control-sm"
                                  autoComplete="off"
                                  name="assigne_user_id"
                                  value={values.assigne_user_id}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                >
                                  <option selected disabled value={""}>
                                    Select user to be assigned
                                  </option>
                                  {state?.users?.map((u) => {
                                    return (
                                      <option value={u._id}>
                                        {u?.fullName}
                                      </option>
                                    );
                                  })}
                                </select>
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.inquiry_type}
                                  </span>
                                )}
                              </div>
                            </div>
                            {!isEmpty(values.inquiry_type) && (
                              <div className="contact-name">
                                <label className="text-black font-w500">
                                  Sub Type
                                </label>
                                <Select
                                  type="text"
                                  className="form-control form-control-sm"
                                  autoComplete="off"
                                  name="sub_inquiry_type"
                                  value={values.sub_inquiry_type}
                                  onChange={handleSelectChange}
                                  //   onBlur={handleBlur}
                                  placeholder="Select Inquiry Sub Type"
                                  mode="multiple"
                                >
                                  {values.inquiry_type === "1" && (
                                    <Fragment>
                                      <Select.Option value="Flights">
                                        Flights
                                      </Select.Option>
                                      <Select.Option value="flightAndhotel">
                                        Flights & Hotels
                                      </Select.Option>
                                      <Select.Option value="evisa">
                                        eVisa
                                      </Select.Option>
                                      <Select.Option value="HajjInquiry">
                                        Hajj
                                      </Select.Option>
                                    </Fragment>
                                  )}
                                  {values.inquiry_type === "2" && (
                                    <Fragment>
                                      <Select.Option value="Hajj">
                                        Hajj Packages
                                      </Select.Option>
                                      <Select.Option value="Umrah">
                                        Umrah Packages
                                      </Select.Option>
                                    </Fragment>
                                  )}
                                </Select>
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.inquiry_type}
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting || values.assigne_user_id === ""}
                      >
                        {isSubmitting ? "Exporting..." : "Assign"}
                      </button>
                      <button
                        type="button"
                        onClick={handleClose}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
