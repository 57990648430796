import React from "react";

export default function TableLoader() {
  return (
    <div class="text-center table-loader">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );
}
