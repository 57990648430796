import { post, patch, get, deletee } from "./index";

export const addNewBlog = (data) => {
  const request = {
    subUrl: `/blog`,
    data,
  };
  return post(request);
};

export const getBlogList = (params) => {
  const request = {
    subUrl: `/blog`,
    params,
  };
  return get(request);
};

export const updateBlog = (data, id) => {
  const request = {
    subUrl: `/blog/${id}`,
    data,
  };
  return patch(request);
};

export const deleteBlog = (id) => {
  const request = {
    subUrl: `/blog/${id}`,
  };
  return deletee(request);
};
export const getBlogsByID = (id) => {
  const request = {
    subUrl: `/blog/${id}`,
  };
  return get(request);
};
