import React, { Component } from "react";
// import { Button } from "antd";
// import { Player } from "video-react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";
// import "video-react/dist/video-react.css";

export default class VideoPlayerModal extends Component {
  state = { visible: false, playing: false };

  showModal = () => {
    this.setState({
      visible: true,
      playing: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
      playing: false,
    });
  };

  render() {
    console.log(this.state.playing);
    let { d } = this.props;

    return (
      <>
        <button className="btn btn-primary" onClick={this.showModal}>
          Open Video
        </button>
        <Modal
          show={this.state.visible}
          onHide={this.hideModal}
          centered
          size="lg"
          className="deal-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {<span dangerouslySetInnerHTML={{ __html: d.description }} />}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 modal-body">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 528,
              }}
              className="modal-body-bottom"
            >
              <ReactPlayer
                ref={(ref) => {
                  this.player = ref;
                }}
                playing={this.state.playing}
                controls={true}
                url={d.video}
                width={700}
                height={400}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/* <Modal
          title={<span dangerouslySetInnerHTML={{ __html: d.description }} />}
          visible={this.state.visible}
          footer={null}
          onCancel={this.hideModal}
          // afterClose={() => this.setState({ playing: false })}
          // bodyStyle={{ padding: 0 }}
        >
         
        </Modal> */}
      </>
    );
  }
}
