/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link,withRouter } from "react-router-dom"

const PageTitle = ({ motherMenu, activeMenu, pageContent, to, history }) => {
//   const navigate = useNavigate()

  return (
    <div className="row page-titles mx-0">
      <div className="col-sm-6 p-md-0">
	  <ol className="breadcrumb">
        <li className="breadcrumb-item active">
          <Link to={to}>{motherMenu}</Link>
        </li>
        <li className="breadcrumb-item  ">{activeMenu}</li>
      </ol>
		  </div>
		  <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
			<ol className="breadcrumb">
			  <li className="breadcrumb-item">
				<a className="cursor-pointer" onClick={history?.goBack}   >Back</a>
			  </li>
			
			</ol>
		  </div>
   
    </div>
  );
};

export default withRouter(PageTitle);
