import { post, patch, get, deletee } from "./index";

// Packages Details
export const getPackagesList = (params) => {
  const request = {
    subUrl: `/packages`,
    params,
  };
  return get(request);
};

export const getPackageDetail = (_id) => {
  const request = {
    subUrl: `/packages/${_id}`,
  };
  return get(request);
};

export const updatePackageDetail = (data, _id) => {
  const request = {
    subUrl: `/packages/${_id}`,
    data,
  };
  return patch(request);
};

export const addPackageDetails = (data) => {
  const request = {
    subUrl: `/packages`,
    data,
  };
  return post(request);
};

export const deletePackage = (id) => {
  const request = {
    subUrl: `/packages/${id}`,
  };
  return deletee(request);
};

export const copyPackage = (id) => {
  const request = {
    subUrl: `/packages/copy-package/${id}`,
  };
  return post(request);
};

// ---------------------------------------------------------------------------------------------------------------------------------

// Packages Additional Info Details
export const getAdditionalInfoList = (params) => {
  const request = {
    subUrl: `/packagesAdditionalInfo`,
    params,
  };
  return get(request);
};

export const addAdditionalInfo = (data) => {
  const request = {
    subUrl: `/packagesAdditionalInfo`,
    data,
  };
  return post(request);
};

export const deleteAdditionalInfo = (id) => {
  const request = {
    subUrl: `/packagesAdditionalInfo/${id}`,
  };
  return deletee(request);
};

export const updateAdditionalInfo = (data) => {
  const request = {
    subUrl: `/packagesAdditionalInfo/${data._id}`,
    data,
  };
  return patch(request);
};

// ---------------------------------------------------------------------------------------------------------------------------------

// Packages Pricing Info Details

export const getPackagesPricingList = (params) => {
  const request = {
    subUrl: `/packagesPricing`,
    params,
  };
  return get(request);
};

export const addPackagesPricing = (data) => {
  const request = {
    subUrl: `/packagesPricing`,
    data,
  };
  return post(request);
};

export const deletePackagesPricing = (id) => {
  const request = {
    subUrl: `/packagesPricing/${id}`,
  };
  return deletee(request);
};

export const updatePackagesPricing = (data) => {
  const request = {
    subUrl: `/packagesPricing/${data._id}`,
    data,
  };
  return patch(request);
};
