/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ValidateInput } from "./ValidateRoles";
import { Formik } from "formik";
import { updateRole } from "../../../services/Roles";
import swal from "sweetalert";
import { verifyObject } from "../../../utilities/utils";
import { allPermissions } from "../../../constants/common";

export default function EditRole({
  userModal,
  setAddUserModal,
  newEditedData,
  _getRoleList,
  _resetData,
}) {
  let editedPermissions = [];

  const [state, setState] = useState({
    allPermissionsState: allPermissions,
  });

  useEffect(() => {
    if (newEditedData && userModal) {
      newEditedData?.permissions?.map((_p) => {
        editedPermissions.push({
          title: _p.title,
          actions: _p.actions,
        });
      });
      console.log("editedData| allPermissionsState", editedPermissions);
      setState({ ...state, allPermissionsState: editedPermissions });
    }
  }, [newEditedData]);
  // const handlePermissionCheckbox = (e, permission, title, index) => {
  //   if (e.target.checked) {
  //     setState((state) => {
  //       let allPermissions = state.allPermissionsState;
  //       allPermissions[index].title = title;
  //       Object.keys(allPermissions[index]?.actions).map((key) => {
  //         if (key === permission) {
  //           allPermissions[index].actions[key] = true;
  //         }
  //         return null;
  //       });
  //       return {
  //         ...state,
  //         allPermissionsState: allPermissions,
  //         errors: {
  //           ...state.errors,
  //           permissions: null,
  //         },
  //       };
  //     });
  //   } else {
  //     setState((state) => {
  //       let allPermissions = state.allPermissionsState;
  //       allPermissions[index].title = title;
  //       allPermissions[index].chekedpermissions =
  //         allPermissions[index].chekedpermissions &&
  //         allPermissions[index].chekedpermissions.length > 0
  //           ? allPermissions[index].chekedpermissions.filter(
  //               (d) => d !== permission
  //             )
  //           : [];
  //       return { ...state, allPermissionsState: allPermissions };
  //     });
  //   }
  // };

  const handlePermissionCheckbox = (e, permission, title, pindex) => {
    setState((state) => {
      let allPermissions = state.allPermissionsState;
      allPermissions[pindex].title = title;
      Object.keys(allPermissions[pindex]?.actions).map((key, index) => {
        if (key === permission) {
          allPermissions[pindex].actions[key] = e.target.checked;
        }
        return null;
      });
      console.log("allPermissions", allPermissions);
      return {
        ...state,
        allPermissionsState: allPermissions,
        errors: {
          ...state.errors,
          permissions: null,
        },
      };
    });
    // if (e.target.checked) {
    //  ;
    // } else {
    //   setState((state) => {
    //     let allPermissions = state.allPermissionsState;
    //     allPermissions[pindex].title = title;
    //     allPermissions[pindex].chekedpermissions =
    //       allPermissions[pindex].chekedpermissions &&
    //       allPermissions[pindex].chekedpermissions.length > 0
    //         ? allPermissions[pindex].actions.filter(
    //             (d) => d !== permission
    //           )
    //         : [];
    //     return { ...state, allPermissionsState: allPermissions };
    //   });
    // }
  };
  const handleSubmit = async (values, setSubmitting) => {
    console.log("state.newEditedData", newEditedData);
    console.log("state.values", values);

    try {
      let response = await updateRole({
        name: values.role_name,
        _id: newEditedData.id,
        permissions: state.allPermissionsState,
      });

      console.log("Response roles", response);
      // setUserArray(response.data.data.docs);
      // this.setState()
      setAddUserModal();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await setSubmitting(false);
      await _getRoleList();
      // await setAddUserModal();
    } catch (e) {
      console.log("Response errr", e);

      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");

      await setSubmitting(false);
    }
  };
  return (
    <div>
      {" "}
      <Modal
        className="modal fade"
        show={userModal}
        onHide={() => {
          // if (newEditedData) {
          //   newEditedData?.permission?.map((_p) => {
          //     editedPermissions.push({
          //       title: _p.title,
          //       actions: _p.actions,
          //     });
          //   });
          //   console.log("editedData| allPermissionsState", editedPermissions);

          //   setState({ ...state, allPermissionsState: allPermissions });
          //   // _getRoleList();
          // }
          setAddUserModal();
          _resetData();
        }}
      >
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                role_name: newEditedData && newEditedData.role_name,
              }}
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting);
              }}
              // enableReinitialize
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Update Role</h4>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">Name</label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="role_name"
                                value={values.role_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter role name"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.role_name}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="add-contact-content">
                          <label className="text-black font-w500">
                            Permissions
                          </label>
                          {state?.allPermissionsState.map((d, pindex) => {
                            return (
                              <>
                                <div className="d-flex checkpermission flex-column">
                                  <p className="labeltitle">{d.title}</p>
                                  <div className="checkb">
                                    {Object.keys(d.actions).map(
                                      (permission) => {
                                        return (
                                          <div className="form-check custom-checkbox mb-3 checkbox-danger">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id={`customCheckBox${pindex}`}
                                              checked={d.actions[permission]}
                                              onChange={(e) =>
                                                handlePermissionCheckbox(
                                                  e,
                                                  permission,
                                                  d.title,
                                                  pindex
                                                )
                                              }
                                            />
                                            <label
                                              className="form-check-label text-black font-w500"
                                              htmlFor={`customCheckBox${pindex}`}
                                            >
                                              {permission}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                <hr />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setAddUserModal();
                          _resetData();
                        }}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
