import validator from "validator";

export function ValidateInputPackagesDetails(data) {
  let errors = {};

  if (data.title !== undefined && validator.isEmpty(data.title)) {
    errors.title = "title  is required";
  }
  if (data.forType !== undefined && validator.isEmpty(data.forType)) {
    errors.forType = "Package For  is required";
  }
  if (data.packageIcon !== undefined && data?.packageIcon?.length === 0) {
    errors.packageIcon = "Package Icon  is required";
  }
  if (data.meta_title === "") {
    errors.meta_title = "Package meta title  is required";
  }
  if (data.meta_desc === "") {
    errors.meta_desc = "Package meta description  is required";
  }
  if (data.meta_keywords === "") {
    errors.meta_keywords = "Package meta keywords  is required";
  }
  if (data.forType === "Umrah") {
    if (
      data.umrahSubType !== undefined &&
      validator.isEmpty(data.umrahSubType)
    ) {
      errors.umrahSubType = "Create Umrah Sub Type  is required";
    }
  }

  if (
    data.position !== undefined &&
    validator.isEmpty(data.position?.toString())
  ) {
    errors.position = "Position is required";
  }

  if (
    data.shortDescription !== undefined &&
    validator.isEmpty(data.shortDescription)
  ) {
    errors.shortDescription = "Short Description is required";
  }

  // if (data.englishDate !== undefined && validator.isEmpty(data.englishDate)) {
  //   errors.englishDate = "Date is required";
  // }

  // if (data.noOfDays !== undefined && validator.isEmpty(data.noOfDays)) {
  //   errors.noOfDays = "No of days is required";
  // }

  if (data.price !== undefined && validator.isEmpty(data.price)) {
    errors.price = "Price is required";
  }

  if (data.priceCaption !== undefined && validator.isEmpty(data.priceCaption)) {
    errors.priceCaption = "Price caption is required";
  }
  // if (data.packageType !== undefined && validator.isEmpty(data.packageType)) {
  //   errors.packageType = "Package Type is required";
  // }

  if (data.packageListingImage === "") {
    errors.packageListingImage = "Package listing image  is required";
  }
  if (data.packageHeaderImage === "") {
    errors.packageHeaderImage = "Package detail header image  is required";
  }
  return errors;
}
