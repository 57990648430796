import { post, patch, get, deletee } from "./index";

export const addNewReview = (data) => {
  const request = {
    subUrl: `/reviews`,
    data,
  };
  return post(request);
};

export const getReviewList = (params) => {
  const request = {
    subUrl: `/reviews`,
    params,
  };
  return get(request);
};

export const updateReview = (data, id) => {
  const request = {
    subUrl: `/reviews/${id}`,
    data,
  };
  return patch(request);
};

export const deleteReview = (id) => {
  const request = {
    subUrl: `/reviews/${id}`,
  };
  return deletee(request);
};
export const getReviewsByID = (id) => {
  const request = {
    subUrl: `/reviews/${id}`,
  };
  return get(request);
};
