import axios from "axios";
// import { push } from "react-router-redux";

// import { navigateToIndex } from "../app/navigation";
import Appstore from "../store/store";
import { errorToast } from "./utils";

const interCeptors = {
  setupInterceptors: (store, history) => {
    // Add a response interceptor
    return axios.interceptors.response.use(
      (response) => {
        // do something with the response data

        const { data } = response;
        console.log("daaaaaaa", data);
        if (data && (data.status === 950 || data.status === 419)) {
          return response;
        }
        if (data && data.status === 304) {
          errorToast({ content: data.message || "Error" });
        }
        return response;
      },
      (error) => {
        console.log("error", error.response);
        if (
          error?.response?.data === "Unauthorized" ||
          error?.response?.status === 401
        ) {
          localStorage.clear();
          Appstore.dispatch({ type: "CLEAR_LOCAL_STATE" });
          errorToast({ content: error?.response?.data || "Error" });
          window.location.href = "/";
        }

        if (error.message === "Network Error") {
        }
        if (error?.response) {
          return error;
        }
      }
    );
  },
};

export default interCeptors;
