import { toast, cssTransition } from "react-toastify";
import { responseHeaderKeys } from "../services/index";
import "react-toastify/dist/ReactToastify.css";

import moment from "moment";
// import {orderTypes} from "../services/orders";
import {
  DATE_FORMAT,
  DATE_FORMAT_12_HR,
  T12_HR_FORMAT,
} from "../constants/common";
import { get } from "lodash";

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  duration: 300,
});

const commonDateFormats = {
  input: {
    dateAndTime: "",
    date: "YYYY-MM-DD",
    time: "",
  },
  output: {
    dateAndTime: DATE_FORMAT_12_HR,
    date: DATE_FORMAT,
    time: T12_HR_FORMAT,
  },
};

export const dateFormats = {
  common: commonDateFormats,
  customers: {
    input: {
      created_at: "",
      date_of_birth: "YYYY-MM-DD",
    },
    output: {
      created_at: commonDateFormats.output.dateAndTime,
      date_of_birth: commonDateFormats.output.date,
    },
  },
};

type IFormatDateParams = {
  date: string,
  format: {
    input: string,
    output: string,
  },
};

export const formatDate = (params: IFormatDateParams) => {
  const { date, format } = params;
  if (!date) {
    return null;
  }

  return moment(date, format.input).format(format.output);
};

export const formatFullDateTime = ({ date }) => {
  if (!date) {
    return null;
  }
  return formatDate({
    date,
    format: {
      input: commonDateFormats.input.dateAndTime,
      output: commonDateFormats.output.dateAndTime,
    },
  });
};

export const formatJustDate = ({ date }) => {
  if (!date) {
    return null;
  }
  return formatDate({
    date,
    format: {
      input: commonDateFormats.input.date,
      output: commonDateFormats.output.date,
    },
  });
};

export const getErrorObject = (error) => {
  let message = "Error!";
  // console.log(error);
  if (error && error.data && error.data.message) {
    // API server error message
    message = error.data.message;
  } else if (error && error.message) {
    // js error message
    message = error.message;
  }
  return {
    message,
  };
};

export const errorToast = ({ content, options = {} }) => {
  if (!content) {
    return;
  }
  return toast(content, {
    transition: Zoom,
    type: "error",
    containerId: options.containerId ? options.containerId : "container_main",
    ...options,
  });
};

export const successToast = ({ content, options = {} }) => {
  if (!content) {
    return;
  }
  return toast(content, {
    transition: Zoom,
    type: "success",
    containerId: options.containerId ? options.containerId : "container_main",
    ...options,
  });
};

export const infoToast = ({ content, options = {} }) => {
  if (!content) {
    return;
  }
  return toast(content, {
    type: "info",
    ...options,
  });
};

export const warningToast = ({ content, options = {} }) => {
  if (!content) {
    return;
  }
  return toast(content, {
    type: "warning",
    ...options,
  });
};

export const normalize = ({ data, key = "id" }) => {
  let obj = {};
  let ids = [];
  data.length > 0 &&
    data.forEach((element, i) => {
      if (element[key]) {
        obj[element[key]] = element;
        ids.push(element[key]);
      } else {
        obj[i] = element;
        ids.push(i);
      }
    });
  return { data: obj, ids };
};
export const generateParamsURL = (data) => {
  return "?q=" + JSON.stringify(data);
};

export const customToast = ({ content, type, options = {} }) => {
  if (!content) {
    return;
  }
  return toast(content, {
    transition: Zoom,
    type: type,
    containerId: options.containerId ? options.containerId : "container_main",
    ...options,
  });
};
export const normalizeByGroup = ({ data, key, groupKey }) => {
  let obj = {};
  let ids = [];
  let group = {};
  let groupIds = new Set();
  data.forEach((element) => {
    obj[element[key]] = element;
    groupIds.add(element[groupKey]);
    if (!group[element[groupKey]]) {
      group[element[groupKey]] = [];
    }
    group[element[groupKey]].push(element[key]);
    ids.push(element[key]);
  });
  return { data: obj, ids, groupIds: [...groupIds], group };
};

export const currencies = {
  inr: { key: "inr", symbol: "₹" },
  euro: { key: "euro", symbol: "€" },
  pound: { key: "pound", symbol: "£" },
};

export const normalizeResponseWithPagination = ({ response, key = "id" }) => {
  const dataArr = response.data.data;
  console.log("________________________________________", dataArr);

  //Current workaround
  let resPagination = response.headers[responseHeaderKeys.pagination];
  if (resPagination) {
    resPagination = JSON.parse(resPagination);
  } else {
    resPagination = {};
  }

  // console.log("resPagination", resPagination);
  const data = verifyObject(response, "data.data.docs", []);

  // console.log("IN PAGINATION", data, ids);
  const pagination = dataArr;
  delete pagination["docs"];

  return {
    data,
    pagination: pagination,
  };
};
let pubNubCr = {};
if (process.env.REACT_APP_ENV === "prod") {
  pubNubCr.publishKey = "pub-c-6d2a67ad-f642-4ab1-948b-e4c4cd2b7087";
  pubNubCr.subscribeKey = "sub-c-f6dcaefe-c0c4-11e8-91cd-c6d399a24064";
} else {
  pubNubCr.publishKey = "pub-c-0817e702-1f50-49bd-b0e3-3473128601f5";
  pubNubCr.subscribeKey = "sub-c-17b49a9a-57fe-11e8-9796-063929a21258";
}

export const getExtraDataFromListing = (response) => {
  const responseData = response.data;
  const extraData = {};
  for (const key in responseData) {
    if (key !== "data") {
      extraData[key] = responseData[key];
    }
  }
  return extraData;
};

export const verifyObject = (obj, param, defaultValue) => {
  let data = get(obj, param, defaultValue);
  return data;
};
export { pubNubCr };
export const addModalClassToBody = () => {
  let body = document.querySelector("body");
  body.classList.add("modal-open");
};

export const removeModalClassFromBody = () => {
  let body = document.querySelector("body");
  body.classList.remove("modal-open");
};

export const formatPhoneNumber = (phoneNumberString, isOmmitCountryCode) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? (isOmmitCountryCode ? "" : "+1 ") : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};
