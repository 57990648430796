import { getReviewList } from "../../services/Reviews";
import { listingReducer } from "../../store/reducers/utils/listingReducer";

const { reducer, actions, types } = listingReducer({
  name: "reviews",
  getApi: getReviewList,
});

const reviews = reducer;
export { reviews, actions, types };
