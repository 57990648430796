export const TIME_ZONE = Math.abs(new Date().getTimezoneOffset() * 60);
export const spaceRegex = /^(?=.*\S).+$/;

export const DATE_FORMAT = "DD-MM-YYYY";
export const T12_HR_FORMAT = "hh:mm A";
export const T24_HR_FORMAT = "HH:mm";
export const DATE_FORMAT_12_HR = "DD-MM-YYYY hh:mm A";
export const DATE_FORMAT_24_HR = "DD-MM-YYYY HH:mm";
export const DATE_FORMAT_12_HR_S = "DD-MM-YYYY hh:mm:ss A";
export const IMAGE_URL = "https://chicago-haj.herokuapp.com/";

export const FLIGHT_INQ_TYPE = "flight";
export const FLIGHT_HOTEL_INQ_TYPE = "flightAndhotel";
export const EVISA_INQ_TYPE = "evisa";

export const TINY_MCE_API_KEY="ghc0txz4uulcngx94dwy853v6ygpiquo13ed5pv3m9071wqp"
export const TINY_MCE_EDITOR = {
  height: 600,
  menubar: "file edit view insert format tools table tc help",
  plugins:
    "print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars linkchecker emoticons",
  mobile: {
    plugins:
      "print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars linkchecker emoticons",
  },
  menu: {
    tc: {
      title: "Comments",
      items: "addcomment showcomments deleteallconversations",
    },
  },
  toolbar:
    "undo redo | bold italic underline strikethrough |  fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
  autosave_ask_before_unload: true,
  autosave_interval: "30s",
  autosave_prefix: "{path}{query}-{id}-",
  autosave_restore_when_empty: false,
  autosave_retention: "2m",
  image_advtab: true,
  link_list: [
    {
      title: "My page 1",
      value: "https://www.tiny.cloud",
    },
    {
      title: "My page 2",
      value: "http://www.moxiecode.com",
    },
  ],
  image_list: [
    {
      title: "My page 1",
      value: "https://www.tiny.cloud",
    },
    {
      title: "My page 2",
      value: "http://www.moxiecode.com",
    },
  ],
  image_class_list: [
    { title: "None", value: "" },
    {
      title: "Some class",
      value: "class-name",
    },
  ],
  importcss_append: true,
  templates: [
    {
      title: "New Table",
      description: "creates a new table",
      content:
        '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
    },
    {
      title: "Starting my story",
      description: "A cure for writers block",
      content: "Once upon a time...",
    },
    {
      title: "New list with dates",
      description: "New List with dates",
      content:
        '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
    },
  ],
  template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
  template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
  image_caption: true,
  quickbars_selection_toolbar:
    "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
  noneditable_noneditable_class: "mceNonEditable",
  toolbar_mode: "sliding",
  spellchecker_ignore_list: ["Ephox", "Moxiecode"],
  // tinycomments_mode: "embedded",
  content_style: ".mymention{ color: gray; }",
  contextmenu: "link image imagetools table configurepermanentpen",
  a11y_advanced_options: true,
};

export const allPermissions = [
  // {
  //   title: "Users",
  //   actions: {
  //     view: false,
  //     edit: false,
  //     delete: false,
  //     create: false,
  //   },
  // },
  // {
  //   title: "Packages",
  //   actions: {
  //     view: false,
  //     edit: false,
  //     delete: false,
  //     create: false,
  //   },
  // },
  {
    title: "Inquiries",
    actions: {
      view: false,
      delete: false,
      edit: false,
    },
  },
  {
    title: "Transactions",
    actions: {
      view: false,
      // edit: false,
      // delete: false,
      // create: false,
    },
  },
  {
    title: "Media",
    actions: {
      view: false,
      edit: false,
      delete: false,
      create: false,
    },
  },
  {
    title: "Reviews",
    actions: {
      view: false,
      edit: false,
      delete: false,
      create: false,
    },
  },
  {
    title: "FAQs",
    actions: {
      view: false,
      edit: false,
      delete: false,
      create: false,
    },
  },
  {
    title: "Blogs",
    actions: {
      view: false,
      edit: false,
      delete: false,
      create: false,
    },
  },
  {
    title: "Roles",
    actions: {
      view: false,
      edit: false,
      delete: false,
      create: false,
    },
  },
  {
    title: "Terms",
    actions: {
      view: false,
      edit: false,
    },
  },
  {
    title: "Privacy",
    actions: {
      view: false,
      edit: false,
    },
  },
  {
    title: "Icons",
    actions: {
      view: false,
      edit: false,
      delete: false,
      create: false,
    },
  },
];

export const allPermissions1 = [
  // {
  //   title: "Users",
  //   actions: {
  //     view: false,
  //     edit: false,
  //     delete: false,
  //     create: false,
  //   },
  // },
  // {
  //   title: "Packages",
  //   actions: {
  //     view: false,
  //     edit: false,
  //     delete: false,
  //     create: false,
  //   },
  // },
  {
    title: "Inquiries",
    actions: {
      view: false,
      delete: false,
      edit: false,
    },
  },
  {
    title: "Transactions",
    actions: {
      view: false,
      // edit: false,
      // delete: false,
      // create: false,
    },
  },
  {
    title: "Media",
    actions: {
      view: false,
      edit: false,
      delete: false,
      create: false,
    },
  },
  {
    title: "Reviews",
    actions: {
      view: false,
      edit: false,
      delete: false,
      create: false,
    },
  },
  {
    title: "FAQs",
    actions: {
      view: false,
      edit: false,
      delete: false,
      create: false,
    },
  },
  {
    title: "Blogs",
    actions: {
      view: false,
      edit: false,
      delete: false,
      create: false,
    },
  },
  {
    title: "Roles",
    actions: {
      view: false,
      edit: false,
      delete: false,
      create: false,
    },
  },
  {
    title: "Terms",
    actions: {
      view: false,
      edit: false,
    },
  },
  {
    title: "Privacy",
    actions: {
      view: false,
      edit: false,
    },
  },
  {
    title: "Icons",
    actions: {
      view: false,
      edit: false,
      delete: false,
      create: false,
    },
  },
];
export const adminPermissions = [
  {
    title: "Users",
    actions: {
      view: true,
      edit: true,
      delete: true,
      create: true,
    },
  },
  {
    title: "Packages",
    actions: {
      view: true,
      edit: true,
      delete: true,
      create: true,
    },
  },
  {
    title: "Inquiries",
    actions: {
      view: true,
      delete: true,
      edit: true,
    },
  },
  {
    title: "Transactions",
    actions: {
      view: true,
      // edit: false,
      // delete: false,
      // create: false,
    },
  },
  {
    title: "Media",
    actions: {
      view: true,
      edit: true,
      delete: true,
      create: true,
    },
  },
  {
    title: "Reviews",
    actions: {
      view: true,
      edit: true,
      delete: true,
      create: true,
    },
  },
  {
    title: "FAQs",
    actions: {
      view: true,
      edit: true,
      delete: true,
      create: true,
    },
  },
  {
    title: "Blogs",
    actions: {
      view: true,
      edit: true,
      delete: true,
      create: true,
    },
  },
  {
    title: "Roles",
    actions: {
      view: true,
      edit: true,
      delete: true,
      create: true,
    },
  },
  {
    title: "Terms",
    actions: {
      view: true,
      edit: true,
    },
  },
  {
    title: "Privacy",
    actions: {
      view: true,
      edit: true,
    },
  },
  {
    title: "Icons",
    actions: {
      view: true,
      edit: true,
      delete: true,
      create: true,
    },
  },
];

export const permissionsRoutes = [
  {
    title: "Inquiries",
    route: "inquiry-list",
  },
  {
    title: "Transactions",
    route: "transactions",
  },
  {
    title: "Media",
    route: "image-gallary",
  },
  {
    title: "Reviews",
    route: "reviews-list",
  },
  {
    title: "FAQs",
    route: "faqs",
  },
  {
    title: "Blogs",
    route: "blogs",
  },
  {
    title: "Roles",
    route: "roles",
  },
  {
    title: "Terms",
    route: "terms-conditions",
  },
  {
    title: "Privacy",
    route: "privacy-policy",
  },
  {
    title: "Icons",
    route: "icon-library",
  },
];