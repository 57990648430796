/* eslint-disable jsx-a11y/anchor-is-valid */
import { isArray } from "lodash";
import moment from "moment";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { DATE_FORMAT } from "../../../constants/common";
// import { render } from 'react-dom';
import { slideDown, slideUp } from "./animation";
import "./style.css";
import ContentLoader from "../../../scss/components/common/ContentLoader";
import TableLoader from "../common/TableLoader";
import NotFoundLable from "../common/NotFoundLable";
import { formatPhoneNumber, verifyObject } from "../../../utilities/utils";
import {
  assignInquiry,
  deletePackage as deletePackageInquiry,
  exportInquires,
} from "../../../services/inquiries";
import swal from "sweetalert";
import { Button } from "react-bootstrap";
import { saveAs } from "file-saver";

function formatDate(str) {
  return str.substr(0, 10);
}

function capitalize(str) {
  return str
    .split(" ")
    .map((s) => {
      return s.charAt(0).toUpperCase() + s.substr(1);
    })
    .join(" ");
}

class UserTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }
  toggleExpander = (e) => {
    if (e.target.type === "checkbox") return;

    if (!this.state.expanded) {
      this.setState({ expanded: true }, () => {
        if (this.refs.expanderBody) {
          slideDown(this.refs.expanderBody);
        }
      });
    } else {
      slideUp(this.refs.expanderBody, {
        onComplete: () => {
          this.setState({ expanded: false });
        },
      });
    }
  };
  _exportInquiry = async (d) => {
    console.log("dates", d);
    try {
      let payload = {
        type: 2,
        startDate: moment(d?.departureDate).format("YYYY-MM-DD"),
        endDate: moment(d?.returnDate).format("YYYY-MM-DD"),
      };
      let response = await exportInquires(payload);
    } catch (e) {
      console.log("Response roles");
      if (e) {
        var blob = new Blob([e], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `${d.fullName}-inquiry.xlsx`);
      } else {
        swal("Server Error", "Something went wrong", "error");
      }
    }
  };
  deleteRecord = async (id) => {
    try {
      // await setLoading(true);
      let response = await deletePackageInquiry(id);
      console.log("Response roles", response);
      swal("Poof! Your record has been deleted!", {
        icon: "success",
        timer: 3000,
        buttons: false,
      });
      // _getBlogList();
      this?.props?.onRequestPkgInquiries();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      console.log("eee", e);
      swal("Server Error", errorMessage, "error");
      // await setLoading(false);
    }
  };
  assignInquiry = async (userId) => {
    this.setState({ isAssigning: true }, async () => {
      try {
        let payload = {
          userId: userId,
          inquiryId: this.props.user._id,
          inquiryType: 2,
        };
        // await setLoading(t rue);
        let response = await assignInquiry(payload);
        console.log("Response roles", response);
        swal("Success", response?.data.message, "success");
        this.setState({ isAssigning: false });
        //   await exportInquires(payload);
        // await setSubmitting(false);
        // await handleClose();
        this.props.onRequestPkgInquiries();
      } catch (e) {
        if (e?.response) {
          console.log("e", e?.response);
          let errorMessage = verifyObject(
            e,
            "response.data.message",
            "Something went wrong"
          );
          swal("Server Error", errorMessage, "error");
        }
        this.setState({ isAssigning: false });

        // await setSubmitting(false);
      }
    });
  };

  render() {
    const {
      user,
      updatingStatusID,
      handleStatusChange,
      handleCommitEdit,
      isCommentEditable,
      selectedComment,
      handleCommentChange,
      updatingStatus,
      handldSubmitCommentChange,
      updatingComment,
      permissions,
    } = this.props;
    const editPermissions = permissions?.editPermissions;
    const deletePermissions = permissions?.deletePermissions;

    return [
      <tr key={user.id} role="row" className="odd">
        <td className="text-nowrap">
          <p>
            <span>{user.refId}</span>
          </p>
        </td>
        <td>{moment(user?.createdAt).format("DD-MM-YYYY hh:mm A")}</td>
        <td>
          <div className="concierge-bx d-flex align-items-center">
            <div>
              <h5 className="fs-16 mb-0 text-nowrap">
                <Link to={"#"} className="text-black">
                  {user.fullName}
                </Link>
              </h5>
              {/* <span className="text-primary fs-14">
                                      #EMP-00025
                                    </span> */}
            </div>
          </div>
        </td>

        <td className="text-nowrap">
          <span>{user.email}</span>
          <p>
            <a className="text-primary" href={`tel:${user?.phoneNumber}`}>
              {" "}
              {formatPhoneNumber(user?.phoneNumber, true)}
            </a>
          </p>
        </td>
        <td>
          {" "}
          {this.state.isAssigning && <span>Assigning....</span>}
          {!this.state.isAssigning &&
            this.props.user.isAssignee !== "true" &&
            "UnAssigned"}
          {this.props.u_data.role !== "admin" && user?.assignedUser?.fullName}
          {this.props.u_data.role === "admin" && (
            <Fragment>
              {!this.state.isAssigning && (
                <select
                  type="text"
                  className="form-control form-control-sm mt-3"
                  autoComplete="off"
                  name="assigne_user_id"
                  value={this.state.assigne_user_id || user?.assignedUser?._id}
                  onChange={(e) => {
                    this.setState({ assigne_user_id: e.target.value }, () => {
                      this.assignInquiry(e.target.value);
                    });
                  }}
                >
                  <option selected disabled value={""}>
                    Select User
                  </option>
                  {this.props?.allusers?.map((u) => {
                    return <option value={u._id}>{u?.fullName}</option>;
                  })}
                </select>
              )}
              {user?.assignedUser?._id !== this.props?.u_data?.userId && (
                <p>
                  <button
                    className="btn-sm btn btn-secondary mt-3"
                    onClick={() => this.assignInquiry(this.props.u_data.userId)}
                  >
                    Assign Myself
                  </button>
                </p>
              )}
            </Fragment>
          )}
          {this.props.u_data.role !== "admin" &&
            this.props.user.isAssignee !== "true" && (
              <p>
                <button
                  className="btn-sm btn-primary btn"
                  onClick={() => this.assignInquiry(this.props.u_data.userId)}
                >
                  Assign Myself
                </button>
              </p>
            )}{" "}
        </td>
        <td>
          {updatingStatusID !== user?._id && (
            <select
              onChange={(e) => handleStatusChange(e, user)}
              className="form-control form-control-sm"
              id="inputState"
              defaultValue={user?.status}
              disabled={!editPermissions?.Packages}
              // value={status}
            >
              <option value="pending">Pending</option>
              <option value="received">Received</option>
              <option value="in_progress">In-Progress</option>
              <option value="cancelled">Cancelled</option>
              <option value="completed">Completed</option>
            </select>
          )}
          {updatingStatusID === user?._id && updatingStatus && (
            <ContentLoader />
          )}
        </td>
        <td>
          {selectedComment?._id !== user._id && (
            <>
              <p>{user.comment ? user?.comment : "-"}</p>
              {editPermissions?.Inquiries && (
                <i
                  onClick={() => handleCommitEdit(user)}
                  className="fa fa-edit"
                ></i>
              )}
            </>
          )}
          {isCommentEditable && selectedComment?._id === user._id && (
            <Fragment>
              <textarea
                className="form-control form-control-sm"
                placeholder="Enter additional details"
                defaultValue={user.comment}
                onChange={(e) => handleCommentChange(e, user)}
              />
              <br />
              <button
                className="btn-sm btn-secondary"
                onClick={handldSubmitCommentChange}
              >
                {selectedComment?._id === user._id && updatingComment
                  ? "Editing..."
                  : "Submit"}
              </button>
            </Fragment>
          )}
        </td>
        <td>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* <Button
              className="btn-sm btn-secondary mb-2 "
              onClick={() => this._exportInquiry(user)}
            >
              Export
            </Button> */}
            {deletePermissions?.Packages ? (
              <Button
                className="btn-sm btn-primary"
                onClick={() => {
                  return swal({
                    title: "Are you sure?",
                    text: "Once deleted, you will not be able to recover this record!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      this.deleteRecord(user._id);
                    } else {
                      swal("Your record is safe!");
                    }
                  });
                }}
              >
                Delete
              </Button>
            ) : (
              "-"
            )}
          </div>
        </td>
        <td>
          {this.state.expanded ? (
            <i
              onClick={this.toggleExpander}
              class="fa fa-chevron-down cursor-pointer ct-chevron-icon"
            ></i>
          ) : (
            <i
              onClick={this.toggleExpander}
              class="fa fa-chevron-up cursor-pointer ct-chevron-icon"
            ></i>
          )}
        </td>
      </tr>,
      this.state.expanded && (
        <tr className="expandable" key="tr-expander">
          <td className="uk-background-muted" colSpan={6}>
            <div
              style={{ display: "flex", justifyContent: "space-around" }}
              ref="expanderBody"
              className="inner uk-grid"
            >
              <div>
                <h5> Trip Details & Travel Dates</h5>
                <span>
                  {moment(user.departureDate).format(DATE_FORMAT)}
                  {user.returnDate &&
                    ` To ${moment(user.returnDate).format(DATE_FORMAT)}`}
                </span>
                <p
                  style={{
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  className="text-capitalize font-10"
                >
                  {`${user.travellingFrom}`}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h5> Person & Budget</h5>

                <span
                  style={{
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  className="text-capitalize font-10"
                >
                  {`No Of Person : ${user.noOfPerson}`}
                </span>
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                  className="text-capitalize font-10"
                >
                  {`Per Person Budget : ${user.perPersonBudget}`}
                </span>
              </div>
              {(user.isDateFixed || user.isDateFlexible) && (
                <div>
                  <div className="card-content">
                    <address>
                      {user.isDateFixed && (
                        <div>
                          <a
                            className={`btn btn-success light btn-xs mb-1 me-1`}
                          >
                            Date Is Fixed
                          </a>
                        </div>
                      )}
                      {user.isDateFlexible && (
                        <div>
                          <a className={`btn btn-dark light btn-xs mb-1 me-1`}>
                            Date Is Flexible
                          </a>
                        </div>
                      )}
                    </address>
                  </div>
                </div>
              )}
              <div>
                <h5>Travel Plans</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${user?.briefTravelPlan?.slice(0, 100)} ${
                      user?.briefTravelPlan?.length > 100 ? "..." : ""
                    }`,
                  }}
                />
              </div>
            </div>
          </td>
          {/* </div> */}
        </tr>
      ),
    ];
  }
}

class PackageinquiryTable extends React.Component {
  state = { users: null };

  componentDidMount() {}

  render() {
    const { InquiryListData, isLoading, permissions } = this.props;
    console.log("this.props", this.props);

    return (
      <main>
        <div className="table-container">
          <div className="uk-overflow-auto">
            <table className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer">
              <thead>
                <tr>
                  <th className="sorting_asc">Ref.ID</th>
                  <th> Date/Time</th>
                  <th className="sorting_asc">Name</th>
                  <th className="sorting">Contact Info</th>
                  <th className="sorting">Assignee</th>
                  <th>Status</th>
                  <th>Comment</th>
                  <th>Actions</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr style={{ textAlign: "center" }}>
                    <td colSpan="8">
                      <TableLoader />
                    </td>
                  </tr>
                ) : null}
                {!isLoading && InquiryListData?.length === 0 && (
                  <tr>
                    <td colSpan="8">
                      <NotFoundLable message="No Packges found" />
                    </td>
                  </tr>
                )}
                {InquiryListData &&
                  isArray(InquiryListData) &&
                  InquiryListData.map((user, index) => {
                    return (
                      <UserTableRow
                        {...this.props}
                        key={index}
                        index={index + 1}
                        user={user}
                        allusers={this.props.allUsers}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    );
  }
}

export default PackageinquiryTable;
