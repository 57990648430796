import React, { Fragment, useRef, useState } from "react";
import { Modal, Select } from "antd";
// import { ValidateInput } from "./ValidateBlogs";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import { addNewBlog } from "../../../services/Blogs";
import swal from "sweetalert";
import { actions as blogsActions } from "../../../store/reducers/BlogsReducer";
import { useDispatch, useSelector } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import moment from "moment";
import { DatePicker } from "antd";
import { isEmpty } from "lodash";
import { saveAs } from "file-saver";
import XLSX from "sheetjs-style";
import Excel from "exceljs";

import { exportInquires } from "../../../services/inquiries";
const { RangePicker } = DatePicker;

export default function ExportInquiryModal({ showModal, setShowModal, user }) {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedTypes, setSelectedTypes] = useState([]);
  const { token } = useSelector((state) => state?.localStore);

  const formikRef = useRef(null);

  const handleSubmit = async (values, setSubmitting) => {
    let payload = {
      type: parseInt(values.inquiry_type, 10),
      startDate: moment(dateRange.startDate?.$d).format("YYYY-MM-DD"),
      endDate: moment(dateRange.endDate?.$d).format("YYYY-MM-DD"),
      typeOfInquiry: selectedTypes,
    };

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      type: parseInt(values.inquiry_type, 10),
      startDate: moment(dateRange.startDate?.$d).format("YYYY-MM-DD"),
      endDate: moment(dateRange.endDate?.$d).format("YYYY-MM-DD"),
      typeOfInquiry: selectedTypes,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.chicagohajj.com/inquiry/export-inquiry", requestOptions)
      .then((response) => {
        console.log("response", response);
        if (response.status !== 400) {
          return response.arrayBuffer();
        } else {
          return response.json();
        }
      })
      .then(async (result) => {
        setSubmitting(false);
        if (result.status === 400) {
          console.log("result", result);
          swal("Server Error", result.message, "error");
        } else {
          console.log("result", result);
          const exceLink = URL.createObjectURL(
            new Blob([result], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = exceLink;
          link.setAttribute(
            "download",
            `${
              values.inquiry_type === "1" ? "Inquiries" : "Package-Inquiries"
            }_${new Date().toLocaleDateString("en-US").split("/").join("")}`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(exceLink);
          await handleClose();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleDateRangePicker = (d) => {
    console.log("ddd", d);
    if (d) {
      setDateRange({
        startDate: d[0],
        endDate: d[1],
      });
    } else {
      setDateRange({
        startDate: null,
        endDate: null,
      });
      setSelectedTypes([]);
    }
  };
  const exportToExcel = async ({ excelData, fileName }) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.sheet(excelData);
    console.log("ws", ws);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, "text.xlsx");
  };

  const handleSelectChange = (dropValues) => {
    console.log("dropValues", dropValues);
    setSelectedTypes(dropValues);
  };

  const handleClose = (e) => {
    setShowModal();
    formikRef.current.resetForm({
      inquiry_type: "",
    });
    setDateRange({
      startDate: null,
      endDate: null,
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  return (
    <div>
      {" "}
      <Modal open={showModal} onCancel={handleClose} footer={false} width={700}>
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                inquiry_type: "",
                sub_inquiry_type: [],
              }}
              //   validate={ValidateInput}
              enableReinitialize
              innerRef={formikRef}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                // setSubmitting(false);
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                console.log("values", values);
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Export Inquiry</h4>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <div className="form-group mb-3">
                              <label className="text-black font-w500">
                                Type (Normal Inquiries or Package Inquiries)
                              </label>
                              <div className="contact-name">
                                <select
                                  type="text"
                                  className="form-control form-control-sm"
                                  autoComplete="off"
                                  name="inquiry_type"
                                  value={values.inquiry_type}
                                  onChange={(e) => {
                                    handleChange(e);
                                    console.log(
                                      "values inquiry_type",
                                      values.sub_inquiry_type
                                    );
                                    setSelectedTypes([]);
                                  }}
                                  onBlur={handleBlur}
                                >
                                  <option selected disabled value={""}>
                                    Select Inquiry Type
                                  </option>
                                  <option value={"1"}>Inquiries</option>
                                  <option value={"2"}>Packages</option>
                                </select>
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.inquiry_type}
                                  </span>
                                )}
                              </div>
                            </div>
                            {!isEmpty(values.inquiry_type) && (
                              <div className="contact-name">
                                <label className="text-black font-w500">
                                  Sub Type
                                </label>
                                <Select
                                  type="text"
                                  className="form-control form-control-sm"
                                  autoComplete="off"
                                  name="sub_inquiry_type"
                                  value={selectedTypes}
                                  onChange={handleSelectChange}
                                  placeholder="Select Inquiry Sub Type"
                                  mode="multiple"
                                >
                                  {values.inquiry_type === "1" && (
                                    <Fragment>
                                      <Select.Option value="Flights">
                                        Flights
                                      </Select.Option>
                                      <Select.Option value="flightAndhotel">
                                        Flights & Hotels
                                      </Select.Option>
                                      <Select.Option value="evisa">
                                        eVisa
                                      </Select.Option>
                                      <Select.Option value="HajjInquiry">
                                        Hajj
                                      </Select.Option>
                                    </Fragment>
                                  )}
                                  {values.inquiry_type === "2" && (
                                    <Fragment>
                                      <Select.Option value="Hajj">
                                        Hajj Packages
                                      </Select.Option>
                                      <Select.Option value="Umrah">
                                        Umrah Packages
                                      </Select.Option>
                                      <Select.Option value="Other">
                                        Other Packages
                                      </Select.Option>
                                    </Fragment>
                                  )}
                                </Select>
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.inquiry_type}
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                          {selectedTypes.length > 0 &&
                            !isEmpty(values.inquiry_type) && (
                              <div className="form-group mb-3">
                                <label className="text-black font-w500">
                                  Start Date- End Date
                                </label>
                                <div className="contact-name">
                                  <RangePicker
                                    value={[
                                      dateRange.startDate,
                                      dateRange.endDate,
                                    ]}
                                    onChange={handleDateRangePicker}
                                    style={{
                                      width: "100%",
                                      height: "37px",
                                    }}
                                    disabledDate={disabledDate}
                                    // className="form-control form-control-sm"
                                  />
                                  {errors && (
                                    <span
                                      id="val-username1-error"
                                      className="invalid-feedback animated fadeInUp"
                                      style={{ display: "block" }}
                                    >
                                      {errors.inquiry_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={
                          isSubmitting ||
                          values.inquiry_type === "" ||
                          dateRange.startDate === null ||
                          dateRange.endDate === null ||
                          selectedTypes.length === 0
                        }
                      >
                        {isSubmitting ? "Exporting..." : "Export"}
                      </button>
                      <button
                        type="button"
                        onClick={handleClose}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
