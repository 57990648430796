import { post, patch, get, deletee } from "./index";

export const addNewBanner = (data) => {
  const request = {
    subUrl: `/banner`,
    data,
  };
  return post(request);
};

export const getBannerList = (params) => {
  const request = {
    subUrl: `/banner`,
    params,
  };
  return get(request);
};

export const updateBanner = (data, id) => {
  const request = {
    subUrl: `/banner/${id}`,
    data,
  };
  return patch(request);
};

export const deleteBanner = (id) => {
  const request = {
    subUrl: `/banner/${id}`,
  };
  return deletee(request);
};
export const getBlogsByID = (id) => {
  const request = {
    subUrl: `/blog/${id}`,
  };
  return get(request);
};
