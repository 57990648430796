/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { isArray } from "lodash";
import swal from "sweetalert";
import { actions as packagesActions } from "../../../../store/reducers/packagePricing";
import TableLoader from "../../common/TableLoader";
import NotFoundLable from "../../common/NotFoundLable";
import { useDispatch, useSelector } from "react-redux";
import { verifyObject } from "../../../../utilities/utils";
import Pagination from "../../../components/common/Pagination";
import {

  deletePackagesPricing,
} from "../../../../services/packages";
import AddPackagePricing from "./AddPackagePricing";

const PackagePricingList = ({ packageId }) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [userModal, setUserModal] = useState(false);
  const [editedData, setEditFormData] = useState("Newest");

  const { data, isLoading, pagination } = useSelector(
    (state) => state.packagePricing
  );
  const { local_package_id } = useSelector((state) => state.localStore);
  const handleModal = (value) => {
    setUserModal(!userModal);
  };

  const handleEditClick = (event, data) => {
    event.preventDefault();
    setEditFormData(data);
    handleModal();
  };

  const _getBlogList = () => {
    dispatch(
      packagesActions.onRequest({
        packageId: local_package_id,
      })
    );
  };

  const onPageChange = (data) => {
    // const { customerId: user_id } = props.localStore;
    const { page } = data;
    dispatch(packagesActions.onRequest({ page, packageId }));
  };

  useEffect(() => {
    _getBlogList();
  }, []);

  const DropdownBlog = (user) => {
    return (
      <>
        <Dropdown className="dropdown">
          <Dropdown.Toggle
            as="div"
            className="btn-link i-false"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                stroke="#262626"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu">
            <Dropdown.Item
              onClick={(e) => handleEditClick(e, user)}
              className="dropdown-item"
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                return swal({
                  title: "Are you sure?",
                  text: "Once deleted, you will not be able to recover this record!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    deleteRecord(user._id);
                  } else {
                    swal("Your record is safe!");
                  }
                });
              }}
              className="dropdown-item"
            >
              Delete
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => push(`/packages-details/${user._id}`)}
              className="dropdown-item"
            >
              Show Details
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const deleteRecord = async (id) => {
    try {
      // await setLoading(true);
      let response = await deletePackagesPricing(id);
      console.log("Response roles", response);
      swal("Poof! Your record has been deleted!", {
        icon: "success",
        timer: 3000,
        buttons: false,
      });
      _getBlogList();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      // await setLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2 flex-wrap">
          <div className="newest ms-3">
            <Link
              to={"#"}
              className="btn btn-primary font-w600 mb-2 me-auto"
              onClick={() => {
                setEditFormData(null);
                handleModal();
              }}
            >
              + New Pricing{" "}
            </Link>
            <AddPackagePricing
              packageId={packageId}
              userModal={userModal}
              editedData={editedData}
              setAddUserModal={handleModal}
              _getBlogList={_getBlogList}
            />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <div
                  id="example2_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example2"
                    className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting"> ID</th>
                        <th className="sorting_asc">Title</th>
                        <th className="sorting_asc">People Per Room</th>
                        <th className="sorting_asc">Price Per Person</th>
                        <th className="sorting bg-none"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="8">
                            <TableLoader />
                          </td>
                        </tr>
                      ) : null}
                      {!isLoading && data.length === 0 && (
                        <tr>
                          <td colSpan="8">
                            <NotFoundLable message="No package info added " />
                          </td>
                        </tr>
                      )}
                      {data &&
                        isArray(data) &&
                        data.map((user, index) => {
                          return (
                            <tr key={user._id} role="row" className="odd">
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-nowrap">
                                      {index + 1}
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-nowrap">
                                      {user.title}
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-nowrap">
                                      {user.peoplePerRoom}
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-nowrap">
                                      {user.pricePerPerson}
                                    </h5>
                                  </div>
                                </div>
                              </td>

                              <td>{DropdownBlog(user)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  {/* <PaginationComponent pagination={pagination} /> */}
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div
                      className="dataTables_paginate paging_simple_numbers mb-0"
                      id="example2_paginate"
                    >
                      <Pagination
                        pagination={pagination}
                        data={data}
                        onPageChange={onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PackagePricingList;
