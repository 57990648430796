 import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p>
        Copyrights © {d.getFullYear()} &amp; All Rights Reserved{" "}
          <a href="https://www.chicagohajj.com/" target="_blank" rel="noreferrer">
          Chicago Hajj
          </a>{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
