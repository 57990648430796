import React from "react";
import { Modal } from "react-bootstrap";
import { ValidateInput } from "./ValidateImageGallaryCategory";
import { Formik } from "formik";
import swal from "sweetalert";
import { verifyObject } from "../../../utilities/utils";
import { updateImageGalleryCategories } from "../../../services/ImageGallary";

export default function EditImageGallaryCategory({
  userModal,
  setAddUserModal,
  editedData,
  _getAllImageGalleryCategories,
}) {

  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);
    try {
      let response = await updateImageGalleryCategories({
        name: values.faq_type_name,
        _id: editedData.id,
      });

      console.log("Response roles", response);
      // setUserArray(response.data.data.docs);
      // this.setState()
      setAddUserModal();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await setSubmitting(false);
      await _getAllImageGalleryCategories();
    } catch (e) {
      console.log("Response errr", e);

      let errorMessage = verifyObject(
        e,
        "data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");

      await setSubmitting(false);
    }
  };

  return (
    <div>
      {" "}
      <Modal className="modal fade" show={userModal} onHide={setAddUserModal}>
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={editedData}
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">
                        Update Image Category
                      </h4>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">Name</label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="faq_type_name"
                                value={values.faq_type_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Image Category Name"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.faq_type_name}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        onClick={setAddUserModal}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
