import { getAllVideos } from "../../services/VideoGallary";
import { listingReducer } from "./utils/listingReducer";

const { reducer, actions, types } = listingReducer({
  name: "videoGallary",
  getApi: getAllVideos,
});

const videoGallary = reducer;
export { videoGallary, actions, types };
