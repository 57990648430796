/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { ValidateInput } from "./ValidateRoles";
import { Formik } from "formik";
import { addnewRole } from "../../../services/Roles";
import { verifyObject } from "../../../utilities/utils";
import { allPermissions } from "../../../constants/common";
import { uniq } from "lodash";

export default function AddNewRole({
  userModal,
  setAddUserModal,
  editedData,
  _getRoleList,
}) {
  const [addFormData, setAddFormData] = useState({
    role_name: "",
  });
  const [editedFormData, setEditdFormData] = useState(null);
  const [state, setState] = useState({
    allPermissionsState: allPermissions,
  });

  useEffect(() => {
    if (editedData) {
      console.log("edited data", editedData);
      setEditdFormData(editedData);
    }
  }, [editedData]);

  const handlePermissionCheckbox = (e, permission, title, pindex) => {
    setState((state) => {
      let allPermissions = state.allPermissionsState;
      allPermissions[pindex].title = title;
      Object.keys(allPermissions[pindex]?.actions).map((key, index) => {
        if (key === permission) {
          allPermissions[pindex].actions[key] = e.target.checked;
        }
        return null;
      });
      console.log("allPermissions", allPermissions);
      return {
        ...state,
        allPermissionsState: allPermissions,
        errors: {
          ...state.errors,
          permissions: null,
        },
      };
    });
    // if (e.target.checked) {
    //  ;
    // } else {
    //   setState((state) => {
    //     let allPermissions = state.allPermissionsState;
    //     allPermissions[pindex].title = title;
    //     allPermissions[pindex].chekedpermissions =
    //       allPermissions[pindex].chekedpermissions &&
    //       allPermissions[pindex].chekedpermissions.length > 0
    //         ? allPermissions[pindex].actions.filter(
    //             (d) => d !== permission
    //           )
    //         : [];
    //     return { ...state, allPermissionsState: allPermissions };
    //   });
    // }
  };

  const handleSubmit = async (values, setSubmitting) => {
    console.log("finalpermissions", state.allPermissionsState);
    try {
      // let newRoles = [];
      // state.allPermissionsState.map((d) =>
      //   newRoles.push({ title: d.name, ...state?.allPermissionsState })
      // );
      let response = await addnewRole({
        name: values.role_name,
        permissions: state.allPermissionsState,
      });

      console.log("Response roles", response);
      // setUserArray(response.data.data.docs);
      // this.setState()
      setAddUserModal();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await setSubmitting(false);
      await _getRoleList();
      // await setAddUserModal();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };
  // console.log("allPermissions", allPermissions);
  return (
    <div>
      {" "}
      <Modal className="modal fade" show={userModal} onHide={setAddUserModal}>
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={addFormData}
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Add New Role</h4>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Role Name
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="role_name"
                                value={values.role_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter role name"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.role_name}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="add-contact-content">
                          <label className="text-black font-w500">
                            Permissions
                          </label>
                          {allPermissions.map((d, pindex) => {
                            return (
                              <>
                                <div className="d-flex checkpermission flex-column">
                                  <p className="labeltitle">{d.title}</p>
                                  <div className="checkb">
                                    {Object.keys(d.actions).map(
                                      (permission, index) => {
                                        return (
                                          <div className="form-check custom-checkbox mb-3 checkbox-danger">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id={`customCheckBox${index}${d.title}}`}
                                              // checked={
                                              //   d.chekedpermissions &&
                                              //   d.chekedpermissions.includes(
                                              //     permission.value
                                              //   )
                                              // }
                                              onChange={(e) =>
                                                handlePermissionCheckbox(
                                                  e,
                                                  permission,
                                                  d.title,
                                                  pindex
                                                )
                                              }
                                            />
                                            <label
                                              className="form-check-label text-black font-w500"
                                              htmlFor={`customCheckBox${index}${d.title}}`}
                                            >
                                              {permission}
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                <hr />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                        // data-loading-text="<i class='fa fa-spinner fa-spin"
                      >
                        {isSubmitting ? (
                          <Fragment>
                            <i class="fa fa-circle-o-notch fa-spin"></i>{" "}
                            <span>Adding...</span>
                          </Fragment>
                        ) : (
                          "ADD"
                        )}
                      </button>
                      <button
                        type="button"
                        onClick={setAddUserModal}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
