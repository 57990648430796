import validator from "validator";
export function ValidateInput(data, videoType) {
  let errors = {};
  console.log("data", data);

  if (data.video_file === "" && videoType === "file") {
    errors.video_file = "Video  is required";
  }
  if (data.url === "" && videoType === "url") {
    errors.url = "Video URL  is required";
  }
  if (
    data.url &&
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
      data.url
    ) === false &&
    videoType === "url"
  ) {
    errors.url = "Please enter a valid url";
  }
  if (data.video_type === "") {
    errors.video_type = "Video type  is required";
  }
  if (data.video_description === "") {
    errors.video_description = "Video Description  is required";
  }
  console.log("errors", errors);
  return errors;
}
