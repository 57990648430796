/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import { ValidateInput } from "./ValidateNewUser";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { getRoles } from "../../../services/Roles";
import { verifyObject } from "../../../utilities/utils";
import { actions as usersActions } from "../../../store/reducers/usersReducer";
import { updateUser } from "../../../services/Users";

export default function EditUserData({
  userModal,
  setAddUserModal,
  editedData,
}) {
  const [rolesArray, setRolesArray] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const _getRoleList = async () => {
    try {
      await setLoading(true);
      let response = await getRoles({});
      console.log("Response roles", response);
      await setRolesArray(response.data.data.docs);
      await setLoading(false);

      // this.setState()
    } catch (e) {
      console.log("eee", e);
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setLoading(false);
    }
  };

  useEffect(() => {
    _getRoleList();
  }, []);

  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);

    try {
      let payload = {
        fullName: values.user_name,
        email: values.login_id,
        role: values.role,
        designation: values.designation,
        _id: editedData._id,
      };
      let response = await updateUser(payload);

      console.log("Response roles", response);
      // setUserArray(response.data.data.docs);
      // this.setState()
      await setAddUserModal();
      await setSubmitting(false);
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await dispatch(usersActions.onRequest({}));
      // await setAddFormData(intialFormData);
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };
  return (
    <div>
      {" "}
      <Modal className="modal fade" show={userModal} onHide={setAddUserModal}>
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={editedData}
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Update System User</h4>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">Name</label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="user_name"
                                value={values.user_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter your name"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.user_name}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Login ID (Email)
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="login_id"
                                value={values.login_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter login id"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.login_id}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Privilage | Role
                            </label>
                            <fieldset className="form-group">
                              <div className="row mb-3">
                                <div className="col-sm-9">
                                  <div className="form-check">
                                    {rolesArray.map((d) => {
                                      return (
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            name="role"
                                            value={d._id}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            defaultChecked={
                                              values.role === d.name
                                            }
                                            checked={d._id === values.role}
                                          />
                                          <label className="form-check-label text-capitalize">
                                            {d.name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            {errors && (
                              <span
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.role}
                              </span>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Designation
                            </label>
                            <div className="contact-occupation">
                              <input
                                type="text"
                                autoComplete="off"
                                name="designation"
                                value={values.designation}
                                //   required="required"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                placeholder="Enter designation"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.designation}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Updating..." : "Update"}
                      </button>
                      <button
                        type="button"
                        onClick={setAddUserModal}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
