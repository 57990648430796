/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { actions as packageInquiriesAction } from "../../../store/reducers/packageInquiries";
///Import
// import PendingBlog from "./Guest/PendingBlog";
// import BookedBlog from "./Guest/BookedBlog";
// import CanceledBlog from "./Guest/CanceledBlog";
// import RefundBlog from "./Guest/RefundBlog";

//Images
import { isArray } from "lodash";
import { verifyObject } from "../../../utilities/utils";
import { useDispatch, useSelector } from "react-redux";
import TableLoader from "../common/TableLoader";
import NotFoundLable from "../common/NotFoundLable";
import Pagination from "../../components/common/Pagination";
import moment from "moment";
import { DATE_FORMAT } from "../../../constants/common";

import "antd/es/popover/style/index";
import {
  updatePackageInquryComment,
  updatePackageInquryStatus,
} from "../../../services/inquiries";
import swal from "sweetalert";
import ContentLoader from "../../../scss/components/common/ContentLoader";
import PackageinquiryTable from "./PackageInquiryTable";
export default function Packageinquiries(props) {
  const dispatch = useDispatch();
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingStatusID, setUpdatingStatusID] = useState(null);
  const [PackageListData, setData] = useState([]);
  const [isCommentEditable, setIsCommentEditable] = useState(false);
  const [selectedComment, setComment] = useState(null);
  const [changedComment, setChangedComment] = useState(null);
  const [updatingComment, setUpdatingComment] = useState(false);
  // const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(10);
  const { data, isLoading, pagination } = useSelector(
    (state) => state.packageInq
  );
  // const onPageChange = ({ page, limit }) => {
  //   props.setPage(page);
  //   props.setLimit(limit);
  //   dispatch(packageInquiriesAction.onRequest({ page: page, limit }));
  // };

  // useEffect(() => {
  //   _getPackageInq();
  // }, []);

  // const _getPackageInq = () => {
  //   dispatch(packageInquiriesAction.onRequest({}));
  // };

  useEffect(() => {
    setData(data);
  }, [data]);

  const handleStatusChange = async (e, d) => {
    setUpdatingStatus(true);
    setUpdatingStatusID(d?._id);
    try {
      let payload = {
        status: e.target.value,
        inquiryType: 2,
        _id: d._id,
      };
      let response = await updatePackageInquryStatus(payload);
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });

      // await _getTripInq();
      setData((PackageListData) => {
        let inqi = PackageListData;
        let _f = inqi?.find((i) => i._id === d._id);
        _f.status = e.target.value;

        console.log("_f", inqi);
        return inqi;
      });
      await setUpdatingStatus(false);
      await setUpdatingStatusID(null);
    } catch (err) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      // setStatus(e.target.value)

      swal("Server Error", errorMessage, "error");
      await setUpdatingStatus(false);
      await setUpdatingStatusID(null);

      // await setSubmitting(false);
    }
  };

  const handleCommitEdit = async (d) => {
    setIsCommentEditable(true);
    setComment(d);
    // setComment(d)
  };

  const handleCommentChange = async (e, d) => {
    if (d._id === selectedComment._id) {
      setChangedComment(e.target.value);
    }
  };
  const handldSubmitCommentChange = async (o) => {
    await setUpdatingComment(true);
    try {
      let payload = {
        comment: changedComment ? changedComment : selectedComment?.comment,
        inquiryType: 2,
        _id: selectedComment._id,
      };
      let response = await updatePackageInquryComment(payload);
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      setData((PackageListData) => {
        let inqi = PackageListData;
        let _f = inqi?.find((i) => i._id === selectedComment._id);
        _f.comment = changedComment ? changedComment : selectedComment?.comment;
        console.log("_f", inqi);
        return inqi;
      });
      await setUpdatingComment(false);
      await setUpdatingStatusID(null);
      await setChangedComment("");
      await setComment(null);
    } catch (err) {
      let errorMessage = verifyObject(
        err,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setUpdatingComment(false);
      await setUpdatingStatusID(null);
      await setChangedComment("");
      await setIsCommentEditable(false);
      await setComment(null);
    }
  };
  console.log("data", data);
  return (
    <div className="col-xl-12">
      <PackageinquiryTable
        updatingStatusID={updatingStatusID}
        handleStatusChange={handleStatusChange}
        handleCommitEdit={handleCommitEdit}
        isCommentEditable={isCommentEditable}
        selectedComment={selectedComment}
        handleCommentChange={handleCommentChange}
        updatingStatus={updatingStatus}
        handldSubmitCommentChange={handldSubmitCommentChange}
        updatingComment={updatingComment}
        InquiryListData={PackageListData}
        isLoading={isLoading}
        onRequestPkgInquiries={props.onRequestPkgInquiries}
        permissions={props.permissions}
        u_data={props.user}
        allUsers={props.allUsers}
      />
      {data?.length >= props.limit && (
        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
          <div className="dataTables_info"></div>
          <div
            className="dataTables_paginate paging_simple_numbers mb-0"
            id="example2_paginate"
          >
            <Pagination
              pagination={pagination}
              data={data}
              onPageChange={props?.onPageChange}
              // handleLimitChange={(e) => {
              //   let limit = e.target.value;
              //   console.log("limiy", limit);
              //   onPageChange({ page: props.page, limit });
              // }}
            />
          </div>
        </div>
      )}
      {/* <div style={{ display: "none" }} className="card">
        <div className="card-body p-0">
          <div className="table-responsive">
            <div id="example2_wrapper" className="dataTables_wrapper no-footer">
              <table
                id="example2"
                className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc">Ref.ID</th>

                    <th className="sorting_asc">Name</th>
                    <th className="sorting">Dates</th>
                    <th className="sorting">Travelling Details</th>
                    <th className="sorting">Travel Plan</th>
                    <th width="10%" className="sorting">
                      Status
                    </th>
                    <th className="sorting">Comment</th>
                    <th width="12%">Inquiry Date</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr style={{ textAlign: "center" }}>
                      <td colSpan="8">
                        <TableLoader />
                      </td>
                    </tr>
                  ) : null}
                  {!isLoading && PackageListData.length === 0 && (
                    <tr>
                      <td colSpan="8">
                        <NotFoundLable message="No Users found" />
                      </td>
                    </tr>
                  )}
                  {PackageListData &&
                    isArray(PackageListData) &&
                    PackageListData.map((user) => {
                      return (
                        <tr key={user.id} role="row" className="odd">
                          <td className="text-nowrap">
                            <p>
                              <span>{user.refId}</span>
                            </p>
                          </td>
                          <td>
                            <div className="concierge-bx d-flex align-items-center">
                              <div>
                                <h5 className="fs-16 mb-0 text-nowrap">
                                  <Link to={"#"} className="text-black">
                                    {user.fullName}
                                  </Link>
                                </h5>
                                <span>{user.email}</span>
                                <p>
                                  <span>{user.mobileNumber}</span>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <span>
                                {moment(user.departureDate).format(DATE_FORMAT)}
                                {user.returnDate &&
                                  ` To ${moment(user.returnDate).format(
                                    DATE_FORMAT
                                  )}`}
                              </span>
                            </div>
                          </td>
                          <td>
                            <span
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                              className="text-capitalize font-10"
                            >
                              {`${user.travellingFrom}`}
                            </span>

                            <p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                  }}
                                  className="text-capitalize font-10"
                                >
                                  {`No Of Person : ${user.noOfPerson}`}
                                </span>
                                <span
                                  style={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                  }}
                                  className="text-capitalize font-10"
                                >
                                  {`Per Person Budget : ${user.perPersonBudget}`}
                                </span>
                              </div>
                            </p>
                            <p>
                              <div>
                                <div className="card-content">
                                  <address>
                                    {user.isDateFixed && (
                                      <div>
                                        <a
                                          className={`btn btn-success light btn-xs mb-1 me-1`}
                                        >
                                          Date Is Fixed
                                        </a>
                                      </div>
                                    )}
                                    {user.isDateFlexible && (
                                      <div>
                                        <a
                                          className={`btn btn-dark light btn-xs mb-1 me-1`}
                                        >
                                          Date Is Flexible
                                        </a>
                                      </div>
                                    )}
                                  </address>
                                </div>
                              </div>
                            </p>
                          </td>
                          <td>
                            <h5 className="fs-16 mb-0 text-wrap">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${user?.briefTravelPlan?.slice(
                                    0,
                                    100
                                  )} ${
                                    user?.briefTravelPlan?.length > 100
                                      ? "..."
                                      : ""
                                  }`,
                                }}
                              />
                            </h5>
                          </td>
                          <td>
                            {updatingStatusID !== user?._id && (
                              <select
                                onChange={(e) => handleStatusChange(e, user)}
                                className="form-control form-control-sm"
                                id="inputState"
                                defaultValue={user?.status}
                              >
                                <option value="pending">Pending</option>
                                <option value="received">Received</option>
                                <option value="in_progress">In-Progress</option>
                                <option value="cancelled">Cancelled</option>
                                <option value="completed">Completed</option>
                              </select>
                            )}
                            {updatingStatusID === user?._id &&
                              updatingStatus && <ContentLoader />}
                          </td>

                          <td>
                            {selectedComment?._id !== user._id && (
                              <>
                                <p>{user.comment}</p>
                                <i
                                  onClick={() => handleCommitEdit(user)}
                                  className="fa fa-edit"
                                ></i>
                              </>
                            )}
                            {isCommentEditable &&
                              selectedComment?._id === user._id && (
                                <Fragment>
                                  <textarea
                                    className="form-control form-control-sm"
                                    placeholder="Enter additional details"
                                    defaultValue={user.comment}
                                    onChange={(e) =>
                                      handleCommentChange(e, user)
                                    }
                                  />
                                  <br />
                                  <button
                                    className="btn-sm btn-secondary"
                                    onClick={handldSubmitCommentChange}
                                  >
                                    {selectedComment?._id === user._id &&
                                    updatingComment
                                      ? "Editing..."
                                      : "Submit"}
                                  </button>
                                </Fragment>
                              )}
                          </td>
                          <td>
                            {moment(user?.createdAt).format(
                              "DD-MM-YYYY HH:mm A"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
