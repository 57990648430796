import React, { useState } from "react";
import TableLoader from "../../common/TableLoader";
import NotFoundLable from "../../common/NotFoundLable";
import { isArray } from "lodash";
import Pagination from "../../../components/common/Pagination";
import { Input, InputNumber } from "antd";
import { updatePackageDetail } from "../../../../services/packages";
import swal from "sweetalert";
import { verifyObject } from "../../../../utilities/utils";

export default function PackageListItem({
  isLoading,
  data,
  editPermissions,
  deletePermissions,
  createPermissions,
  DropdownBlog,
  pagination,
  onPageChange,
  forType,
  _getPackagesList,
}) {
  const [userPosition, setUserPosition] = useState("");

  const handlePositionChange = async (e, user) => {
    console.log("userPosition", userPosition);
    try {
      let formData = new FormData();
      formData.set("position", userPosition);
      let response = await updatePackageDetail(formData, user._id);
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      _getPackagesList();
      // await dispatch(blogsActions.onRequest({}));

      // await setAddUserModal();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
    }
  };
  return (
    <div className="my-post-content pt-3">
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <table
                  id="example2"
                  className="table table-sm mb-0 table-responsive-lg p-2"
                >
                  <thead>
                    <tr role="row">
                      <th className="sorting_asc">Title</th>
                      {/* <th className="sorting_asc">Sub Title</th> */}
                      <th className="sorting_asc">Short Description</th>
                      <th className="sorting_asc">Image</th>
                      <th className="sorting_asc">Price</th>
                      <th className="sorting_asc">Dates</th>
                      <th className="sorting_asc">Position</th>
                      <th className="sorting_asc">Status</th>
                      <th className="sorting bg-none"></th>
                    </tr>
                  </thead>
                  <tbody id="orders">
                    {isLoading ? (
                      <tr style={{ textAlign: "center" }}>
                        <td colSpan="8">
                          <TableLoader />
                        </td>
                      </tr>
                    ) : null}
                    {!isLoading && data.length === 0 && (
                      <tr>
                        <td colSpan="8">
                          <NotFoundLable
                            message={`No ${forType} Packages found`}
                          />
                        </td>
                      </tr>
                    )}
                    {data &&
                      isArray(data) &&
                      data.map((user, index) => {
                        return (
                          <tr
                            key={user._id}
                            role="row"
                            className="btn-reveal-trigger"
                          >
                            {/* <td>
                        <div className="concierge-bx d-flex align-items-center">
                          <div>
                            <h5 className="fs-16 mb-0 text-nowrap">
                              {index + 1}
                            </h5>
                          </div>
                        </div>
                      </td> */}
                            <td className="py-2">
                              <div className="concierge-bx d-flex align-items-center font-w500">
                                {user.title}
                              </div>
                            </td>
                            <td>
                              <div className="concierge-bx d-flex align-items-center ">
                                {/* <div> */}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: `${user.shortDescription.slice(
                                      0,
                                      100
                                    )} ${
                                      user.shortDescription.length > 100
                                        ? "..."
                                        : ""
                                    }`,
                                  }}
                                />
                                {/* </div> */}
                              </div>
                            </td>
                            <td>
                              <div className="concierge-bx d-flex align-items-center font-w500">
                                <img
                                  src={`${user.packageListingImage}`}
                                  alt=""
                                />
                                {}
                              </div>
                            </td>{" "}
                            <td>
                              <div className="concierge-bx d-flex align-items-center font-w500">
                                {user.price} {user.priceCaption}
                                {}
                              </div>
                            </td>
                            <td>
                              <div className="concierge-bx d-flex align-items-center font-w500">
                                {/* {console.log("isDate(user.englishDate)",isValidDate(user.englishDate))}
                              {moment(user.englishDate).format(
                                "DD-MM-YYYY"
                              )} */}
                                {user.englishDate}
                              </div>
                            </td>
                            <td>
                              <div className="concierge-bx d-flex align-items-center font-w500">
                                <InputNumber
                                  name="position"
                                  onChange={(e) => {
                                    setUserPosition(e);
                                    
                                  }}
                                  defaultValue={user.position}
                                  onBlur={(e) => handlePositionChange(e, user)}
                                  onKeyUpCapture={(e)=>{
                                    console.log('onKeyUpCapture', e.key);
                                    if(e.key === 'Enter'){
                                      handlePositionChange(e, user)
                                    }
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="concierge-bx d-flex align-items-center">
                                <div>
                                  {user.isActive ? (
                                    <span className="badge badge-success">
                                      Active
                                      <span className="ms-1 fa fa-check" />
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      In Active
                                      <span className="ms-1 fa fa-check" />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td>
                              {(editPermissions?.Packages ||
                                deletePermissions?.Packages ||
                                createPermissions?.Packages) &&
                                DropdownBlog(user)}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                {/* <PaginationComponent pagination={pagination} /> */}
                <div className="d-sm-flex text-center justify-content-end align-items-center mt-3 mb-3">
                  <div
                    className="dataTables_paginate paging_simple_numbers mb-0"
                    id="example2_paginate"
                  >
                    <Pagination
                      pagination={pagination}
                      data={data}
                      onPageChange={onPageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
