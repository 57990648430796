import { post, patch, get, deletee } from "./index";

export const createNewImage = (data) => {
  const request = {
    subUrl: `/imageGallery`,
    data,
  };
  return post(request);
};

export const getAllImages = (params) => {
  const request = {
    subUrl: `/imageGallery`,
    params,
  };
  return get(request);
};

export const updateImage = (data,id) => {
  const request = {
    subUrl: `/imageGallery/${id}`,
    data,
  };
  return patch(request);
};

export const deleteImage = (id) => {
  const request = {
    subUrl: `/imageGallery/${id}`,
  };
  return deletee(request);
};


//Image Gallery Category

export const createNewImageGalleryCategory = (data) => {
  const request = {
    subUrl: `/imageGalleryCategory`,
    data,
  };
  return post(request);
};

export const getAllImageGalleryCategories = (params) => {
  const request = {
    subUrl: `/imageGalleryCategory/`,
    params,
  };
  return get(request);
};

export const updateImageGalleryCategories = (data) => {
  const request = {
    subUrl: `/imageGalleryCategory/${data._id}`,
    data,
  };
  return patch(request);
};

export const deleteImageGalleryCategories = (_id) => {
  const request = {
    subUrl: `/imageGalleryCategory/${_id}`,
  };
  return deletee(request);
};
