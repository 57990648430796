/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { isArray } from "lodash";
import swal from "sweetalert";
import { actions as blogsActions } from "../../../store/reducers/BlogsReducer";

import TableLoader from "../common/TableLoader";
import NotFoundLable from "../common/NotFoundLable";
import { useDispatch, useSelector } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import { deleteBlog, updateBlog } from "../../../services/Blogs";
import Pagination from "../../components/common/Pagination";
import UploadSitemap from "./UploadSitemap";

const SiteMapIndex = () => {
  const [editedData, setEditFormData] = useState("Newest");
  const [userModal, setUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const { push } = useHistory();
  const { permissions } = useSelector((state) => state?.localStore);
  const createPermissions = permissions?.createPermissions;
  const editPermissions = permissions?.editPermissions;
  const deletePermissions = permissions?.deletePermissions;

  const dispatch = useDispatch();
  const { data, isLoading, pagination } = useSelector((state) => state.blogs);

  const handleEditClick = (event, data) => {
    console.log("data", data);
    event.preventDefault();
    const formValues = {
      id: data._id,
      blog_title: data.title,
      blog_image: data.image,
      blog_description: data.description,
      blog_short_description: data.shortDescription,
      blog_status: data.status,
      slug: data.slug,
    };
    setEditFormData(formValues);
    handleEditModal();
  };

  const handleEditModal = () => {
    setEditUserModal(!editUserModal);
  };

  const _getBlogList = () => {
    dispatch(blogsActions.onRequest({}));
  };

  const onPageChange = (data) => {
    // const { customerId: user_id } = props.localStore;
    const { page } = data;
    dispatch(blogsActions.onRequest({ page }));
  };

  const changeStatus = async (status, id) => {
    try {
      let formData = new FormData();
      formData.set("status", status);
      let response = await updateBlog(formData, id);

      console.log("Response roles", response);
      // setUserArray(response.data.data.docs);
      // this.setState()
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await dispatch(blogsActions.onRequest({}));
    } catch (e) {
      console.log("eeee", e);
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
    }
  };

  useEffect(() => {
    _getBlogList();
  }, []);

  // const DropdownBlog = (user) => {
  //   return (
  //     <>
  //       {(editPermissions.Blogs || deletePermissions.Blogs) && (
  //         <Dropdown className="dropdown">
  //           <Dropdown.Toggle
  //             as="div"
  //             className="btn-link i-false"
  //             data-bs-toggle="dropdown"
  //             aria-expanded="false"
  //           >
  //             <svg
  //               width="24"
  //               height="24"
  //               viewBox="0 0 24 24"
  //               fill="none"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path
  //                 d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
  //                 stroke="#262626"
  //                 strokeWidth="2"
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //               <path
  //                 d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
  //                 stroke="#262626"
  //                 strokeWidth="2"
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //               <path
  //                 d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
  //                 stroke="#262626"
  //                 strokeWidth="2"
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //             </svg>
  //           </Dropdown.Toggle>
  //           <Dropdown.Menu className="dropdown-menu">
  //             {editPermissions.Blogs && (
  //               <Dropdown.Item
  //                 onClick={(e) => handleEditClick(e, user)}
  //                 className="dropdown-item"
  //               >
  //                 Edit
  //               </Dropdown.Item>
  //             )}
  //             {deletePermissions.Blogs && (
  //               <Dropdown.Item
  //                 onClick={() => {
  //                   return swal({
  //                     title: "Are you sure?",
  //                     text: "Once deleted, you will not be able to recover this record!",
  //                     icon: "warning",
  //                     buttons: true,
  //                     dangerMode: true,
  //                   }).then((willDelete) => {
  //                     if (willDelete) {
  //                       deleteRecord(user._id);
  //                     } else {
  //                       swal("Your record is safe!");
  //                     }
  //                   });
  //                 }}
  //                 className="dropdown-item"
  //               >
  //                 Delete
  //               </Dropdown.Item>
  //             )}
  //             <Dropdown.Item
  //               onClick={(e) => {
  //                 if (user.status === "draft") {
  //                   changeStatus("publish", user._id);
  //                 } else {
  //                   changeStatus("draft", user._id);
  //                 }
  //               }}
  //               className="dropdown-item"
  //             >
  //               Change Status
  //             </Dropdown.Item>
  //             <Dropdown.Item
  //               onClick={(e) => push(`/blogs/${user._id}`)}
  //               className="dropdown-item"
  //             >
  //               Show Details
  //             </Dropdown.Item>
  //           </Dropdown.Menu>
  //         </Dropdown>
  //       )}
  //     </>
  //   );
  // };

  const handleModal = (value) => {
    setUserModal(!userModal);
  };

  const deleteRecord = async (id) => {
    try {
      // await setLoading(true);
      let response = await deleteBlog(id);
      console.log("Response roles", response);
      swal("Poof! Your record has been deleted!", {
        icon: "success",
        timer: 3000,
        buttons: false,
      });
      _getBlogList();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      // await setLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <div
                  id="example2_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <UploadSitemap />
                  {/* <PaginationComponent pagination={pagination} /> */}
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info">
                      {/* Showing {activePag.current * sort + 1} to{" "}
                      {data.length > (activePag.current + 1) * sort
                        ? (activePag.current + 1) * sort
                        : data.length}{" "}
                      of {data.length} entries */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SiteMapIndex;
