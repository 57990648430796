/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap";
import { ValidateInput } from "./ValidateBlogs";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import swal from "sweetalert";
import { updateBlog } from "../../../services/Blogs";
import { actions as blogsActions } from "../../../store/reducers/BlogsReducer";
import { useDispatch } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import { Image } from "antd";
import { TINY_MCE_API_KEY, TINY_MCE_EDITOR } from "../../../constants/common";
import { Modal } from "antd";

export default function EditBlog({ userModal, setAddUserModal, editedData }) {
  console.log("🚀 ~ file: EditBlog.js:16 ~ EditBlog ~ editedData:", editedData);
  const dispatch = useDispatch();
  const [displayedImage, setDisplayImage] = useState(null);
  const [editedData1, setEditedData] = useState({});

  useEffect(() => {
    if (editedData) {
      setEditedData(editedData);
    }
  }, [editedData]);

  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);
    try {
      let formData = new FormData();
      formData.set("title", values.blog_title);
      if (values.blog_image_updated) {
        formData.set("image", values.blog_image_updated);
      } else {
        formData.set("image", values.blog_image);
      }
      // formData.set("image", values.blog_image);
      formData.set("description", values.blog_description);
      formData.set("shortDescription", values.blog_short_description);
      formData.set("status", values.blog_status);
      formData.set("slug", values.slug);

      let response = await updateBlog(formData, editedData.id);

      console.log("Response roles", response);
      // setUserArray(response.data.data.docs);
      // this.setState()
      setAddUserModal();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await dispatch(blogsActions.onRequest({}));
      await setSubmitting(false);
    } catch (e) {
      console.log("eeee", e);
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };
  console.log(
    "🚀 ~ file: EditBlog.js:45 ~ EditBlog ~ editedData1:",
    editedData1
  );
  return (
    <div>
      <Modal
        // className="fade bd-example-modal-lg"
        // size="lg"
        // show={userModal}
        // onHide={setAddUserModal}
        open={userModal}
        onCancel={setAddUserModal}
        footer={false}
        width={700}
      >
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                ...editedData,
                blog_image_updated: null,
              }}
              enableReinitialize
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                // setSubmitting(false);
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Update New Blog</h4>
                      {/* <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button> */}
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Blog Title
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="blog_title"
                                value={values.blog_title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter blog title"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.blog_title}
                                </span>
                              )}
                            </div>
                          </div>
                          {/* <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Blog URL
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="blog_url"
                                value={values.blog_url}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter blog url"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.blog_url}
                                </span>
                              )}
                            </div>
                          </div> */}
                          <div className="row">
                            <div className="form-group mb-3 col-lg-6">
                              <label className="text-black font-w500">
                                Blog Image
                              </label>
                              <div className="contact-name">
                                <input
                                  className="form-control"
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  // id="formFile"
                                  // value={values.blog_image}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "blog_image_updated",
                                      e.currentTarget.files[0]
                                    );
                                    setDisplayImage(
                                      URL.createObjectURL(
                                        e.currentTarget.files[0]
                                      )
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  placeholder="Choose image"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.blog_image}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <Image
                                width={200}
                                src={
                                  displayedImage
                                    ? displayedImage
                                    : values.blog_image
                                }
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Blog Status
                            </label>
                            <div className="contact-name">
                              <select
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="blog_status"
                                value={values.blog_status}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option selected disabled value={""}>
                                  Select blog status
                                </option>
                                <option value={"draft"}>Draft</option>
                                <option value={"publish"}>Published</option>
                              </select>
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.blog_status}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">Slug</label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="slug"
                                value={values.slug}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Slug"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.slug}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Blog Short Description
                            </label>
                            <div className="contact-name">
                              <Editor
                                apiKey={TINY_MCE_API_KEY}
                                value={values.blog_short_description}
                                init={TINY_MCE_EDITOR}
                                onEditorChange={(e) => {
                                  setFieldValue("blog_short_description", e);
                                }}
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.blog_short_description}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Blog Description
                            </label>
                            <div className="contact-name">
                              <Editor
                                apiKey={TINY_MCE_API_KEY}
                                value={values.blog_description}
                                init={TINY_MCE_EDITOR}
                                onEditorChange={(e) => {
                                  setFieldValue("blog_description", e);
                                }}
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.blog_description}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Blog Meta Title
                            </label>
                            <div className="contact-name">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="meta_title"
                                value={values.meta_title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter blog title"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.meta_title}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Blog Meta Description
                            </label>
                            <div className="contact-name">
                              <textarea
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="meta_desc"
                                value={values.meta_desc}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter blog title"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.meta_desc}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Blog Meta Keywords
                            </label>
                            <div className="contact-name">
                              <textarea
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="meta_keywords"
                                value={values.meta_keywords}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter blog title"
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.meta_keywords}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Updating..." : "Update"}
                      </button>
                      <button
                        type="button"
                        onClick={setAddUserModal}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
