import validator from "validator";

export function ValidateInput(data) {
  let errors = {};

  if (data.faq_title !== undefined && validator.isEmpty(data.faq_title)) {
    errors.faq_title = "FAQ title  is required";
  }
  if (data.faq_question !== undefined && validator.isEmpty(data.faq_question)) {
    errors.faq_question = "FAQ Question  is required";
  }
  if (data.faq_answer !== undefined && validator.isEmpty(data.faq_answer)) {
    errors.faq_answer = "FAQ Answer  is required";
  }
  if (data.faq_type === "") {
    errors.faq_type = "FAQ Type  is required";
  }

  return errors;
}
