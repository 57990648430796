import React from "react";
import { Modal } from "antd";
import { ValidateInput } from "./ValidateTermsConditions";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import swal from "sweetalert";
import { verifyObject } from "../../../utilities/utils";
import { updatePrivacyPolicy } from "../../../services/termsConditions";
import { TINY_MCE_API_KEY, TINY_MCE_EDITOR } from "../../../constants/common";

export default function EditTermsConditions({
  userModal,
  setAddUserModal,
  editedData,
  _getTermsConditionsList,
}) {
  const handleSubmit = async (values, setSubmitting) => {
    try {
      let response = await updatePrivacyPolicy(
        {
          description: values.description,
        },
        editedData.id
      );

      // setUserArray(response.data.data.docs);
      // this.setState()
      setAddUserModal();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await _getTermsConditionsList();
      await setSubmitting(false);
    } catch (e) {
      console.log("eeee", e);
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };
  return (
    <div>
      {" "}
      <Modal
        // className="fade bd-example-modal-lg"
        // size="lg"
        // show={userModal}
        // onHide={setAddUserModal}
        open={userModal}
        onCancel={setAddUserModal}
        footer={false}
        width={900}
      >
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                ...editedData,
              }}
              validate={ValidateInput}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                // setSubmitting(false);
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">
                        Update Terms & Conditions
                      </h4>
                      {/* <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button> */}
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Description
                            </label>
                            <div className="contact-name">
                              <Editor
                                // initialValue="<p>This is the initial content of the editor</p>"
                                value={values.description}
                                apiKey={TINY_MCE_API_KEY}
                                init={TINY_MCE_EDITOR}

                                // init={{
                                //   height: 380,
                                //   menubar: false,
                                //   plugins: [
                                //     "advlist autolink lists link image code charmap print preview anchor",
                                //     "searchreplace visualblocks code fullscreen",
                                //     "insertdatetime media table paste code help wordcount",
                                //   ],
                                //   toolbar:
                                //     "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
                                //     "bullist numlist outdent indent | removeformat | help ",
                                //   content_style: "body { color: #828282 }",
                                // }}
                                onEditorChange={(e) => {
                                  setFieldValue("description", e);
                                }}
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.blog_short_description}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Updating..." : "Update"}
                      </button>
                      <button
                        type="button"
                        onClick={setAddUserModal}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
