import { post, patch, get, deletee } from "./index";

export const forgotPassword = (data) => {
  const request = {
    subUrl: `/user/forgetPassword`,
    data,
  };
  return post(request);
};

export const setNewPassword = (data) => {
  const request = {
    subUrl: `/user/setNewPassword/${data.token}`,
    data,
  };
  return post(request);
};
