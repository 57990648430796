import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
export default function ImageLightBox({ imageGallery }) {
  const [images, setImages] = useState([]);

  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(0);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    if (imageGallery) {
      let gallery = [];
      imageGallery.map((d) => {
        gallery = [
          ...gallery,
          {
            src: d,
            caption: "image",
            orginal: "image",
          },
        ];
        setImages(gallery);
        return null;
      });
    }
  }, [imageGallery]);
  return (
    <div>
      <img
        onClick={handleClick}
        src={`${imageGallery && imageGallery[0]}`}
        alt=""
        className="cursor-pointer"
      />
      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.src}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.src}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.src}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}{" "}
    </div>
  );
}
