import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

///Import
// import PendingBlog from "./Guest/PendingBlog";
// import BookedBlog from "./Guest/BookedBlog";
// import CanceledBlog from "./Guest/CanceledBlog";
// import RefundBlog from "./Guest/RefundBlog";

//Images

import AddNewRole from "./AddNewRole";
import { isArray } from "lodash";
import swal from "sweetalert";
import EditRole from "./EditRoleData";
import { deleteRole, getRoles } from "../../../services/Roles";
import { verifyObject } from "../../../utilities/utils";
import TableLoader from "../common/TableLoader";
import NotFoundLable from "../common/NotFoundLable";
import { useSelector } from "react-redux";

// const usersArray = [
//   {
//     id: 1,
//     role_name: "admin",
//   },
//   {
//     id: 2,
//     role_name: "staff",
//   },
// ];

const RolesList = () => {
  const [editedData, setEditFormData] = useState(null);
  const [newEditedData, setNewEditFormData] = useState(null);

  const [userModal, setUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [usersArray, setUserArray] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { permissions } = useSelector((state) => state?.localStore);
  const createPermissions = permissions?.createPermissions;
  const editPermissions = permissions?.editPermissions;
  const deletePermissions = permissions?.deletePermissions;

  const [data, setData] = useState(
    document.querySelectorAll("#example2_wrapper tbody tr")
  );
  const sort = 10;
  const activePag = useRef(0);

  const handleEditClick = (_d) => {
    console.log("_d", _d);

    setNewEditFormData({
      id: _d._id,
      role_name: _d.name,
      permissions: _d.permissions,
    });
    handleEditModal();
  };

  const handleEditModal = () => {
    setEditUserModal(!editUserModal);
  };

  const deleteRecord = async (_id) => {
    try {
      // await setLoading(true);
      let response = await deleteRole({ _id });
      console.log("Response roles", response);
      swal("Poof! Your record has been deleted!", {
        icon: "success",
      });
      _getRoleList();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      // await setLoading(false);
    }
  };

  const DropdownBlog = (_d) => {
    return (
      <>
        {(editPermissions.Roles || deletePermissions.Roles) && (
          <Dropdown className="dropdown">
            <Dropdown.Toggle
              as="div"
              className="btn-link i-false"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                  stroke="#262626"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu">
              {editPermissions.Roles && (
                <Dropdown.Item
                  onClick={(e) => handleEditClick(_d)}
                  className="dropdown-item"
                >
                  Edit
                </Dropdown.Item>
              )}
              {deletePermissions.Roles && (
                <Dropdown.Item
                  onClick={() => {
                    return swal({
                      title: "Are you sure?",
                      text: "Once deleted, you will not be able to recover this record!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async (willDelete) => {
                      if (willDelete) {
                        await deleteRecord(_d._id);
                      } else {
                        swal("Your record is safe!");
                      }
                    });
                  }}
                  className="dropdown-item"
                >
                  Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </>
    );
  };

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#example2_wrapper tbody tr"));
    //chackboxFun();
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination

  const _getRoleList = async () => {
    try {
      await setLoading(true);
      let response = await getRoles({});
      console.log("response.data.data.docs", response.data.data.docs);
      await setUserArray(response.data.data.docs);
      await setLoading(false);

      // this.setState()
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setLoading(false);
    }
  };
  useEffect(() => {
    _getRoleList();
  }, []);

  const _resetData = async () => {
    try {
      let response = await getRoles({});
      console.log("response.data.data.docs", response.data.data.docs);
      await setUserArray(response.data.data.docs);
      await setLoading(false);

      // this.setState()
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setLoading(false);
    }
  };

  // console.log(document.querySelectorAll(".sorting_1 input")[0].checked);

  const handleModal = (value) => {
    setUserModal(!userModal);
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="card-action coin-tabs mb-2"></div>
        <div className="d-flex align-items-center mb-2 flex-wrap">
          <div className="newest ms-3">
            {createPermissions.Roles && (
              <Link
                to={"#"}
                className="btn btn-primary font-w600 mb-2 me-auto"
                onClick={() => {
                  setEditFormData(null);
                  handleModal();
                }}
              >
                + New Role
              </Link>
            )}
            <AddNewRole
              editedData={editedData}
              userModal={userModal}
              setAddUserModal={handleModal}
              _getRoleList={_getRoleList}
            />
            <EditRole
              newEditedData={newEditedData}
              userModal={editUserModal}
              setAddUserModal={handleEditModal}
              _getRoleList={_getRoleList}
              _resetData={_resetData}
            />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <div
                  id="example2_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="example2"
                    className="table card-table display mb-4 shadow-hover default-table dataTablesCard dataTable no-footer"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting"> ID</th>
                        <th className="sorting_asc">Name</th>
                        <th className="sorting bg-none"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="8">
                            <TableLoader />
                          </td>
                        </tr>
                      ) : null}
                      {!isLoading && usersArray.length === 0 && (
                        <tr>
                          <td colSpan="8">
                            <NotFoundLable message="No Roles Found" />
                          </td>
                        </tr>
                      )}

                      {!isLoading &&
                        usersArray &&
                        isArray(usersArray) &&
                        usersArray.map((_d, index) => {
                          return (
                            <tr key={_d._id} role="row" className="odd">
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-nowrap">
                                      <Link to={"#"} className="text-black">
                                        {index + 1}
                                      </Link>
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="concierge-bx d-flex align-items-center">
                                  <div>
                                    <h5 className="fs-16 mb-0 text-nowrap">
                                      <Link
                                        to={"#"}
                                        className="text-black text-capitalize"
                                      >
                                        {_d.name}
                                      </Link>
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td>{DropdownBlog(_d)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RolesList;
