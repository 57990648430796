import validator from "validator";

export function ValidateInput(data) {
  let errors = {};
  console.log("data", data);

  if (data.icon_name !== undefined && validator.isEmpty(data.icon_name)) {
    errors.icon_name = "Icon title  is required";
  }

  if (data.icon_image === "") {
    errors.icon_image = "Icon Image  is required";
  }

  console.log("errors", errors);
  return errors;
}
