import { getAllTransectionsAPI } from "../../services/Transections";
import { listingReducer } from "../../store/reducers/utils/listingReducer";

const { reducer, actions, types } = listingReducer({
  name: "transections",
  getApi: getAllTransectionsAPI,
});

const transections = reducer;
export { transections, actions, types };
