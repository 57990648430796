/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ValidateInput } from "./ValidateVideos";
import { Formik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
// import { addNewImage } from "../../../services/Blogs";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { verifyObject } from "../../../utilities/utils";
import {
  createNewVideo,
  getAllVideoGallaryCategories,
} from "../../../services/VideoGallary";
import { TINY_MCE_API_KEY, TINY_MCE_EDITOR } from "../../../constants/common";
import { Radio } from "antd";

export default function AddNewImage({
  userModal,
  setAddUserModal,
  _getVideoList,
}) {
  const [isLoading, setLoading] = useState(false);

  const [videoCategories, setvideoCategories] = useState([]);
  const [videoType, setVideoType] = useState("file");

  useEffect(() => {
    _getVideoCategoryList();
  }, []);

  const _getVideoCategoryList = async () => {
    try {
      await setLoading(true);
      let response = await getAllVideoGallaryCategories({});
      console.log("Response roles", response);
      let videoCategories = response.data.data.docs;
      let options = [];
      videoCategories.map((d) => {
        options.push({
          value: d._id,
          label: d.name,
        });
        return null;
      });
      await setvideoCategories(videoCategories);
      await setLoading(false);

      // this.setState()
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setLoading(false);
    }
  };

  const handleSubmit = async (values, setSubmitting) => {
    console.log("values", values);
    try {
      let formData = new FormData();
      if (values.video_file) {
        formData.set("video", values.video_file);
      }
      if (values.url) {
        formData.set("url", values.url);
      }
      formData.set("videoCategoryId", values.video_type);
      formData.set("description", values.video_description);

      let response = await createNewVideo(formData);
      console.log("Response roles", response);

      setAddUserModal();
      await swal({
        title: "Success",
        text: response.data.message,
        buttons: false,
        icon: "success",
        timer: 1000,
      });
      await _getVideoList();
      await setSubmitting(false);
      // await setAddUserModal();
    } catch (e) {
      let errorMessage = verifyObject(
        e,
        "response.data.message",
        "Something went wrong"
      );
      swal("Server Error", errorMessage, "error");
      await setSubmitting(false);
    }
  };
  return (
    <div>
      {" "}
      <Modal
        size="lg"
        className="fade bd-example-modal-lg"
        show={userModal}
        onHide={setAddUserModal}
      >
        <div className="" role="document">
          <div className="">
            <Formik
              initialValues={{
                video_file: "",
                video_description: "",
                url: "",
              }}
              validate={(v) => ValidateInput(v, videoType)}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                // setSubmitting(false);
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Add New Video</h4>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={setAddUserModal}
                        data-dismiss="modal"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <i className="flaticon-cancel-12 close"></i>
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Select Video Mode (File or URL)
                            </label>
                            <div className="contact-name">
                              <Radio.Group
                                onChange={(e) => {
                                  let key = e?.target?.value;
                                  setVideoType(key);
                                  if (key === "file") {
                                    setFieldValue("url", null);
                                  } else {
                                    setFieldValue("video_file", null);
                                  }
                                }}
                                value={videoType}
                              >
                                <Radio value={"file"}>File </Radio>
                                <Radio value={"url"}>URL</Radio>
                              </Radio.Group>
                              {/* {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.video_file}
                                </span>
                              )} */}
                            </div>
                          </div>
                          {videoType === "file" && (
                            <div className="form-group mb-3">
                              <label className="text-black font-w500">
                                Video
                              </label>
                              <div className="contact-name">
                                <input
                                  className="form-control"
                                  type="file"
                                  accept="video/mp4,video/x-m4v,video/*"
                                  // id="formFile"
                                  // value={values.blog_image}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "video_file",
                                      e.currentTarget.files[0]
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  placeholder="Choose image"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.video_file}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                          {videoType === "url" && (
                            <div className="form-group mb-3">
                              <label className="text-black font-w500">
                                Video URL
                              </label>
                              <div className="contact-name">
                                <input
                                  className="form-control"
                                  type="text"
                                  accept="video/mp4,video/x-m4v,video/*"
                                  name="url"
                                  onChange={(e) => {
                                    setFieldValue("url", e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  placeholder="Write a URL of Video"
                                />
                                {errors && (
                                  <span
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.url}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Video Type
                            </label>
                            <div className="contact-name">
                              <select
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="video_type"
                                value={values.video_type}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter blog title"
                              >
                                <option selected disabled value={""}>
                                  Select Video Category
                                </option>
                                {videoCategories.map((ft) => {
                                  return (
                                    <option key={ft._id} value={ft._id}>
                                      {ft.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.video_type}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Description
                            </label>
                            <div className="contact-name">
                              <Editor
                                apiKey={TINY_MCE_API_KEY}
                                init={TINY_MCE_EDITOR}
                                // init={{
                                //   height: 300,
                                //   menubar: false,
                                //   plugins: [
                                //     "advlist autolink lists link image code charmap print preview anchor",
                                //     "searchreplace visualblocks code fullscreen",
                                //     "insertdatetime media table paste code help wordcount",
                                //   ],
                                //   toolbar:
                                //     "undo redo | formatselect | code |link | image | bold italic backcolor |  alignleft aligncenter alignright alignjustify | \n" +
                                //     "bullist numlist outdent indent | removeformat | help ",
                                //   content_style: "body { color: #828282 }",
                                // }}
                                onEditorChange={(e) => {
                                  setFieldValue("video_description", e);
                                }}
                              />
                              {errors && (
                                <span
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.video_description}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-secondary"
                        //   onClick={handleAddFormSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Adding..." : "Add"}
                      </button>
                      <button
                        type="button"
                        onClick={setAddUserModal}
                        className="btn btn-danger"
                      >
                        {" "}
                        <i className="flaticon-delete-1"></i> Discard
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
}
