import { getInquiriesList } from "../../services/inquiries";
import { listingReducer } from "../../store/reducers/utils/listingReducer";

const { reducer, actions, types } = listingReducer({
  name: "inquiries",
  getApi: getInquiriesList,
});

const inquiries = reducer;
export { inquiries, actions, types };
