import { post, patch, get, deletee } from "./index";

export const getTermsConditionsList = (params) => {
  const request = {
    subUrl: `/defaultContent/termsAndCondition`,
    params,
  };
  return get(request);
};

export const getPrivacyPolicyList = (params) => {
  const request = {
    subUrl: `/defaultContent/privacyPolicy`,
    params,
  };
  return get(request);
};
export const updatePrivacyPolicy = (data, id) => {
  const request = {
    subUrl: `/defaultContent/privacyPolicy`,
    data,
  };
  return post(request);
};

export const updateTermsConditions = (data, id) => {
  const request = {
    subUrl: `/defaultContent/termsAndCondition`,
    data,
  };
  return post(request);
};

export const deleteTermsConditions = (id) => {
  const request = {
    subUrl: `/defaultContent/termsAndCondition/${id}`,
  };
  return deletee(request);
};
export const getTermsConditionsByID = (id) => {
  const request = {
    subUrl: `/defaultContent/termsAndCondition/${id}`,
  };
  return get(request);
};
