import { listingReducer } from "../reducers/utils/listingReducer";
import {
  getAdditionalInfoList,
} from "../../services/packages";

const { reducer, actions, types } = listingReducer({
  name: `packagesAddInfo`,
  getApi: getAdditionalInfoList,
});
const packagesAddInfo = reducer;

export { packagesAddInfo, actions, types };
