import validator from "validator";

export function ValidateInputPackagesDetails(data) {
  let errors = {};
  console.log("data", data);
  if (data.title !== undefined && validator.isEmpty(data.title)) {
    errors.title = "title  is required";
  }

  if (
    data.peoplePerRoom !== undefined &&
    validator.isEmpty(data.peoplePerRoom.toString())
  ) {
    errors.peoplePerRoom = "Enter people per room";
  }

  if (
    data.pricePerPerson !== undefined &&
    validator.isEmpty(data.pricePerPerson.toString())
  ) {
    errors.pricePerPerson = "Enter price per person";
  }
  if (
    data.position !== undefined &&
    validator.isEmpty(data.position.toString())
  ) {
    errors.position = "Enter position";
  }

  return errors;
}
