/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
// import Home from "./containers/Dashboard/Home";
import DashboardDark from "./containers/Dashboard/DashboardDark";
import GuestList from "./containers/Dashboard/GuestList";
import GuestDetails from "./containers/Dashboard/GuestDetails";
import ConciergeList from "./containers/Dashboard/ConciergeList";
import RoomList from "./containers/Dashboard/RoomList";
import Reviews from "./containers/Dashboard/Reviews";
import Task from "./containers/Dashboard/Task";

/////Demo
import Theme1 from "./containers/Dashboard/Demo/Theme1";
import Theme2 from "./containers/Dashboard/Demo/Theme2";
import Theme3 from "./containers/Dashboard/Demo/Theme3";
import Theme4 from "./containers/Dashboard/Demo/Theme4";
import Theme5 from "./containers/Dashboard/Demo/Theme5";
import Theme6 from "./containers/Dashboard/Demo/Theme6";

/// App
import AppProfile from "./containers/AppsMenu/AppProfile/AppProfile";
import Compose from "./containers/AppsMenu/Email/Compose/Compose";
import Inbox from "./containers/AppsMenu/Email/Inbox/Inbox";
import Read from "./containers/AppsMenu/Email/Read/Read";
import Calendar from "./containers/AppsMenu/Calendar/Calendar";
import PostDetails from "./containers/AppsMenu/AppProfile/PostDetails";

/// Product List
import ProductGrid from "./containers/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./containers/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./containers/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./containers/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./containers/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./containers/AppsMenu/Shop/ProductOrder";
import Customers from "./containers/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./containers/charts/Sparkline";
import ChartJs from "./containers/charts/Chartjs";
import Chartist from "./containers/charts/chartist";
import RechartJs from "./containers/charts/rechart";
import ApexChart from "./containers/charts/apexcharts";

/// Bootstrap
import UiAlert from "./containers/bootstrap/Alert";
import UiAccordion from "./containers/bootstrap/Accordion";
import UiBadge from "./containers/bootstrap/Badge";
import UiButton from "./containers/bootstrap/Button";
import UiModal from "./containers/bootstrap/Modal";
import UiButtonGroup from "./containers/bootstrap/ButtonGroup";
import UiListGroup from "./containers/bootstrap/ListGroup";
import UiCards from "./containers/bootstrap/Cards";
import UiCarousel from "./containers/bootstrap/Carousel";
import UiDropDown from "./containers/bootstrap/DropDown";
import UiPopOver from "./containers/bootstrap/PopOver";
import UiProgressBar from "./containers/bootstrap/ProgressBar";
import UiTab from "./containers/bootstrap/Tab";
import UiPagination from "./containers/bootstrap/Pagination";
import UiGrid from "./containers/bootstrap/Grid";
import UiTypography from "./containers/bootstrap/Typography";

/// Plugins
import Select2 from "./containers/PluginsMenu/Select2/Select2";
//import Nestable from "./containers/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./containers/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./containers/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./containers/PluginsMenu/Toastr/Toastr";
import JqvMap from "./containers/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./containers/PluginsMenu/Lightgallery/Lightgallery";

//Redux
import Todo from "./pages/Todo";
//import ReduxForm from "./containers/Forms/ReduxForm/ReduxForm";
//import WizardForm from "./containers/Forms/ReduxWizard/Index";

/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./containers/table/SortingTable/SortingTable";
import FilteringTable from "./containers/table/FilteringTable/FilteringTable";
import DataTable from "./containers/table/DataTable";
import BootstrapTable from "./containers/table/BootstrapTable";

/// Form
import Element from "./containers/Forms/Element/Element";
import Wizard from "./containers/Forms/Wizard/Wizard";
import SummerNote from "./containers/Forms/Summernote/SummerNote";
import Pickers from "./containers/Forms/Pickers/Pickers";
import jQueryValidation from "./containers/Forms/jQueryValidation/jQueryValidation";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";

//Admin Management Section
import UsersList from "./containers/Users/UserstList";
import RolesList from "./containers/Roles/RolesList";
import BlogsList from "./containers/Blogs/BlogList";
import FAQList from "./containers/FAQs/FAQList";
import FAQTypeList from "./containers/FAQTypes/FAQTypeList";
import FAQDetails from "./containers/FAQs/FAQDetails";
import BlogDetails from "./containers/Blogs/BlogsDetails";
import ImageList from "./containers/ImageGallary/ImageList";

// Routing
import RedirectComponent from "./components/common/RedirectComponent";
import VideoList from "./containers/VideoGallary/VideoList";
import VideoGallaryCategories from "./containers/VideoGallaryCategories/VideoGallaryCategoryList";
import InquiryList from "./containers/Inquiries/InquiryList";
import PackagesDetailsList from "./containers/Packages/Packages/PackagesList";
import AddPackagDetails from "./containers/Packages/Packages/AddPackageDetails";
import ImageGallaryCategoryList from "./containers/ImageGallaryCategories/ImageGallaryCategoryList";
import EvisaDetails from "./containers/Inquiries/eVisaDetails";
import ReviewsList from "./containers/Reviews/ReviewList";
import TransectionsList from "./containers/Transections/TransectionsList";
import { useDispatch, useSelector } from "react-redux";
import TermsConditionsList from "./containers/TermsConditions/TermsConditionsList";
import PrivacyPolicyList from "./containers/PrivacyPolicy/PrivacyPolicyList";
import IconsList from "./containers/IconLibrary/IconList";
import { checkAutoLogin } from "../services/AuthService";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { keys } from "lodash";
import SiteMapIndex from "./containers/SiteMap/SiteMapIndex";
import BannerConfigList from "./containers/BannerConfig/BannerConfigList";

const Markup = (props) => {
  const { menuToggle } = useContext(ThemeContext);
  const { user, permissions } = useSelector((state) => state?.localStore);
  const viewPermissions = permissions?.viewPermissions;
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const [intialRoute, setIntialRoute] = useState([]);

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   checkAutoLogin(dispatch, props.history);
  // }, []);
  // useEffect(() => {
  //   renderComponent();
  // }, [viewPermissions]);
  // const renderComponent = () => {
  //   // const allAlowed = keys(viewPermissions, (d) => {
  //   //   // if (viewPermissions[d] === true) {
  //   //     console.log(`ViewPermissions`, viewPermissions[d]);
  //   //   // }
  //   // });
  //   Object.keys(viewPermissions).map((d, i) => {
  //     if (viewPermissions[d] === true) {
  //       console.log("D", d);
  //       setAllowedRoutes([...allowedRoutes, d]);
  //     }
  //   });
  //   // console.log("allAlowed", allAlowed);
  //   return InquiryList;
  // };

  // console.log("allowedRoutes", allowedRoutes);
  const renderRoutes = () => {
    if (allowedRoutes) {
      if (allowedRoutes[0] === "Icon") {
        return IconsList;
      } else {
        return UsersList;
      }
    }
  };

  let routes = [
    /// Dashboard
    {
      url: "",
      component: user && user?.role !== "admin" ? renderRoutes() : UsersList,
      allowed: true,
    },
    // { url: "dashboard", component: Home },
    { url: "dashboard-dark", component: DashboardDark, allowed: true },
    { url: "guest-list", component: GuestList, allowed: true },
    { url: "guest-details", component: GuestDetails, allowed: true },
    { url: "concierge-list", component: ConciergeList, allowed: true },
    { url: "room-list", component: RoomList, allowed: true },
    { url: "reviews", component: Reviews },
    { url: "task", component: Task },
    { url: "transactions", component: TransectionsList, allowed: true },

    // {url : 'transactions', component: TransectionsList},

    /// Users
    {
      url: "users-list",
      component: UsersList,
      allowed: viewPermissions?.Users,
    },

    //roles
    { url: "roles", component: RolesList, allowed: viewPermissions?.Roles },

    //blogs
    { url: "blogs", component: BlogsList, allowed: viewPermissions?.Blogs },
    // sitemap
    { url: "sitemap", component: SiteMapIndex, allowed: true },
    { url: "banner-config", component: BannerConfigList, allowed: true },

    {
      url: "blogs/:id",
      component: BlogDetails,
      allowed: viewPermissions?.Blogs,
    },

    //reviews
    {
      url: "reviews-list",
      component: ReviewsList,
      allowed: viewPermissions?.Reviews,
    },

    //faqs
    { url: "faqs", component: FAQList, allowed: viewPermissions?.FAQs },
    {
      url: "faqTypes",
      component: FAQTypeList,
      allowed: viewPermissions?.FAQs,
    },
    {
      url: "faqs/:id",
      component: FAQDetails,
      allowed: viewPermissions?.FAQs,
    },

    //image List
    {
      url: "image-gallary",
      component: ImageList,
      allowed: viewPermissions?.Media,
    },
    {
      url: "video-gallary",
      component: VideoList,
      allowed: viewPermissions?.Media,
    },
    {
      url: "video-gallary-categories",
      component: VideoGallaryCategories,
      allowed: viewPermissions?.Media,
    },
    {
      url: "image-gallary-categories",
      component: ImageGallaryCategoryList,
      allowed: viewPermissions?.Media,
    },

    //Inquiry List
    {
      url: "inquiry-list",
      component: InquiryList,
      allowed: viewPermissions?.Inquiries,
    },
    {
      url: "evisa-detail",
      component: EvisaDetails,
      allowed: viewPermissions?.Inquiries,
    },

    //Packages
    // { url: "packages", component: Packages },
    // { url: "packages/:id", component: PackageDetails },
    {
      url: "packages-list",
      component: PackagesDetailsList,
      allowed: viewPermissions?.Packages,
    },
    {
      url: "packages-details",
      component: AddPackagDetails,
      allowed: viewPermissions?.Packages,
    },
    {
      url: "packages-details/:_id",
      component: AddPackagDetails,
      allowed: viewPermissions?.Packages,
    },

    //Icon Library

    {
      url: "icon-library",
      component: IconsList,
      allowed: viewPermissions?.Icons,
    },

    //Inquiry List
    {
      url: "terms-conditions",
      component: TermsConditionsList,
      allowed: viewPermissions?.Terms,
    },
    {
      url: "privacy-policy",
      component: PrivacyPolicyList,
      allowed: viewPermissions?.Privacy,
    },

    ///Demo
    { url: "primary-sidebar", component: Theme1 },
    { url: "mini-primary-sidebar", component: Theme2 },
    { url: "compact-primary-header", component: Theme3 },
    { url: "horizontal-primary-sidebar", component: Theme4 },
    { url: "horizontal-modern-sidebar", component: Theme5 },
    { url: "modern-sidebar", component: Theme6 },

    /// Apps
    { url: "app-profile", component: AppProfile },
    { url: "email-compose", component: Compose },
    { url: "email-inbox", component: Inbox },
    { url: "email-read", component: Read },
    { url: "app-calender", component: Calendar },
    { url: "post-details", component: PostDetails },

    /// Chart
    { url: "chart-sparkline", component: SparklineChart },
    { url: "chart-chartjs", component: ChartJs },
    { url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: ApexChart },
    { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    { url: "ui-alert", component: UiAlert },
    { url: "ui-badge", component: UiBadge },
    { url: "ui-button", component: UiButton },
    { url: "ui-modal", component: UiModal },
    { url: "ui-button-group", component: UiButtonGroup },
    { url: "ui-accordion", component: UiAccordion },
    { url: "ui-list-group", component: UiListGroup },
    { url: "ui-card", component: UiCards },
    { url: "ui-carousel", component: UiCarousel },
    { url: "ui-dropdown", component: UiDropDown },
    { url: "ui-popover", component: UiPopOver },
    { url: "ui-progressbar", component: UiProgressBar },
    { url: "ui-tab", component: UiTab },
    { url: "ui-pagination", component: UiPagination },
    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },

    /// Plugin
    { url: "uc-select2", component: Select2 },
    //{ url: "uc-nestable", component: Nestable },
    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: MainSweetAlert },
    { url: "uc-toastr", component: Toastr },
    { url: "map-jqvmap", component: JqvMap },
    { url: "uc-lightgallery", component: Lightgallery },

    ///Redux
    { url: "todo", component: Todo },
    //{ url: "redux-form", component: ReduxForm },
    //{ url: "redux-wizard", component: WizardForm },

    /// Widget
    { url: "widget-basic", component: Widget },

    /// Shop
    { url: "ecom-product-grid", component: ProductGrid },
    { url: "ecom-product-list", component: ProductList },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-product-order", component: ProductOrder },
    { url: "ecom-checkout", component: Checkout },
    { url: "ecom-invoice", component: Invoice },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-customers", component: Customers },

    /// Form
    { url: "form-element", component: Element },
    { url: "form-wizard", component: Wizard },
    { url: "form-editor-summernote", component: SummerNote },
    { url: "form-pickers", component: Pickers },
    { url: "form-validation-jquery", component: jQueryValidation },

    /// table
    { url: "table-filtering", component: FilteringTable },
    { url: "table-sorting", component: SortingTable },
    { url: "table-datatable-basic", component: DataTable },
    { url: "table-bootstrap-basic", component: BootstrapTable },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
  ];

  // if (user?.role === "Staff") {
  //   routes = [{ url: "inquiry-list", component: InquiryList }];
  // }
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => {
                if (data.allowed) {
                  return (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  );
                }
              })}

              <Route path="*" exact component={RedirectComponent} />
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
      <ScrollToTop />
    </>
  );
};

export default withRouter(Markup);
